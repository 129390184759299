import React, { Component } from 'react';
import {
    FacebookShareButton,
    // InstapaperShareButton,
    OKShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,    
    FacebookIcon,
    // InstapaperIcon,   
    OKIcon,
    TelegramIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    // FacebookMessengerIcon
  } from "react-share";


class SocialShare extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div className="product-subscribe">
                <p className="subscribe__title">Поделиться:</p>
                <div className="subscribe__wrap">
                    <VKShareButton url={this.props.url}>
                        <VKIcon size={42} round={true}/>
                    </VKShareButton>
                    <TelegramShareButton url={this.props.url}>
                        <TelegramIcon size={42} round={true}/>
                    </TelegramShareButton>
                    <FacebookShareButton url={this.props.url}>
                        <FacebookIcon size={42} round={true}/>
                    </FacebookShareButton>
                    <ViberShareButton url={this.props.url}>
                        <ViberIcon size={42} round={true}/>
                    </ViberShareButton>
                    <TwitterShareButton url={this.props.url}>
                        <TwitterIcon size={42} round={true}/>
                    </TwitterShareButton>
                    <OKShareButton url={this.props.url}>
                        <OKIcon size={42} round={true}/>
                    </OKShareButton>
                </div>
            </div>
        );
    }
}

export default SocialShare;