// React 
import React, { Component } from 'react';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';

//My components
import HeaderCatalog from "../main/HeaderCatalog";
import MobileNav from "../main/MobileNav";
import HeaderLogo from "../main/HeaderLogo";
import HeaderPromoLine from "../main/HeaderPromoLine";
import HeaderTopLine from "../main/HeaderTopLine";
import HeaderSearch from "../main/HeaderSearch";
import HeaderProfileMenu from "../main/HeaderProfileMenu";

//SCSS
import '../../scss/components/main/header.scss';
import axios from "axios";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: true, items: [] };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
        }));
    }


    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/categories-button`)
            .then(response => {
                this.setState({
                    items: response.data.data[0]
                })
            })
            .catch(error => {
                console.error(error);
            })
    }

    render() {
        return (
            <>
                <header>
                    <HeaderPromoLine />
                    <div className='header-desktop'>
                        <Container>
                            <Row>
                                <HeaderLogo />
                                <Col className="header-menu">
                                    <HeaderTopLine />
                                    <Nav className='header-menu__bottom flex'>
                                        <div className="catalog-menu">
                                            <Button id='catalog-btn' className='catalog-btn' onClick={this.handleClick}>Каталог товаров</Button>
                                            <div className={`header-catalog ${this.state.isToggleOn ? '' : 'open'}`} >
                                                <HeaderCatalog items={this.state.items} />
                                            </div>
                                            <div id={'menu-underlay'} className={`overlay_main ${this.state.isToggleOn ? '' : 'open'}`} onClick={this.handleClick}></div>
                                        </div>
                                        {this.state.items.length > 0 &&
                                            <>
                                            <HeaderSearch items={this.state.items}/>
                                            <HeaderProfileMenu />
                                            </>
                                        }
                                    </Nav>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </header>
                {this.state.items.length > 0 &&
                <MobileNav items={this.state.items}/>
                }
            </>
        )
    }
}

export default Header;
