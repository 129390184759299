import React, { Component } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class ImageZoom extends Component {
    constructor(props) {
        super(props);

        let imgs = []
        this.props.images.map( (img, i) => {
            imgs.push(img.original)
            return false
        } )
        this.state = {
            currentImgIndex: 0,
            currentImg: this.props.images[0],
            isOpen: false,
            photoIndex: 0,
            images: imgs
        };
        this.clickThumb =this.clickThumb.bind(this)
        this.showBig =this.showBig.bind(this)
    }
    clickThumb(e, n) {
        this.setState({
            currentImgIndex: n,
            currentImg: this.props.images[n]
        })
        document.querySelectorAll('.thumb')[0].style.borderColor = '#aaa'
        e.target.style.borderColor = '#a07'
    }
    showBig() {
        this.setState({ isOpen: true })
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.images[0].original !== this.props.images[0].original) {
            document.querySelectorAll('.thumb')[0].click()
        }
    }
    render() {
        return (
            <div style={{
                display: 'flex'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100px'
                }}>
                    {this.props.images.map( (img, i) => {
                        return(
                            <div key={i} className='thumb' style={{
                                width: '100%',
                                borderRadius: 3,
                                marginBottom: 5,
                                border: (this.state.currentImgIndex === i) ? '1px solid #a07' : '1px solid #aaa'
                            }}
                            onClick={ (e)=>{this.clickThumb(e, i)} }>
                                <img src={img.thumbnail} alt={this.props.name + ' - изображение ' + (i+1)} style={{
                                    width: '100%'
                                }} />
                            </div>
                        )
                    } )}
                </div>
                <div onClick={ ()=>{this.showBig()}}>
                    <ReactImageMagnify {...{
                        smallImage: {
                            isFluidWidth: true,
                            alt: this.props.name + ' - изображение ' + (this.state.currentImgIndex+1),
                            src: this.state.currentImg.thumbnail,
                            sizes: '300px'
                        },
                        largeImage: {
                            src: this.state.currentImg.original,
                            alt: this.props.name + ' - изображение ' + (this.state.currentImgIndex+1),
                            width: 1000,
                            height: 1000
                        },
                        style: {
                            zIndex: 9999
                        }
                    }} />
                </div>

                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[this.state.photoIndex]}
                        nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                        prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() => {
                            this.setState({
                                photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

export default ImageZoom;
