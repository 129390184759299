//React
import React, {useRef, useState} from 'react';
import HTMLFlipBook from "react-pageflip";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";

// import {ButtonGroup} from "react-bootstrap";

function PagePromoJournal(props) {
  const [isZoomed, setIsZoomed] = useState(false)
  const [page, setPage] = useState(0)
  const pages = []
  for (let i = 0; i < props.count; i++) {
    pages[i] = i + 1
  }
  let w = Math.round((window.innerHeight - 180) / 1.45)
  let h = Math.round(window.innerHeight - 180)
  let mw = w * 2
  if (window.innerWidth < window.innerHeight * 2 / 1.45) {
    w = Math.round(window.innerWidth - 40)
    h = Math.round((window.innerWidth - 40) * 1.45)
    mw = w
  }
  const flipBook = useRef(null)
  // const flipBook2 = useRef(null)
  return (
    // <div className="promo-sk border ptb30 mtb20 f fc rounded-lg"
    //   style={{
    //     background: '#dfdcdf'
    //   }}
    // >
    <div style={{position: 'relative', zIndex: 1000}}>
      <TransformWrapper
        panning={{disabled: !isZoomed}}
        wheel={{disabled: !isZoomed}}
        initialScale={isZoomed ? 1.5 : 1}
        maxScale={isZoomed ? 5 : 1}
        minScale={isZoomed ? 1.25 : 1}
      >
        {({setTransform}) => (
          <React.Fragment>
            <TransformComponent>
              <div className={'position-relative mx-auto'}
                   style={{
                     zIndex: 1000,
                     width: mw,
                     marginTop: 50,
                     background: '#edeff3'
                   }}
              >
                {pages.length > 0 && isZoomed && (
                  <HTMLFlipBook
                    width={w}
                    height={h}
                    size="fixed"
                    maxShadowOpacity={0.5}
                    showCover={false}
                    mobileScrollSupport={true}
                    clickEventForward={false}
                    useMouseEvents={false}
                    ref={flipBook}
                    onFlip={(n) => setPage(n.data)}
                    startPage={page}
                  >
                    {pages.map((el, i) => (
                      <div key={i}
                           style={{
                             width: w,
                             height: h,
                             overflow: 'hidden'
                           }}
                      >
                        {/*<TransformWrapper*/}
                        {/*  panning={{disabled: !isZoomed}}*/}
                        {/*  wheel={{disabled: !isZoomed}}*/}
                        {/*  initialScale={1.5}*/}
                        {/*>*/}
                        {/*  <TransformComponent>*/}
                        <img
                          src={`${process.env.REACT_APP_BACKEND}/uploads/sp/newspapers/${props.id}/${el}.jpg`}
                          alt=""
                          style={{
                            width: w,
                            height: h,
                          }}
                        />
                        {/*  </TransformComponent>*/}
                        {/*</TransformWrapper>*/}
                      </div>
                    ))}
                  </HTMLFlipBook>
                )}
                {pages.length > 0 && !isZoomed && (
                  <HTMLFlipBook
                    width={w}
                    height={h}
                    size="fixed"
                    maxShadowOpacity={0.5}
                    showCover={false}
                    mobileScrollSupport={true}
                    clickEventForward={true}
                    useMouseEvents={true}
                    ref={flipBook}
                    onFlip={(n) => setPage(n.data)}
                    startPage={page}
                  >
                    {pages.map((el, i) => (
                      <div key={i}
                           style={{
                             width: w,
                             height: h,
                             overflow: 'hidden'
                           }}
                      >
                        {/*<TransformWrapper*/}
                        {/*  panning={{disabled: !isZoomed}}*/}
                        {/*  wheel={{disabled: !isZoomed}}*/}
                        {/*>*/}
                        {/*  <TransformComponent>*/}
                        <img
                          src={`${process.env.REACT_APP_BACKEND}/uploads/sp/newspapers/${props.id}/${el}.jpg`}
                          alt=""
                          style={{
                            width: w,
                            height: h,
                          }}
                        />
                        {/*  </TransformComponent>*/}
                        {/*</TransformWrapper>*/}
                      </div>
                    ))}
                  </HTMLFlipBook>
                )}
              </div>

            </TransformComponent>
            <div className='w-100 d-flex justify-content-center align-items-stretch'>
              {/*<button className='btn btn-primary m-2 d-block ml-auto mr-0' style={{*/}
              {/*  opacity: 0,*/}
              {/*  pointerEvents: 'none'*/}
              {/*}}>*/}
              {/*  &times;*/}
              {/*</button>*/}
              <button className='btn btn-primary m-2 d-block' onClick={() => {
                flipBook.current.pageFlip().flipPrev()
              }}>
                <i className='icon-arrow-left2'/>
              </button>
              {!isZoomed && (<button className='btn btn-primary m-2 d-block' onClick={() => {
                setIsZoomed(!isZoomed)
                setTransform(-mw / 2, -h / 2, 2)
              }}>
                <i className='icon-zoom-in'/>
              </button>)}
              {isZoomed && (<button className='btn btn-primary m-2 d-block' onClick={() => {
                setIsZoomed(!isZoomed)
                setTransform(0, 0, 1)
              }}>
                <i className='icon-book'/>
              </button>)}
              <button className='btn btn-primary m-2 d-block' onClick={() => {
                flipBook.current.pageFlip().flipNext()
              }}>
                <i className='icon-arrow-right2'/>
              </button>
              {/*<button className='btn btn-primary m-2 d-block ml-auto mr-0' onClick={props.close} title={'Закрыть'}>*/}
              {/*  &times;*/}
              {/*</button>*/}
            </div>
            <div className='text-center px-5 text-white-50 d-none d-lg-block'>
              Страница {page + 1}-{page + 2}  из {props.count}
            </div>
            <div className='text-center px-5 text-white-50 d-block d-lg-none'>
              Страница {page + 1} из {props.count}
            </div>
            {isZoomed && (
              <div className='text-center px-5 text-white-50'>
                Перемещайте и масштабируйте страницы, чтобы увидеть больше
              </div>
            )}
            {!isZoomed && (
              <div className='text-center px-5 text-white-50'>
                Перелистывайте страницы журнала
              </div>
            )}
          </React.Fragment>
        )}
      </TransformWrapper>

      <button className='btn btn-primary m-2 d-block ml-auto mr-0 rounded-circle'
              onClick={props.close}
              title={'Закрыть'}
              style={{
                top: '-4px',
                right: '-4px',
                position: 'fixed',
                width: '40px',
                height: '40px',
              }}
      >
        &times;
      </button>
    </div>
  );
}

export default PagePromoJournal;
