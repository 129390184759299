import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class PromoRools extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            setShow: false
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
    }

    handleClose() {
        this.setState({
            show: false
        })
    }
    
    handleShow() {
        this.setState({
            show: true
        })
    }

    render(){
        return (
            <div className="promo-rools__modal">
                <Button variant="primary" className="promo-rools__btn" onClick={this.handleShow}>Условия акции</Button>
                <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Условия акции</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-scroll" dangerouslySetInnerHTML={{__html: this.props.condition}}>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}