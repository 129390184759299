
// React 
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class ModalRegComplete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCart: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleClose() {
        this.setState({
            showCart: false
        })
    }
    handleShow() {
        this.setState({
            showCart: true
        })
    }
    render() {
        return (
            <Modal
                show={this.state.showCart}
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-sm"
            >
                <Modal.Header closeButton>
                    <h4>Регистрация завершена</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="center-block">
                        <b>Вы успешно зарегистрированы!</b>
                        <p>Ваша почта подтверждена!<br />
Вам направлено письмо с данными для авторизации.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Закрыть</Button>
                    <Button onClick={this.handleClose}>Войти</Button>  {/* ТУТ надо повесить октрытие модалки Входа */}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalRegComplete;