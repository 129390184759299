import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap';
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.location.replace('/404.html')
    }
    render() {
        return (
            <main className='page-error'>
                <Redirect to={'/404'} status={404} />
                <Container>
                    <h3 style={{
                        textAlign: "center"
                    }}>
                        Ошибка 404: Страница не найдена
                    </h3>
                </Container>
            </main>
        );
    }
}

export default NotFound;
