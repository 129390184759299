//React
import React, {Component} from 'react';
import {Container, Row, Col, Breadcrumb} from 'react-bootstrap';

//My Components
// import WishList from "../main/ModalWishlist";
import Slider from './components/SliderPromo';
import MenuCatalogSub from './components/MenuCatalogSub2';
import SKeditorContent from './components/SKeditorContent';

//SCSS
import "../../scss/components/pages/home/__pageHome.scss";
import "../../scss/components/pages/home/dropdownProducts.scss";
import '../../scss/components/pages/home2/__home2.scss';
import '../../scss/components/pages/categories/catChild.scss';
import '../../scss/components/pages/categories/filter.scss';
import axios from "axios";
import {Link} from 'react-router-dom';
import CountDown from "./components/CountDown2";
import Product from "./components/Product";
import Journal from "./components/Journal";

class PagePromoChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: [],
      data: null,
      sidebar: null,
      products: [],
      pagination: null,
      page: 1,
      crumb: '',
      ckText: '',
      stock_title: '',
      order_by: 1,
      isToggleOn: true,
      show: false,
      newspaperId: 0,
      newspaperCount: 0,
      newspapers: null,
      isLoading: false,
    };
    this.filterProducts = this.filterProducts.bind(this)
    this.handleClick = this.handleClick.bind(this);
    this.toggleNewspaper = this.toggleNewspaper.bind(this);
  }

  toggleNewspaper(id, count) {
    this.setState({
      show: !this.state.show,
      newspaperId: id,
      newspaperCount: count
    }, () => {
      if (this.state.show) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    })
  }

  filterProducts(e) {
    this.setState({
      order_by: e.target.value
    }, () => {
      this.changePage(this.state.page)
    })
  }

  changePage(page) {
    this.setState({
      isLoading: true
    })
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-product/${this.props.match.params.slug}/${this.state.sidebar.category_main}/${this.state.order_by}/${page}`)
      .then(response => {
        this.setState({
          page: page,
          products: response.data.data.products,
          isLoading: false
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  getProducts() {
    this.setState({
      isLoading: true
    })
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-product/${this.props.match.params.slug}/${this.state.sidebar ? this.state.sidebar.category_main : 'all'}/${this.state.order_by}/1`)
      .then(response => {
        // console.log(response.data.data)
        this.setState({
          products: response.data.data.products,
          pagination: response.data.data.pages,
          isLoading: false
        }, () => {
          // console.log(this.state.products)
        })
      })
      .catch(err => {
        console.error(err)
      })
  }
  fetchData() {
    document.title = 'Галактика | Акция'

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
        .then(response => {
          const data = response.data.data;
          const filtered = data.filter(s => s.name_slug === this.props.match.params.slug)
          this.setState({
            crumb: filtered[0].name,
            stock_title: filtered[0].name,
          })
        })
        .catch(error => {
          console.error(error);
        })

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-categories-sidebar/first/${this.props.match.params.slug}`)
        .then(response => {
          this.setState({
            sidebar: response.data.data
          }, () => {
            // console.log(this.state.sidebar)
            this.getProducts()
          })
        })
        .catch(error => {
          console.error(error);
        })

    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-baner/${this.props.match.params.slug}`)
        .then(response => {
          const data = response.data.data;
          this.setState({
            blocks: data.block_baner,
            data: data,
            newspapers: data.newspaper
          }, () => {
            // console.log(data)
            if (this.state.data.text) {
              this.setState({
                ckText: this.state.data.text
              })
            }
            if (this.state.data.text_end) {
              this.setState({
                ckText: this.state.data.text_end
              })
            }
            if (this.state.data.background_url) {
              document.body.style.backgroundImage = `url(${process.env.REACT_APP_BACKEND}uploads/sp/stocks/${this.state.data.background_url.replace(/\s+/, "%20")})`
              document.body.style.backgroundPosition = 'center'
              // document.body.style.backgroundSize = 'cover'
            }
          })


          let metaTitle, metaDescription, robotsIndex, robotsFollow
          if (data.meta_tags && data.meta_tags.seo_title && data.meta_tags.seo_title !== 'null') {
            metaTitle = data.meta_tags.seo_title
          }
          if (data.meta_tags && data.meta_tags.meta_discription && data.meta_tags.meta_discription !== 'null') {
            metaDescription = data.meta_tags.meta_discription
          }
          if (data.meta_tags && data.meta_tags.robots_index) {
            robotsIndex = data.meta_tags.robots_index
          }
          if (data.meta_tags && data.meta_tags.robots_follow) {
            robotsFollow = data.meta_tags.robots_follow
          }

          if (metaTitle) document.title = metaTitle;
          else document.title = data.block_baner[0].name + ' | Галактика';
          if (metaDescription) {
            if (!document.querySelector('meta[name="description"]')) {
              let metaD = document.createElement('meta')
              metaD.name = 'description'
              metaD.content = metaDescription
              document.querySelector('head').appendChild(metaD)
            } else {
              document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
            }
          }
          document.querySelectorAll('meta[name="robots"]').forEach((el) => {
            document.querySelector('head').removeChild(el)
          })
          if (robotsIndex || robotsFollow) {
            let metaD = document.createElement('meta')
            metaD.name = 'robots'
            let robotsContent = ''
            if (robotsIndex === 'yes') {
              robotsContent += 'index'
              if (robotsFollow === 'yes') {
                robotsContent += ', follow'
              }
              if (robotsFollow === 'no') {
                robotsContent += ', nofollow'
              }
            } else if (robotsIndex === 'no') {
              robotsContent += 'noindex'
              if (robotsFollow === 'yes') {
                robotsContent += ', follow'
              }
              if (robotsFollow === 'no') {
                robotsContent += ', nofollow'
              }
            } else {
              if (robotsFollow === 'yes') {
                robotsContent += 'follow'
              }
              if (robotsFollow === 'no') {
                robotsContent += 'nofollow'
              }
            }
            metaD.content = robotsContent
            document.querySelector('head').appendChild(metaD)
          }
          // document.querySelectorAll('.will-animate').forEach( (el, i) => {
          //     el.classList.add('fadeInBlock')
          // })

        })
        .catch(error => {
          console.error(error);
        })
  }
  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    document.body.style.backgroundImage = ''
  }

  getLink(linkObj) {

    if (typeof linkObj === 'string') {
      if (linkObj.indexOf("https://galaktika.me") !== -1)
        return linkObj.replace("https://galaktika.me/", '/')
      if (linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
        return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
      return '/' + linkObj
    }

    if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

    if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
    if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
  }

  getDays(endString) {
    let now = new Date()
    let end = new Date(endString)
    return end > now ? Math.floor((end - now) / (24 * 3600 * 1000)) : 0
  }

  handleClick() {
    // document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.fetchData()
    }
  }
  render() {
    // const clickBtn = this.state.isToggleOn;
    return (
      <>
        <main className='promo'>
          <Container>
            <Breadcrumb>
              <li className={'breadcrumb-item'}>
                <Link to={`/`}>
                  <i className="i-home"/>
                </Link>
              </li>
              <li className={'breadcrumb-item'}>
                <Link to={`/promo`}>
                  Все акции
                </Link>
              </li>
              {this.state.crumb && (<li className={'breadcrumb-item'}>
                <span>
                  {this.state.crumb}
                </span>
              </li>)}
            </Breadcrumb>
            {this.state.blocks && this.state.blocks.map((block, index) => (
              <React.Fragment key={index}>
                {
                  block.type === 1 && (
                    <Row className="mb-4">
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <Slider items={block.baners}/>
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <CountDown
                          name={block.title}
                          startDate={block.date_start_stock}
                          endDate={block.date_end_stock}
                          time_stock={block.time_stock}
                          button={block.button}
                          text_for_modal={block.text_for_modal}
                          link={block.link}
                          text_end={block.text_end}
                          status={block.status}
                        />
                      </Col>
                    </Row>
                  )
                }
                {
                  block.type === 2 && (
                    <Row className="mb-4">
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <Slider items={block.baners}/>
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <CountDown
                          name={block.title}
                          startDate={block.date_start_stock}
                          endDate={block.date_end_stock}
                          time_stock={block.time_stock}
                          button={block.button}
                          text_for_modal={block.text_for_modal}
                          link={block.link}
                          text_end={block.text_end}
                          status={block.status}
                        />
                      </Col>
                    </Row>
                  )
                }
                {
                  block.type === 3 && (
                    <Row>
                      <Col xs={12} xl={7} className="products promo-product">
                        {block.baners.length > 0 &&
                          <Slider items={block.baners}/>
                        }
                      </Col>
                      <Col xs={12} xl={5}>
                        <div className="timetable tac h-100">
                          {block.status === 'open' && (
                            <>
                              <h4 dangerouslySetInnerHTML={{__html: block.title}}/>
                              <div dangerouslySetInnerHTML={{__html: block.text}}/>
                              {/*<Link to={this.getLink(block.link)}>*/}
                              {/*  Перейти в Акцию*/}
                              {/*</Link>*/}
                            </>
                          )}
                          {block.status === 'end' && (
                            <h4 className='h-100 d-flex pb32 align-items-center justify-content-center'>
                              <div
                                dangerouslySetInnerHTML={{__html: block.text_end}}/>
                            </h4>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )
                }
              </React.Fragment>
            ))}
            {this.state.sidebar && this.state.sidebar.categories.length === 0 && (
              <Link to={`/promo`} className="flex align-items-center mt10">
                <i className="i-home"/>
                <b className="flex fg ttu ml-1 mt-1">Все акции</b>
              </Link>)}

            {
              this.state.newspapers &&
              (
                <div className="promo-sk border ptb30 mtb20 rounded-lg">
                  <Row>
                    <Col xs={6} xl={3} className={'mx-auto text-center'}>
                      <a href="!#" className="promo-newspapers_item" onClick={(e) => {
                        e.preventDefault()
                        this.toggleNewspaper(this.state.newspapers.id, this.state.newspapers.pages)
                      }}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND}/uploads/sp/newspapers/${this.state.newspapers.id}/1.jpg`}
                          alt="" className="img-fluid"/>
                      </a>
                    </Col>
                  </Row>

                  {this.state.show && (
                    <div
                      className='position-fixed d-flex flex-column align-items-center'
                      style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100vh',
                        zIndex: 999,
                        background: 'rgba(0,0,0,.5)',
                        backdropFilter: 'blur(5px)',
                      }}
                    >
                      <div className='position-absolute'
                           style={{
                             top: 0,
                             left: 0,
                             width: '100%',
                             height: '100vh',
                             zIndex: 999,
                           }}
                           onClick={() => {
                             this.setState({show: false, newspaperId: 0, newspaperCount: 0})
                             document.body.style.overflow = 'auto'
                           }}
                      />
                      <Journal close={() => {
                        this.setState({show: false, newspaperId: 0, newspaperCount: 0})
                        document.body.style.overflow = 'auto'
                      }} id={this.state.newspaperId} count={this.state.newspaperCount}/>
                    </div>
                  )}
                </div>
              )
            }
            {this.state.ckText &&
              <SKeditorContent text={this.state.ckText}/>
            }
            {this.state.products && this.state.products.length > 0 && (<div className="border promo-content_header">
              <h3 className="mtb10">
                Товары акции
                &nbsp;
                <span className="op03">
                  {this.state.crumb}
                </span>
                &nbsp;
                <span className="f10 fw600 ml10">
                  {this.state.pagination && this.state.pagination.total} товаров
                </span>
              </h3>
              <div className="f catalog__settings">
                <div className="filter-selection f">
                  <div className="filter-mobile">
                    <button className="btn btn-primary" onClick={this.handleClick}>Фильтры</button>
                  </div>
                  {/*<div className="filter-selection__label">*/}
                  {/*    <p>Выбранные фильтры:</p>*/}
                  {/*</div>*/}
                  {/*<div className='filter-selection__list'>*/}
                  {/*    <div className='filter-selection__item'>*/}
                  {/*        <button className="filter-selection__btn reset">Сбросить</button>*/}
                  {/*    </div>*/}
                  {/*    <div className='filter-selection__item' >*/}
                  {/*        <button className="filter-selection__link" to="/">*/}
                  {/*            Белшина*/}
                  {/*        </button>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
                <div className="catalog-view mla">
                  <div className="catalog-view__wrap">
                    <div className="catalog-view__sort">
                      <select value={this.state.order_by} id='order' className="select-custom"
                              onChange={this.filterProducts}>
                        <option value='1'> По умолчанию</option>
                        <option value='2'> От дешевых к дорогим</option>
                        <option value='3'> От дорогих к дешевым</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            <div className="promo-inner">
              <aside className={`catalog-filter ${this.state.isToggleOn ? '' : 'show'}`}>
                <div className="catalog-filter__overlay" onClick={this.handleClick}/>
                <div className="catalog-filter__wrap">
                  <div className="catalog-filter__promo scrolled">
                    {this.state.sidebar && this.state.sidebar.categories.length > 0 && (
                      <Link to={`/promo`} className="flex align-items-center mt10">
                        <i className="i-home"/>
                        <b className="flex fg ttu ml-1 mt-1">Все акции</b>
                      </Link>)}
                    <div className="promo-categories">
                      {this.state.sidebar && (
                        <MenuCatalogSub sidebar={this.state.sidebar} stock={this.props.match.params.slug}
                                        stock_title={this.state.crumb}/>
                      )}
                    </div>
                  </div>
                </div>
              </aside>
              <main className="promo-content" style={{
                transition: 'opacity .4s ease',
                opacity: this.state.isLoading ? 0 : 1,
                pointerEvents: this.state.isLoading ? 'none' : 'auto'
              }}>
                <div className="catalog-list">
                  <div className="catalog-list__wrap">
                    {this.state.products && this.state.products.length > 0 && this.state.products.map((p, i) =>
                      (
                        <Product product={p} key={i}/>
                      )
                    )}
                  </div>
                </div>
                {this.state.pagination &&
                  (<div className="catalog-list__navigation">
                    {/*<div className="loadMore">
                                            <button>Показать больше</button>
                                        </div>*/}
                    {this.state.pagination.lastPage > 1 &&
                      <div className="pagination">
                        <ul className="pagination__wrap">
                          <ul className="pag_arrows">
                            <li className="pagination__item pag_arrows__item bt_pprev">
                              {this.state.page === 1 &&
                                <span style={{opacity: .15}}>
                                  <i className="i-arrow prev" style={{
                                    display: 'inline-block',
                                    transform: 'rotate(90deg) translateY(-14px)'
                                  }}/>
                                  <i className="i-arrow prev"
                                     style={{display: 'inline-block'}}/>
                                </span>
                              }
                              {this.state.page !== 1 &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(1)
                                }}>
                                  <i className="i-arrow prev"
                                     style={{display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)'}}/>
                                  <i className="i-arrow prev" style={{display: 'inline-block'}}/>
                                </a>
                              }
                            </li>
                            <li className="pagination__item pag_arrows__item bt_prev">
                              {this.state.page === 1 &&
                                <span style={{opacity: .15}}>
                                  <i className="i-arrow prev" style={{opacity: .15}}/>
                                </span>
                              }
                              {this.state.page !== 1 &&
                                <a className={`link`} href='!#' onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page > 1 ? this.state.page - 1 : 1)
                                }}>
                                  <i className="i-arrow prev"/>
                                </a>
                              }
                            </li>
                          </ul>
                          <ul className="pag_list">
                            {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(1)
                                }}>
                                  <b>1</b>
                                </a>
                              </li>
                            }
                            {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                              <li className="pagination__item"><b>...</b></li>
                            }
                            {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page - 2)
                                }}>
                                  <b>{this.state.page * 1 - 2}</b>
                                </a>
                              </li>
                            }
                            {this.state.page > 1 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 - 1)
                                }}>
                                  <b>{this.state.page * 1 - 1}</b>
                                </a>
                              </li>
                            }
                            <li className="pagination__item active">
                              <span className="link">
                                <b>{this.state.page}</b>
                              </span>
                            </li>
                            {this.state.page < this.state.pagination.lastPage &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 + 1)
                                }}>
                                  <b>{this.state.page * 1 + 1}</b>
                                </a>
                              </li>
                            }
                            {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page * 1 + 2)
                                }}>
                                  <b>{this.state.page * 1 + 2}</b>
                                </a>
                              </li>
                            }

                            {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                              <li className="pagination__item"><b>...</b></li>
                            }

                            {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                              <li className="pagination__item">
                                <a href='!#' className="link" onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.pagination.lastPage)
                                }}>
                                  <b>{this.state.pagination.lastPage}</b>
                                </a>
                              </li>
                            }

                          </ul>
                          <ul className="pag_arrows">
                            <li className="pagination__item pag_arrows__item bt_next">
                              {this.state.page !== this.state.pagination.lastPage &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage)
                                }}>
                                  <i className="i-arrow next"/>
                                </a>
                              }
                              {this.state.page === this.state.pagination.lastPage &&
                                <span className={`link`} style={{opacity: .15}}>
                                  <i className="i-arrow next" style={{opacity: .15}}/>
                                </span>
                              }
                            </li>
                            <li className="pagination__item pag_arrows__item bt_nnext">
                              {this.state.page !== this.state.pagination.lastPage &&
                                <a href='!#' className={`link`} onClick={(e) => {
                                  e.preventDefault()
                                  this.changePage(this.state.pagination.lastPage)
                                }}>
                                  <i className="i-arrow next" style={{display: 'inline-block'}}/>
                                  <i className="i-arrow next"
                                     style={{display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)'}}/>
                                </a>
                              }
                              {this.state.page === this.state.pagination.lastPage &&
                                <span className={`link`} style={{opacity: .15}}>
                                  <i className="i-arrow next"
                                     style={{display: 'inline-block'}}/>
                                  <i className="i-arrow next" style={{
                                    display: 'inline-block',
                                    transform: 'rotate(-90deg) translateY(-14px)'
                                  }}/>
                                </span>
                              }
                            </li>
                          </ul>
                        </ul>
                      </div>
                    }
                  </div>)}
                <div className="cat-desc">
                  {this.state.data && this.state.data.bottom_description !== 'null' && (
                    <div className="cat-desc__wrap"
                         dangerouslySetInnerHTML={{__html: this.state.data.bottom_description}}/>
                  )}
                </div>
              </main>
            </div>
          </Container>
        </main>
      </>
    );
  }
}

export default PagePromoChild;
