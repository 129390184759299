//React
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Badges from "../main/Badges";
// import queryString from 'query-string'
import {Breadcrumb, Container, Button} from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
//import StarRatings from 'react-star-ratings';

import CartCtx from '../CartCtx'

//My Components
import ReadMore from '../main/ReadMore';
import ImageSlider from '../categories/Slider';
import WishList from "../main/ModalWishlist";

import '../../scss/components/pages/categories/catChild.scss';
import '../../scss/components/pages/categories/filter.scss';
import axios from "axios";


const Range = Slider.Range;


class PageCategoryChild extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      filterToggleOn: true,
      banners: [],
      current: {},
      products: [],
      filters: [],
      manufacturers: [],
      pagination: {},
      page: this.props.match.params.page || 1,
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      max_price: 0,
      order_by: 1,
      price_from: 0,
      price_to: 0,
      colors: [],
      crumbs: [],
      trueRobots: null,
      isLoading: true
    };
    this.handleClick = this.handleClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.filterCheck = this.filterCheck.bind(this)
    this.brandCheck = this.brandCheck.bind(this)
    this.colorCheck = this.colorCheck.bind(this)
    this.changePage = this.changePage.bind(this)
    this.filterProducts = this.filterProducts.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.changePageAndHandle = this.changePageAndHandle.bind(this)
    this.addSomeUselessTags = this.addSomeUselessTags.bind(this)
    this.linkiHuinki = this.linkiHuinki.bind(this)
    if (window.location.pathname.substr(-2, 2) === '/1') {
      window.history.pushState(null, null, window.location.pathname.slice(0, -2))
    }
    let lastPart = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    if (lastPart !== this.props.match.params.slug && !lastPart.match(/^[0-9]+$/)) {
      window.location.replace('/404.html')
    }
    if (false) {
      window.location.replace('/404.html')
    }
  }

  filterCheck() {
    let checked = []
    document.querySelectorAll('.filter-check:checked').forEach((el, i) => {
      if (!el.classList.contains('brand-check')) checked.push(el.id)
    })
    this.setState({
      selectedFilters: checked
    })
  }

  brandCheck() {
    let checked = []
    document.querySelectorAll('.brand-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('m', ''))
    })
    this.setState({
      selectedManufacturers: checked
    })
  }

  colorCheck() {
    let checked = []
    document.querySelectorAll('.color-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('c', ''))
    })
    this.setState({
      selectedColors: checked
    })
  }

  removeFilter(e) {
    document.getElementById(e.target.getAttribute('data-id')).checked = false
    this.filterCheck()
  }

  changePage(num) {
    this.setState({
      page: num,
      isLoading: true,
    })


    setTimeout(() => {
      this.filterProducts()
    }, 300)

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, 400)

  }

  resetFilters() {
    document.querySelectorAll('.filter-check').forEach((el, i) => {
      el.checked = false
    })
    this.setState({
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      price_from: this.state.min,
      price_to: this.state.max
    })
    setTimeout(() => {
      this.changePage(1)
    }, 300)

  }

  filterProducts() {
    if (this.state.page > 1)
      this.props.history.push('/catalog/' + this.props.match.params.slug + '/' + this.state.page)
    let postData = {
      'slug': this.props.match.params.slug,
      'page': this.state.page,
      'arrayFilter': this.state.selectedFilters.length === 0 ? '' : this.state.selectedFilters.join(', '),
      'manufacturer': this.state.selectedManufacturers.length === 0 ? 'no' : this.state.selectedManufacturers.join(', '),
      'price_from': this.state.price_from,
      'price_to': this.state.price_to,
      'order_by': document.getElementById('order').value
    }

    this.setState({
      isLoading: true
    })
    axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-filter`, postData)
      .then((response) => {
        let respData = response.data.data;
        //if (respData.length === 6) respData.unshift([])
        this.setState({
          products: respData[0],
          filters: respData[1],
          manufacturers: respData[2],
          pagination: respData[4],
          selectedFilters: respData[5].filter,
          selectedManufacturers: respData[5].manufacturer[0] === 'no' ? [] : respData[5].manufacturer,
          selectedColors: respData[5].filter,
          order_by: respData[5].orderBy,
          price_from: respData[5].price_from || respData[6].minPrice,
          price_to: respData[5].price_to || respData[6].maxPrice,
          min: respData[6].minPrice,
          max: respData[6].maxPrice,
          colors: respData[3]
        }, ()=>{

          this.setState({
            isLoading: false
          })
        })
      })
  }

  addSomeUselessTags() {

    if (this.state.page !== 1) {
      document.querySelectorAll('meta[name="robots"]').forEach((el) => {
        document.querySelector('head').removeChild(el)
      })
      let metaR = document.createElement('meta')
      metaR.name = 'robots'
      metaR.content = 'noindex, follow'
      document.querySelector('head').appendChild(metaR)
    } else {
      document.querySelectorAll('meta[name="robots"]').forEach((el) => {
        document.querySelector('head').removeChild(el)
      })
      if (this.state.trueRobots) {
        let metaR = document.createElement('meta')
        metaR.name = 'robots'
        metaR.content = this.state.trueRobots
        document.querySelector('head').appendChild(metaR)
      }
    }
    this.linkiHuinki()
  }

  linkiHuinki() {
    document.querySelectorAll('link[rel="next"], link[rel="prev"]').forEach((el) => {
      document.querySelector('head').removeChild(el)
    })
    if (this.state.pagination.lastPage > 1) {
      if (this.state.page === 1) {
        let l1 = document.createElement('link')
        l1.rel = 'next'
        l1.href = window.location.href + '/2'
        document.querySelector('head').appendChild(l1)
      } else if (this.state.page < this.state.pagination.lastPage) {
        let l1 = document.createElement('link')
        l1.rel = 'next'
        let l2 = document.createElement('link')
        l2.rel = 'prev'
        let q = this.state.pagination.lastPage.toString().length + 1
        l1.href = window.location.href.slice(0, -q) + '/' + (+this.state.page + 1)
        l2.href = window.location.href.slice(0, -q) + (+this.state.page - 1 === 1 ? '' : '/' + (+this.state.page - 1))
        document.querySelector('head').appendChild(l1)
        document.querySelector('head').appendChild(l2)
      } else {
        let l1 = document.createElement('link')
        l1.rel = 'prev'
        let q = this.state.pagination.lastPage.toString().length + 1
        l1.href = window.location.href.slice(0, -q) + (this.state.pagination.lastPage - 1 === 1 ? '' : '/' + (this.state.pagination.lastPage - 1))
        document.querySelector('head').appendChild(l1)
      }

    }
    if (this.state.page !== 1) {
      if (!document.querySelector('link[rel="canonical"]')) {
        let cunt = document.createElement('link')
        cunt.rel = 'canonical'
        let src = window.location.href
        cunt.href = src.slice(0, -2)
        document.head.appendChild(cunt)
      } else {
        let src = window.location.href
        document.querySelector('link[rel="canonical"]').href = src.slice(0, -2)
      }
    } else {
      if (document.querySelector('link[rel="canonical"]')) {
        let c = document.querySelector('link[rel="canonical"]')
        document.head.removeChild(c)
      }
    }
  }

  onLowerBoundChange = (e) => {
    this.setState({price_from: +e.target.value});
  }
  onUpperBoundChange = (e) => {
    this.setState({price_to: +e.target.value});
  }
  onSliderChange = (value) => {
    this.setState({
      price_from: value[0],
      price_to: value[1]
    });
  }
  handleApply = () => {
    const {min, max} = this.state;
    this.setState({value: [min, max]});
  }

  componentDidUpdate(prevProps, prevState) {
    document.querySelector('#menu-underlay.open') && document.querySelector('#menu-underlay.open').click()
    this.addSomeUselessTags()
    // console.log(prevProps.match.params.slug, this.props.match.params.slug);
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState({
        page: 1
      }, () => {
        this.fetchData()
      })

    }
  }

  fetchData() {
    if (this.state.page > 1)
      this.props.history.push('/catalog/' + this.props.match.params.slug + '/' + this.state.page)
    axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-one/${this.props.match.params.slug}`)
      .then((response) => {

        // console.log(response.data.data[0])
        // if(response.data.data[0].redirect !== null) {
        //     // window.location.reload()
        //     if(response.data.data[0].redirect.redirect_type === 'prod') {
        //         window.location.replace(`/product/${response.data.data[0].redirect.redirect_to}`)
        //     }
        //     if(response.data.data[0].redirect.redirect_type === 'cat') {
        //         // console.log(response.data.data[0])
        //         if(response.data.data[0].redirect.type_cat === 'no_have_cat') {
        //             window.location.replace(`/catalog/${response.data.data[0].redirect.redirect_to}`)
        //         } else {
        //             window.location.replace(`/category/${response.data.data[0].redirect.redirect_to}`)
        //         }
        //     }
        // }

        this.setState({
          banners: response.data.data[1],
          current: response.data.data[0],
          crumbs: response.data.data[2].reverse()
        })

        let metaTitle, metaDescription, robotsIndex, robotsFollow
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.seo_title) {
          metaTitle = response.data.data[0].meta_tags.seo_title
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.meta_discription) {
          metaDescription = response.data.data[0].meta_tags.meta_discription
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.robots_index) {
          robotsIndex = response.data.data[0].meta_tags.robots_index
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.robots_follow) {
          robotsFollow = response.data.data[0].meta_tags.robots_follow
        }

        if (metaTitle) document.title = metaTitle;
        else document.title = this.state.current.name + ' | Галактика';
        if (metaDescription) {
          if (!document.querySelector('meta[name="description"]')) {
            let metaD = document.createElement('meta')
            metaD.name = 'description'
            metaD.content = metaDescription
            document.querySelector('head').appendChild(metaD)
          } else {
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
          }
        }
        let metaR = document.createElement('meta')
        metaR.name = 'robots'
        let robotsContent = ''
        if ((robotsIndex || robotsFollow) && this.state.page === 1) {
          if (robotsIndex === 'yes') {
            robotsContent += 'index'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else if (robotsIndex === 'no') {
            robotsContent += 'noindex'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else {
            if (robotsFollow === 'yes') {
              robotsContent += 'follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += 'nofollow'
            }
          }
          if (this.state.page !== 1) {
            robotsContent = 'noindex, follow'
          }
          metaR.content = robotsContent
          this.setState({
            trueRobots: robotsContent
          })
          document.querySelector('head').appendChild(metaR)
        }

      })
      .catch(err => {
        console.error(err);
        window.location.href = '/404'

      })

    let postData = {
      'slug': this.props.match.params.slug,
      'page': this.state.page,
      'arrayFilter': '',
      'manufacturer': 'no',
      'price_from': 0,
      'price_to': 'no',
      'order_by': 1
    }
    this.setState({
      isLoading: true
    })
    axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-filter`, postData)
      .then((response) => {
        this.setState({
          products: response.data.data[0],
          filters: response.data.data[1],
          manufacturers: response.data.data[2],
          pagination: response.data.data[4],
          selectedFilters: response.data.data[5].filter,
          selectedManufacturers: response.data.data[5].manufacturer[0] === 'no' ? [] : response.data.data[5].manufacturer,
          selectedColors: response.data.data[5].filter,
          order_by: response.data.data[5].orderBy,
          price_from: response.data.data[6].minPrice,
          price_to: response.data.data[6].maxPrice,
          min: response.data.data[6].minPrice,
          max: response.data.data[6].maxPrice,
          colors: response.data.data[3]
        }, () => {

          this.setState({
            isLoading: false
          })
        })

      })

  }

  componentDidMount() {
    this.fetchData();
  }

  handleClick() {
    document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  toggleFilter(e) {
    this.setState(prevState => ({
      filterToggleOn: !prevState.filterToggleOn
    }));
    let parent;
    if (e.target.type === 'button') parent = e.target.parentNode
    else parent = e.target.parentNode.parentNode
    parent.classList.toggle('collapsed')
  }

  getFilterName(id) {
    let arr = this.state.filters;
    let name = '';
    arr.map((f, i) => {
      f.filter_option.map((o, j) => {
        if (o.filter_option_id === id) name = o.filter_option_value
        return null
      })
      return null
    })
    return name;
  }

  changePageAndHandle() {
    this.handleClick();
    this.changePage(1);
  }

  static contextType = CartCtx;

  render() {
    return (
      <main className='page-category'>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href={process.env.REACT_APP_CLIENT}><i className="i-home"></i></Breadcrumb.Item>
            {this.state.crumbs.length > 0 &&
            this.state.crumbs.map((c, i) => {
              return (
                <React.Fragment key={i}>
                  {c.redirect === null &&
                  <li className={'breadcrumb-item'} key={i}>
                    {this.state.crumbs.length - 1 === i &&
                    <span>
                                                        {c.name}
                                                    </span>
                    }
                    {this.state.crumbs.length - 1 !== i &&
                    <Link to={`/${i + 1 === this.state.crumbs.length ? 'catalog' : 'category'}/${c.name_slug}`}>
                      {c.name}
                    </Link>
                    }
                  </li>
                  }
                </React.Fragment>
              )
            })
            }
          </Breadcrumb>
          <h1>{this.state.current.name}</h1>
          {this.state.current.short_description &&
          <ReadMore
            initialHeight={50}
            readMore={props => (
              <div className='readmore__button' onClick={props.onClick}>
                {props.open ? '' : 'Читать дальше'}
              </div>
            )}
          >
            <div className="readmore__caption" dangerouslySetInnerHTML={{__html: this.state.current.short_description}}>
            </div>
          </ReadMore>
          }
          {this.state.banners.length > 0 &&
          <ImageSlider items={this.state.banners}/>
          }

          <div className="catalog">
            <div className="catalog__settings flex">
              <div className="filter-selection flex">
                <div className="filter-mobile">
                  <button className="btn btn-primary" onClick={this.handleClick}>Фильтры</button>
                </div>

                <div className="filter-selection__label">
                  <p>Выбрано товаров: <span>{this.state.pagination.total}</span></p>
                </div>
                <div className='filter-selection__list'>
                  <div className='filter-selection__item'>
                    <button className="filter-selection__btn reset" onClick={this.resetFilters}>Сбросить</button>
                  </div>
                  {/* {this.state.selectedFilters.length > 0 &&
                                        this.state.selectedFilters.map((f, i) => {
                                            return (
                                                <div className='filter-selection__item' key={i}>
                                                    <button className="filter-selection__link" to="/" data-id={f} onClick={this.removeFilter}>
                                                        {
                                                            this.getFilterName(f)
                                                        }
                                                    </button>
                                                </div>
                                            )
                                        })
                                    } */}
                </div>
              </div>
              <div className="catalog-view">
                <div className="catalog-view__wrap">
                  <div className="catalog-view__sort">
                    <select value={this.state.order_by} id='order' className="select-custom"
                            onChange={this.filterProducts}>
                      <option value='1'> По умолчанию</option>
                      <option value='2'> От дешевых к дорогим</option>
                      <option value='3'> От дорогих к дешевым</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="catalog__wrap">
              <div className={`catalog-filter ${this.state.isToggleOn ? '' : 'show'}`}>
                <div className="catalog-filter__overlay" onClick={this.handleClick}/>
                <div className="catalog-filter__wrap">
                  <div className="flex catalog-filter__header">
                    <Button variant="link" className="filter_m_toggle" onClick={this.handleClick}>Фильтры</Button>
                    <div className="filter-selection__label">
                      <p>Выбрано товаров: <span>{this.state.pagination.total}</span></p>
                    </div>
                  </div>
                  <div className="catalog-filter__inner">

                    <div className="catalog-filter__footer flex top-button">
                      <Button className="filter-set" onClick={this.changePageAndHandle}>Показать</Button>
                    </div>
                    {this.state.manufacturers.length > 0 &&
                    <div className={`filter-block ${this.state.filterToggleOn ? '' : ''}`}>
                      <Button variant="link filter-block__title" onClick={this.toggleFilter}><i className="i-arrow-b"/>
                        Производители
                      </Button>
                      <div className="filter-block__inner">
                        <div className="checkbox-filter">
                          {this.state.manufacturers.map((opt, index) => {
                            return (
                              <div className="checkbox-filter__item" key={index}>
                                <input className="custom-checkbox filter-check brand-check"
                                       type="checkbox"
                                       id={'m' + opt.id}
                                       onChange={this.brandCheck}/>
                                <label htmlFor={'m' + opt.id}>
                                  {opt.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    }
                    <div className="filter-block">
                      <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                        <i className="i-arrow-b" />Цена</Button>
                      <div className="filter-block__inner">
                        {this.state.price_to &&
                        <div className="price-range">
                          <div className="price-range__wrap">
                            <label>от</label>
                            <input type="number" value={this.state.price_from} onChange={this.onLowerBoundChange}/>
                            <label>до</label>
                            <input type="number" value={this.state.price_to} onChange={this.onUpperBoundChange}/>
                            <i className="i-rub" />
                            <Button variant="primary" onClick={this.changePageAndHandle}>OK</Button>
                          </div>
                          <Range value={[this.state.price_from, this.state.price_to]} min={this.state.min}
                                 max={this.state.max} allowCross={false} onChange={this.onSliderChange}/>
                        </div>
                        }
                      </div>
                    </div>
                    {this.state.filters.map((filter, index) => {
                      return (
                        <div className="filter-block" key={index}>
                          <Button variant="link filter-block__title" onClick={this.toggleFilter}>
                            <i className="i-arrow-b" />
                            {filter.name}
                          </Button>
                          <div className="filter-block__inner">
                            <div className="checkbox-filter">
                              {filter.filter_option.map((opt, index) => {
                                return (
                                  <div className="checkbox-filter__item" key={index}>
                                    <input className="custom-checkbox filter-check"
                                           type="checkbox"
                                           id={opt.filter_option_id}
                                           onChange={this.filterCheck}/>
                                    <label htmlFor={opt.filter_option_id}>
                                      {opt.filter_option_value}
                                    </label>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="catalog-filter__footer flex">
                    <div className="filter-selection__label">
                      <p>Выбрано товаров: <span>{this.state.pagination.total}</span></p>
                    </div>
                    <Button className="filter-selection__btn reset" onClick={this.resetFilters}>Сбросить</Button>
                    <Button className="filter-set" onClick={this.changePageAndHandle}>Показать</Button>
                  </div>
                </div>
              </div>
              <div className="catalog-list">
                <div className="catalog-list__wrap" style={{
                  transition: 'opacity .4s ease',
                  opacity: this.state.isLoading ? 0 : 1,
                  pointerEvents: this.state.isLoading ? 'none' : 'auto'
                }}>

                  {this.state.products.map((product, index) => (
                    <React.Fragment key={index}>
                      {product.redirect === null &&
                      <div className="catalog-list__item" key={index}>
                        <div className="products__wrap">
                          <div className="btn-toolbar">
                            <WishList productId={product.id} productCount={product.count}/>
                          </div>
                          <div className="products-image">
                            <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                              <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                                   alt={("Изображение товара " + product.name)}/>
                            </Link>
                          </div>

                          <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                            {product.name}
                          </Link>

                          <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                            <div className="producst-price__wrap">
                              <div className="products-price__old">
                                {product.priceBadgeStock.price.discountPrice &&
                                <span
                                  className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                                }
                              </div>
                              <div className="products-price__main">
                                {product.priceBadgeStock.price.discountPrice &&
                                <span className="price i-rub" style={{color: '#d81137'}}>
                                                                            {product.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                                                                        </span>
                                }
                                {!product.priceBadgeStock.price.discountPrice &&
                                <span className="price i-rub">
                                                                            {product.price.toLocaleString('ru')}
                                                                        </span>
                                }
                              </div>
                            </div>
                            <div className="products-cart-sm">
                              {product.count > 0 && process.env.REACT_APP_TEST === 1 &&
                              <>
                                {!this.context.isInCart(product.id) &&
                                <CartCtx.Consumer>
                                  {({cart, addProduct}) => (
                                    <Button variant="simple" className="add-to-cart"
                                            onClick={() => addProduct(product.id)}><i className="i-cart"></i>
                                    </Button>
                                  )}
                                </CartCtx.Consumer>
                                }
                                {this.context.isInCart(product.id) &&
                                <CartCtx.Consumer>
                                  {({cart, addProduct}) => (
                                    <Button variant="simple" className="add-to-cart added" disabled>
                                      <i className="i-cart" /></Button>
                                  )}
                                </CartCtx.Consumer>
                                }
                              </>
                              }
                            </div>
                          </div>

                          <div className="products-stock">
                            {product.count > 0 &&
                            <p className='stock-in'>
                              В наличии
                            </p>
                            }
                            {product.count < 1 &&
                            <p className='stock-out'>
                              Нет в наличии
                            </p>
                            }
                          </div>

                          {
                            product.priceBadgeStock.bages.length > 0 &&
                            <Badges items={product.priceBadgeStock}/>
                          }
                          {product.gift && (
                            <Link to={`/product/${product.gift.name_slug}`} className="products-gift contents"
                                  style={{
                                    color: '#000',
                                    textDecoration: 'none',
                                    background: 'rgba(255,255,255,.5)',
                                    backdropFilter: 'blur(20px)'
                                  }}
                            >
                              <span className="f7 fw500">Подарок</span>
                              <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.gift.photo_url} alt="" className="img"/>
                            </Link>
                          )}
                          <div className="products__footer">
                            {product.count > 0 && process.env.REACT_APP_TEST === 1 &&
                            <>
                              {!this.context.isInCart(product.id) &&
                              <CartCtx.Consumer>
                                {({cart, addProduct}) => (
                                  <div className="cart-btn">
                                    <Button className="btn btn-primary" onClick={() => addProduct(product.id)}>В
                                      корзину</Button>
                                  </div>
                                )}
                              </CartCtx.Consumer>
                              }
                              {this.context.isInCart(product.id) &&
                              <CartCtx.Consumer>
                                {({cart, addProduct}) => (
                                  <div className="cart-btn">
                                    <Button className="btn btn-primary added" onClick={() => addProduct(product.id)}>Уже
                                      в корзине</Button>
                                  </div>
                                )}
                              </CartCtx.Consumer>
                              }
                            </>
                            }

                            {/*<div className="short-bages">
                                                            <ul className="short-bages__wrap">
                                                                <li className="bage">
                                                                    <img src="img/temp/products/b01.png" alt="" />
                                                                </li>
                                                            </ul>
                                                        </div>*/}
                            <div className="short-desc">
                              <p
                                dangerouslySetInnerHTML={{__html: product.short_discription !== 'null' && product.short_discription}}></p>
                              <p>
                                {product.character.map((item, index) => {
                                  return (
                                    <span key={index}>
                                                                                {item.nameChar}: {item.valueChar}; <br/>
                                                                            </span>
                                  )
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </React.Fragment>
                  ))}
                  {
                    this.state.pagination.lastPage === 0 &&
                    <div className="catalog-list__empty"><p>По данному запросу товаров не найдено!</p></div>
                  }
                </div>
                <div className="catalog-list__navigation">
                  {/*<div className="loadMore">
                                            <button>Показать больше</button>
                                        </div>*/}
                  {
                    this.state.pagination.lastPage > 1 &&
                    <div className="pagination">
                      <ul className="pagination__wrap">
                        <ul className="pag_arrows">
                          <li className="pagination__item pag_arrows__item bt_pprev">
                            {this.state.page === 1 &&
                            <span style={{opacity: .15}}>
                                                                <i className="i-arrow prev" style={{
                                                                  display: 'inline-block',
                                                                  transform: 'rotate(90deg) translateY(-14px)'
                                                                }}></i>
                                                                <i className="i-arrow prev"
                                                                   style={{display: 'inline-block'}}></i>
                                                            </span>
                            }
                            {this.state.page !== 1 &&
                            <Link className={`link`} to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(1)
                            }}>
                              <i className="i-arrow prev"
                                 style={{display: 'inline-block', transform: 'rotate(90deg) translateY(-14px)'}}></i>
                              <i className="i-arrow prev" style={{display: 'inline-block'}}></i>
                            </Link>
                            }
                          </li>
                          <li className="pagination__item pag_arrows__item bt_prev">
                            {this.state.page === 1 &&
                            <span style={{opacity: .15}}>
                                                                <i className="i-arrow prev"><span></span></i>
                                                            </span>
                            }
                            {this.state.page !== 1 &&
                            <Link className={`link`} to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page > 1 ? this.state.page - 1 : 1)
                            }}>
                              <i className="i-arrow prev"><span></span></i>
                            </Link>
                            }
                          </li>
                        </ul>
                        <ul className="pag_list">


                          {this.state.page > 2 && this.state.pagination.lastPage > 3 &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(1)
                            }}>
                              <b>1</b>
                            </Link>
                          </li>
                          }

                          {this.state.page > 3 && this.state.pagination.lastPage > 4 &&
                          <li className="pagination__item"><b>...</b></li>
                          }


                          {this.state.page === this.state.pagination.lastPage && this.state.pagination.lastPage > 2 &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page - 2)
                            }}>
                              <b>{this.state.page * 1 - 2}</b>
                            </Link>
                          </li>
                          }
                          {this.state.page > 1 &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page * 1 - 1)
                            }}>
                              <b>{this.state.page * 1 - 1}</b>
                            </Link>
                          </li>
                          }
                          <li className="pagination__item active">
                                                        <span className="link">
                                                            <b>{this.state.page}</b>
                                                        </span>
                          </li>
                          {this.state.page < this.state.pagination.lastPage &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page * 1 + 1)
                            }}>
                              <b>{this.state.page * 1 + 1}</b>
                            </Link>
                          </li>
                          }
                          {this.state.page === 1 && this.state.pagination.lastPage > 2 &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page * 1 + 2)
                            }}>
                              <b>{this.state.page * 1 + 2}</b>
                            </Link>
                          </li>
                          }

                          {this.state.page < this.state.pagination.lastPage * 1 - 2 && this.state.pagination.lastPage > 4 &&
                          <li className="pagination__item"><b>...</b></li>
                          }

                          {this.state.page < this.state.pagination.lastPage * 1 - 1 && this.state.pagination.lastPage > 3 &&
                          <li className="pagination__item">
                            <Link className="link" to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.pagination.lastPage)
                            }}>
                              <b>{this.state.pagination.lastPage}</b>
                            </Link>
                          </li>
                          }

                        </ul>
                        <ul className="pag_arrows">
                          <li className="pagination__item pag_arrows__item bt_next">
                            {this.state.page !== this.state.pagination.lastPage &&
                            <Link className={`link`} to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage)
                            }}>
                              <i className="i-arrow next"><span></span></i>
                            </Link>
                            }
                            {this.state.page === this.state.pagination.lastPage &&
                            <span className={`link`} style={{opacity: .15}}>
                                                                <i className="i-arrow next"><span></span></i>
                                                            </span>
                            }
                          </li>
                          <li className="pagination__item pag_arrows__item bt_nnext">
                            {this.state.page !== this.state.pagination.lastPage &&
                            <Link className={`link`} to={`/catalog/${this.props.match.params.slug}`} onClick={() => {
                              this.changePage(this.state.page < this.state.pagination.lastPage ? this.state.page * 1 + 1 : this.state.pagination.lastPage)
                            }}>
                              <i className="i-arrow next" style={{display: 'inline-block'}}></i>
                              <i className="i-arrow next"
                                 style={{display: 'inline-block', transform: 'rotate(-90deg) translateY(-14px)'}}></i>
                            </Link>
                            }
                            {this.state.page === this.state.pagination.lastPage &&
                            <span className={`link`} style={{opacity: .15}}>
                                                                <i className="i-arrow next"
                                                                   style={{display: 'inline-block'}}></i>
                                                                <i className="i-arrow next" style={{
                                                                  display: 'inline-block',
                                                                  transform: 'rotate(-90deg) translateY(-14px)'
                                                                }}></i>
                                                            </span>
                            }
                          </li>
                        </ul>
                      </ul>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="cat-desc">
            <div className="cat-desc__wrap" dangerouslySetInnerHTML={{__html: this.state.current.bottom_description}}>
            </div>
          </div>

        </Container>
      </main>
    );
  }
}

export default PageCategoryChild;
