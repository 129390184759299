import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  FullscreenControl,
  GeolocationControl,
  RouteButton,
  TrafficControl,
  TypeSelector,
} from "react-yandex-maps";

// function ContactGallery() {
//     const [currentImage, setCurrentImage] = useState(0);
//     const [viewerIsOpen, setViewerIsOpen] = useState(false);

//     const openLightbox = useCallback((event, { photo, index }) => {
//         setCurrentImage(index);
//         setViewerIsOpen(true);
//     }, []);

//     const closeLightbox = () => {
//         setCurrentImage(0);
//         setViewerIsOpen(false);
//     };
//     const photos = [
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         }
//     ];
//     return (
//         <div>
//             <Gallery photos={photos} onClick={openLightbox} />
//             <ModalGateway>
//                 {viewerIsOpen ? (
//                     <Modal onClose={closeLightbox}>
//                         <Carousel
//                             currentIndex={currentImage}
//                             views={photos.map(x => ({
//                                 ...x,
//                                 srcset: x.srcSet,
//                                 caption: x.title
//                             }))}
//                         />
//                     </Modal>
//                 ) : null}
//             </ModalGateway>
//         </div>
//     );
// }

class ContactGorlovka extends Component {
  render() {
    const mapData = {
      center: [48.317159, 37.964373],
      zoom: 15,
      controls: [],
    };

    const coordinates = [[48.317159, 37.964373]];
    return (
      <div className="contact__wrap">
        <div className="contact__title">
          <h1>Торговый центр Горловка</h1>
          <p>ул. Маршала Жукова, 7</p>
        </div>
        <div className="contact__main">
          <Row>
            <Col sm={12} md={5} xl={4}>
              <div className="contact__worktime">
                <div className="worktime-header">
                  <h3>
                    <i className="i-calendar"></i>График работы:
                  </h3>
                </div>
                <div className="worktime-content">
                  <div className="weekdays flex">
                    <div className="left-side">
                      <p>ПН-ЧТ</p>
                    </div>
                    <div className="right-side">
                      <p>08:00 - 21:00</p>
                    </div>
                  </div>
                  <div className="weekends flex">
                    <div className="left-side">
                      <p>ПТ-ВС</p>
                    </div>
                    <div className="right-side">
                      <p>08:00 - 21:00</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-place">
                <div className="contact-place__item">
                  <i className="i-map orange"></i>
                  <div className="place-info">
                    <p>Адрес ТЦ</p>
                    <span>ул. Маршала Жукова, 7, Горловка</span>
                  </div>
                </div>
                <div className="contact-place__item">
                  <i className="i-square orange"></i>
                  <div className="place-info">
                    <p>Площадь ТЦ</p>
                    <span>11 000 кв.м.</span>
                  </div>
                </div>
                <div className="contact-place__item">
                  <i className="i-opening orange"></i>
                  <div className="place-info">
                    <p>Дата открытия ТЦ</p>
                    <span>01.07.2015</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={7} xl={8}>
              <div className="contact__map">
                <YMaps>
                  <Map defaultState={mapData} width="100%" height="390px">
                    {coordinates.map((coordinate) => (
                      <Placemark geometry={coordinate} />
                    ))}
                    <ZoomControl options={{ float: "right" }} />
                    <FullscreenControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <RouteButton options={{ float: "left" }} />
                    <TrafficControl options={{ float: "right" }} />
                    <TypeSelector options={{ float: "right" }} />
                  </Map>
                </YMaps>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contact__numbers">
          <Row>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Центральное инфо</h5>
                <ul className="numbers__wrap">
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="tel:+78564559922"
                    >
                      <span className="label">Городской:</span>
                      <span>+7 (8564) 55-99-22</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79493543104"
                    >
                      <span className="label viber">Мобильный:</span>
                      <span>+7 (949) 354-31-04</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mail"
                      href="mailto:info@gl.galaktika.me"
                    >
                      <span className="label">E-mail:</span>
                      <span>info@gl.galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Отдел безналичного расчета</h5>
                <ul className="numbers__wrap">
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="tel:+78565544861"
                    >
                      <span className="label">Городской:</span>
                      <span>+7 (856) 554-48-61</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel: +79493286072"
                    >
                      <span className="label">Мобильный:</span>
                      <span> +7 (949) 328-60-72</span>
                    </a>
                  </li>
                  
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mail"
                      href="mailto:beznal1@gl.galaktika.me"
                    >
                      <span className="label">E-mail:</span>
                      <span>beznal1@gl.galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Доставка</h5>
                <ul className="numbers__wrap">
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="tel:+78564559928"
                    >
                      <span className="label">Городской:</span>
                      <span>+7 (8564) 55-99-28</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79493543084"
                    >
                      <span className="label">Мобильный:</span>
                      <span>+7 (949) 354-30-84</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mail"
                      href="mailto:delivery@gl.galaktika.me"
                    >
                      <span className="label">E-mail:</span>
                      <span>delivery@gl.galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="contact__gallery">
                    <h4>Фотогалерея</h4>
                    <ContactGallery />
                </div> */}
      </div>
    );
  }
}
export default ContactGorlovka;
