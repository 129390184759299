import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from './components/main/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
//Components
import PageHome from './components/home/PageHome';
import PagePromo from './components/promo/PagePromo';
import PagePromoChild from './components/promo/PagePromoChild';
import PagePromoCategory from './components/promo/PagePromoCategory';
import PageCategory from './components/categories/PageCategory';
import PageCategoryChild from './components/categories/PageCategoryChild';
import PageSingleProduct from './components/signleProduct/SingeProduct';
import PageContact from "./components/contact/PageContact";
import PageCheckout from "./components/checkout/PageCheckout";
import SearchResults from "./components/main/SearchResults";
import PageProfileMain from "./components/profile/PageProfileMain";
import PageProfileMainEdit from "./components/profile/PageProfileMainEdit";
import PagePOrders from "./components/profile/orders";
import PagePViewedProducts from "./components/profile/viewed-products";
import PagePWaitingList from "./components/profile/waiting-list";
import PagePWishlist from "./components/profile/wishlist";
import PageAbout from "./components/about/PageAbout";
import Recovery from "./components/profile/Recovery";
import NotFound from "./components/error/NotFound";
import axios from 'axios';

import CartCtx from './components/CartCtx'


//SCSS
import './App.css';
import './scss/main.scss';
import Header from "./components/main/Header";
import Footer from './components/main/Footer';
import PagePromoChild2 from "./components/promo/PagePromoChild2";
// import PagePromoChildType6 from "./components/promo/PagePromoChildType6";
import SearchPromo from "./components/promo/components/SearchPromo";

if (process.env.REACT_APP_TEST !== `1`)
  require('./scss/hidecart.scss')

class App extends Component {
  constructor(props) {
    super(props)
    axios.get(`${process.env.REACT_APP_API_BASE}/pages/background`)
      .then(response => {
        if (response.data.data) {
          document.body.style.backgroundImage = `url(${process.env.REACT_APP_BACKEND + response.data.data})`
          const bgA = document.createElement('a')
          bgA.setAttribute('style', `display: block;position: fixed;top: 0;left: 0;width: 100vw;height: 100vh;`)
          bgA.href = '/'
        }
      })
      .catch(error => {
        console.error(error);
      })
    this.addProduct = (id) => {
      let product = null;
      let currentCart = this.state.cart;

      axios.get(`${process.env.REACT_APP_API_BASE}/products/${id}`)
        .then((response => {
          const prodData = response.data.data
          let price = prodData.price
          if (prodData.priceBadgeStock.price.discountPrice) price = prodData.priceBadgeStock.price.discountPrice

          product = {
            product_id: prodData.id,
            name: prodData.name,
            count: 1,
            price: price,
            img: prodData.photo_url,
            name_slug: prodData.name_slug,
            code: prodData.articule
          }

          let index = null;
          currentCart.filter(function (obj, i) {
            if (obj.product_id === id) {
              index = i;
              return true;
            }
            return null
          })

          if (index !== null) {
            currentCart[index].count++
          } else {
            currentCart[currentCart.length] = product
          }

          this.setState({
            cart: currentCart
          })

          localStorage.setItem('galaktika-cart', JSON.stringify(this.state.cart))

        }))
        .catch(err => {
          console.error(err);
        })
    };

    this.isInCart = (id) => {
      let currentCart = this.state.cart;
      if (currentCart.find(obj => obj.product_id === id)) return true
      return false
    }
    this.removeProduct = (id) => {
      let currentCart = this.state.cart;
      let index = null;
      currentCart.filter(function (obj, i) {
        if (obj.product_id === id) {
          index = i;
          return true;
        }
        return null
      })
      currentCart.splice(index, 1)
      this.setState({
        cart: currentCart
      })
      localStorage.setItem('galaktika-cart', JSON.stringify(this.state.cart))
    }
    this.increaseProduct = (id) => {
      let currentCart = this.state.cart;
      let index = null;
      currentCart.filter(function (obj, i) {
        if (obj.product_id === id) {
          index = i;
          return true;
        }
        return null
      })
      let singlePrice = currentCart[index].price / currentCart[index].count
      currentCart[index].count++
      currentCart[index].price = singlePrice * currentCart[index].count
      this.setState({
        cart: currentCart
      })
      localStorage.setItem('galaktika-cart', JSON.stringify(this.state.cart))
    }
    this.decreaseProduct = (id) => {
      let currentCart = this.state.cart;
      let index = null;
      currentCart.filter(function (obj, i) {
        if (obj.product_id === id) {
          index = i;
          return true;
        }
        return null
      })
      let singlePrice = currentCart[index].price / currentCart[index].count
      if (currentCart[index].count > 1) {
        currentCart[index].count--
        currentCart[index].price = singlePrice * currentCart[index].count
      }
      this.setState({
        cart: currentCart
      })
      localStorage.setItem('galaktika-cart', JSON.stringify(this.state.cart))
    }
    this.getTotalCount = () => {
      let currentCart = this.state.cart;
      let count = 0;
      currentCart.map((el, i) => {
        count += el.count
        return null
      })
      return count
    }
    this.getTotalSumm = () => {
      let currentCart = this.state.cart;
      let price = 0;
      currentCart.map((el, i) => {
        price += el.price
        return null
      })
      return price
    }
    this.clearCart = () => {
      this.setState({
        cart: []
      })
      localStorage.removeItem('galaktika-cart')
    }
    this.state = {
      cart: localStorage.getItem('galaktika-cart') ? JSON.parse(localStorage.getItem('galaktika-cart')) : [],
      addProduct: this.addProduct,
      removeProduct: this.removeProduct,
      increaseProduct: this.increaseProduct,
      decreaseProduct: this.decreaseProduct,
      clearCart: this.clearCart,
      isInCart: this.isInCart,
      getTotalCount: this.getTotalCount,
      getTotalSumm: this.getTotalSumm,
    };

  }

  render() {
    return (
      <CartCtx.Provider value={this.state}>
        <Router basename={'/'}>
          <ScrollToTop>
            {/* <div id="site" className="main_layout" style={{backgroundImage: `url(${require("./img/temp/products/school.jpg")})`}}> */}
            <div id="site" className="main_layout">
              {/*<a href='/' style={{*/}
              {/*  display: 'block',*/}
              {/*  position: 'fixed',*/}
              {/*  top: 0,*/}
              {/*  left: 0,*/}
              {/*  width: '100vw',*/}
              {/*  height: '100vh',*/}
              {/*  zIndex: 0*/}
              {/*}} className="d-none d-lg-block">&nbsp;</a>*/}
              <Header/>
              <div className="container bg-white">
                <Switch>
                  {/* <Route exact path='/sale/:id' component={PageHomeAkciya} /> */}
                  <Route exact path='/(\d+)' render={props => {
                    axios.get(`${process.env.REACT_APP_API_BASE}/products/slug-by-articule/${props.match.params[0]}`)
                      .then(res => {
                        if (!res.data.data) {
                          window.location.replace('/404.html?error=product-not-found')
                        } else {
                          window.location.replace(`/product/${res.data.data.productSlug}?utm_source=market&utm_medium=offline&utm_campaign=offline_qr_code&utm_content=${res.data.data.categorySlug}&utm_term=${res.data.data.articule}`)
                        }
                      })
                      .catch(err => {
                        console.error(err)
                      })
                  }}/>
                  <Route exact path='/' component={PageHome}/>
                  {/* <Route exact path='/akciya' component={PageSale} /> */}
                  <Route exact path='/category/:slug' component={PageCategory} RouteKey={true}/>
                  <Route exact path='/catalog/:slug' component={PageCategoryChild}/>
                  <Route exact path='/catalog/:slug/:page' component={PageCategoryChild}/>
                  <Route exact path='/product/:slug' component={PageSingleProduct}/>
                  <Route exact path='/contact' component={PageContact}/>
                  <Route exact path='/search/:page' component={SearchResults}/>
                  <Route exact path='/checkout' component={PageCheckout}/>
                  <Route exact path='/profile' component={PageProfileMain}/>
                  <Route exact path='/profile-edit' component={PageProfileMainEdit}/>
                  <Route exact path='/profile/orders' component={PagePOrders}/>
                  {/*<Route exact path='/profile/orders/:page' component={PagePOrders} />*/}
                  <Route exact path='/profile/viewed-products' component={PagePViewedProducts}/>
                  <Route exact path='/profile/waiting-list' component={PagePWaitingList}/>
                  <Route exact path='/profile/wishlist' component={PagePWishlist}/>
                  <Route exact path='/forgotPassword/:token/:mail' component={Recovery}/>
                  <Route exact path='/about' component={PageAbout}/>
                  {/*<Route exact path='/404' component={NotFound} status={404} />*/}
                  {/*<Route exact path='/404.html' />*/}
                  <Route exact path='/promo' component={PagePromo}/>
                  <Route exact path='/promo/:slug' component={PagePromoChild}/>
                  <Route exact path='/promo-brand/:stock' component={PagePromoCategory}/>
                  {/* категории при переходе со страницы Все Акции */}
                  {/*<Route exact path='/promo/all/:category' component={props => <PagePromoChild2 {...props}/>}/>*/}
                  {/* категории при переходе со страницы Конкретной Акции */}
                  <Route exact path='/promo/:stock/:category' component={props => <PagePromoChild2 {...props}/>}/>
                  {/*<Route exact path='/promo/category/:slug' component={PagePromoChild}/>*/}
                  <Route exact path='/promo/catalog/:stock/:category' component={PagePromoCategory}/>
                  <Route exact path='/stock-search/:page' component={SearchPromo}/>
                  <Route component={NotFound} status={404}/>
                </Switch>
              </div>
              <Footer/>
            </div>
          </ScrollToTop>
        </Router>
      </CartCtx.Provider>
    );
  }
}

export default App;
