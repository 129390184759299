
// React 
import React, { Component, useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import ProfileNav from '../SideNav';
import { Button, Container, Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//SCSS
import "../../../scss/components/pages/profile/profile.scss"
import axios from "axios";


function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
        <div className={`order-header__wrap flex ${isCurrentEventKey ? 'open' : ''}`} >
            {children}
        </div>
    );
}



class PagePOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            pageData: {},
            page: 1
        }
        this.changePage = this.changePage.bind(this)
    }
    componentDidMount() {
        this.fetchOrders()
    }
    changePage(e) {
        e.preventDefault()
        let p = e.currentTarget.getAttribute('data-n')
        document.querySelector(`li[data-n].active`).classList.remove('active')
        document.querySelector(`li[data-n="${p}"]`).classList.add('active')
        this.setState({
            page: p
        }, function(){
            this.fetchOrders()
        })
    }
    fetchOrders() {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/my-orders`, {
            jwt: localStorage.getItem('galaxy-token'),
            page: this.state.page
        }).then(response => {
            this.setState({
                orders: response.data.data.order_list,
                pageData: response.data.data.pages
            }, () => {
                // console.log(this.state.orders, this.state.pageData)
            })
        }).catch(err => {
            console.error(err)
        })
    }
    buildPages(){
        let pseudoArray = []
        for(let n = 1; n<=this.state.pageData.lastPage*1; n++) {
            pseudoArray.push(n)
        }
        return pseudoArray.map((num,index) =>
            <li className={`pagination__item ${this.state.page === num ? 'active' : ''}`} key={num} data-n={num} onClick={this.changePage}>
                <a href='!#' className={`link`}>
                    <b>{num}</b>
                </a>
            </li>
        )
    }
    getAddressString(item) {
        let str = ''
        if(item.delivery !== '5') {
            str += 'Самовывоз: '
            str += item.delivery === '1' ? 'г. Макеевка, просп. 250-летия Донбасса, 74' : ''
            str += item.delivery === '2' ? 'г. Донецк, ул. Шутова, 37' : ''
            str += item.delivery === '3' ? 'г. Горловка, ул. Маршала Жукова, 7' : ''
            str += item.delivery === '4' ? 'г. Донецк ЖД, ул. Соколиная, 38' : ''
        } else {
            if(item.city) str += item.city
            if(item.address) str += ', ' +  item.address
            if(item.home) str += ', д. ' +  item.home
            if(item.flat) str += ', кв. ' +  item.flat
        }
        return str
    }
    render() {
        return (
            <main className='page-profile'>
                <Container>
                    <ProfileNav />
                    <section className="prof-main pp-orders">
                        <div className="breadcrumbs">
                            <div className="breadcrumbs__item">
                                <h1>Личный кабинет</h1>
                            </div>
                            <div className="breadcrumbs__item">
                                <h2>Заказы</h2>
                            </div>
                        </div>
                        <div className="prof-content pp-profile">
                            <div className="prof-content__header flex">
                                <p>Всего заказов: <span>{this.state.pageData.total}</span></p>
                                {/*<Button variant="outline-primary" className="btn-burger">*/}
                                {/*    <span className="burger"></span>*/}
                                {/*    Свернуть всё*/}
                                {/*</Button>*/}
                            </div>
                            <div className="prof-content__body">
                                <Accordion className="orders">
                                    {this.state.orders.length > 0 &&
                                        this.state.orders.map((item, index) => {
                                            return (
                                                <Card className="order" key={index}>
                                                    <Card.Header className="order-header">
                                                        <ContextAwareToggle eventKey={`${index}`}>
                                                            <Accordion.Toggle as={Button} variant="simple" className="order-btn" eventKey={`${index}`}>
                                                                <i className="i-arrow"></i>
                                                                <p>Заказ #<span>{item.order_numb}</span></p>
                                                            </Accordion.Toggle>
                                                            <div className="order-header__main">
                                                                <div className="order-header__items">
                                                                    {item.products.map((product, index) => {
                                                                        return(
                                                                            <>
                                                                            {product.status === 'show' && 
                                                                            <div className="item" key={index}>
                                                                                <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url} alt="" />
                                                                            </div>
                                                                            }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="order-header__info">
                                                                    <div className="order-header__total">
                                                                        <p>Товаров <span>{item.count_products}</span> на сумму:</p>
                                                                        <b className="i-rub">{item.order_summ.toLocaleString('ru')}</b>
                                                                    </div>
                                                                    <div className="order-header__date">{item.created_at}</div>
                                                                    <div className="order-header__status">
                                                                        <div className={`status ${item.order_status === "Заказ выполнен" ? "complete" : "taken"}`}>
                                                                            <span>{item.order_status}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*<div className="order-header__print">*/}
                                                            {/*    <div className="order-header__date">21.06.2020  17:55</div>*/}
                                                            {/*    <Button variant="simple">*/}
                                                            {/*        <svg><use xlinkHref="#i_print" /></svg>*/}
                                                            {/*    </Button>*/}
                                                            {/*</div>*/}
                                                        </ContextAwareToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={`${index}`} className="order-body">
                                                        <Card.Body className="order-body__wrap">
                                                            <div className="order-main">
                                                                <div className="order-main__status">
                                                                    <div className={`status ${item.order_status === "Заказ выполнен" ? "complete" : "taken"}`}>
                                                                        <p>Статус</p>
                                                                        <h3>{item.order_status}</h3>
                                                                    </div>
                                                                </div>
                                                                <Accordion className="order-info">
                                                                    <Card className="order-infull">
                                                                        <Card.Header className="order-infull__header">
                                                                            <ContextAwareToggle eventKey="900">
                                                                                <Accordion.Toggle as={Button} variant="simple" className="order-btn" eventKey="900">
                                                                                    <span>Полная информация о заказе</span>
                                                                                    <i className="i-arrow"></i>
                                                                                </Accordion.Toggle>
                                                                            </ContextAwareToggle>
                                                                        </Card.Header>
                                                                        <Accordion.Collapse eventKey="900" className="order-infull__body">
                                                                            <Card.Body className="info-flex">
                                                                                <div className="line">
                                                                                    <b>ФИО</b>
                                                                                    <p>{item.name}</p>
                                                                                </div>
                                                                                <div className="line">
                                                                                    <b>Телефон</b>
                                                                                    <p>{item.phone}</p>
                                                                                </div>
                                                                                <div className="line">
                                                                                    <b>E-mail</b>
                                                                                    <p>{item.email}</p>
                                                                                </div>
                                                                                <div className="line">
                                                                                    <b>Способ оплаты</b>
                                                                                    <p>{item.payment === '1' ? 'Наличные' : 'Безналичный расчёт'}</p>
                                                                                </div>
                                                                                <div className="line">
                                                                                    <b>Адрес</b>
                                                                                    <p>{this.getAddressString(item)}</p>
                                                                                </div>
                                                                                <div className="line">
                                                                                    <b>Комментарий</b>
                                                                                    <p>{item.comment_user}</p>
                                                                                </div>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>
                                                                    <Card className="order-infull">
                                                                        <Card.Header className="order-infull__header">
                                                                            <ContextAwareToggle eventKey="901">
                                                                                <Accordion.Toggle as={Button} variant="simple" className="order-btn" eventKey="901">
                                                                                    <span>История заказа</span>
                                                                                    <i className="i-arrow"></i>
                                                                                </Accordion.Toggle>
                                                                            </ContextAwareToggle>
                                                                        </Card.Header>
                                                                        <Accordion.Collapse eventKey="901" className="order-history__body">
                                                                            <Card.Body className="info-flex">
                                                                                <ul className="history-list">
                                                                                    {item.history.map((hist,index) => {
                                                                                        return (
                                                                                            <li className="history-list__item" key={index}>
                                                                                                <b className="date">{hist.date}</b>
                                                                                                <ul className="history-list__statuses">
                                                                                                    {hist.item.map((item,huindex) => {
                                                                                                        return(
                                                                                                            <li className="history-list__status" key={huindex}>
                                                                                                                <span className="time">{item.time}</span>
                                                                                                                <p className="status complete">{item.name}</p>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            </Card.Body>
                                                                        </Accordion.Collapse>
                                                                    </Card>
                                                                </Accordion>
                                                            </div>
                                                            <div className="order-products">
                                                                <div className="order-products__wrap">
                                                                    <div className="cart-items">
                                                                        <div className="cart-items__wrap">
                                                                            {
                                                                                item.products.map((product, index) => {
                                                                                    return (
                                                                                        <div key={index}>
                                                                                        {product.status === 'show' &&
                                                                                                <div className="cart-item" key={index}>
                                                                                                    <div className="products-image">
                                                                                                        <Link className="products-image__link"
                                                                                                            to="/">
                                                                                                            <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                                                                                                                alt=""/>
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                    <div className="products-info">
                                                                                                        <div className="products-info__top">
                                                                                                            <Link className="products-title" to={`/product/${product.name_slug}`}>
                                                                                                                {product.name}
                                                                                                            </Link>
                                                                                                        </div>

                                                                                                        <div className="products-info__bottom">
                                                                                                            <div className="products-price in-stock">
                                                                                                                <div className="products-price__wrap">
                                                                                                                    <p className="product-header__code">Код: <span>
                                                                                                                        {product.articule && product.articule}
                                                                                                                        {!product.articule && product.photo_url.replace('.jpg','')}
                                                                                                                    </span>
                                                                                                                    </p>
                                                                                                                    <div className="products-price__main">
                                                                                                                        <span className="price i-rub">
                                                                                                                            {product.price.toLocaleString('ru')}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="products-sum">
                                                                                                                <div className="products-qty">
                                                                                                                    {product.count} шт.
                                                                                                                </div>
                                                                                                                <div className="products-sum__total">
                                                                                                                    <span className="price i-rub">
                                                                                                                        {product.summ.toLocaleString('ru')}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                        }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="order-products__footer">
                                                                    <p>Всего к оплате:</p>
                                                                    <span className="i-rub">{item.order_summ.toLocaleString('ru')}</span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })
                                    }
                                </Accordion>
                            </div>
                            <div className="catalog-list__navigation">
                                <div className="pagination">
                                    <ul className="pagination__wrap">
                                        {
                                            (this.state.pageData && this.state.pageData.lastPage > 1) ? this.buildPages() : ''
                                        }
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </section>
                </Container>
            </main >
        )
    }
}

export default PagePOrders;
