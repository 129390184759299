
// React 
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CartMain from '../cart/cartMain';
import CartCtx from "../CartCtx";


// const contextType = CartCtx;

class CheckoutCartModal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showCart: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
    }

    handleClose = () => this.setState({
        showCart: false
    })
    handleShow = () => this.setState({
        showCart: true
    })
    static contextType = CartCtx;
    render() {

        return (
            <>
                <Button variant="link" className="nav-list__link" onClick={this.handleShow}>
                    <svg><use xlinkHref="#i_cart" /></svg>
                    <p>Корзина</p>
                </Button>
                <Modal
                    show={this.state.showCart}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-cart"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Корзина</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CartMain />
                        <Button variant="link" onClick={this.handleClose} className="cart-close">Назад</Button>
                        {this.context.getTotalCount() > 0 &&
                        <Link to="/checkout" className="btn btn-primary to-checkout" onClick={this.handleClose}>Оформить
                            заказ</Link>
                        }
                    </Modal.Body>
                </Modal>
            </>
        )

    }


}

export default CheckoutCartModal;
