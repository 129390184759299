
// React 
import React, { Component } from 'react';
import ProfileNav from './SideNav';
import { Container, Form } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
//SCSS
import "../../scss/components/pages/profile/profile.scss"
import axios from "axios";

class PagePMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            goback: false,
            userData: {}
        }
    }
    getPets() {
        let count = 0
        return this.state.userData.pets.map((item, index) => {
            if(item.status !== 'no_selected') {
                count++
            }
            if(item.status !== 'no_selected') {
                return (
                    <span key={index}>
                        {count>1 ? ', '+item.name : item.name}
                    </span>
                )
            }
            else return null
        })
    }
    getHobbies() {
        let count = 0
        return this.state.userData.hobbies.map((item, index) => {
            if(item.status !== 'no_selected') {
                count++
            }
            if(item.status !== 'no_selected') {
                return (
                    <span key={index}>
                        {count>1 ? ', '+item.name : item.name}
                    </span>
                )
            } 
            else return null
        })
    }
    getDate(dateString) {
        let d = new Date(dateString)
        return d.toLocaleDateString()
    }
    closeSucC() {
        if(document.querySelector('#suc-c')) {
            setTimeout(()=>{
                document.querySelector('#suc-c').style.display = 'none'
            }, 3000)
        }
    }
    componentDidMount() {
        if(!localStorage.getItem('galaxy-token')) {
            this.setState({
                goback: true
            })
        }
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {
            // console.log(response.data.data)
            this.setState({
                userData: response.data.data
            })
        }).catch(err => {
            console.error(err)
        })
    }
    render() {
        return (
            <main className='page-profile'>
                {this.state.goback && <Redirect to='/' />}
                {this.state.userData.info &&
                <Container>
                    <ProfileNav />
                    <section className="prof-main">
                        <div className="breadcrumbs">
                            <div className="breadcrumbs__item">
                                <h1>Личный кабинет</h1>
                            </div>
                            <div className="breadcrumbs__item">
                                <h2>Профиль</h2>
                            </div>
                        </div>

                        {(this.props.location.state && this.props.location.state.saved) &&
                        <div className="p-3 bg-success text-white rounded shadow" id='suc-c' style={{
                            top: 165,
                            position: "fixed",
                            right: 15,
                            zIndex: 9999,
                            fontSize: 18,
                            fontWeigth: 'bold'
                        }}>
                            Профиль успешно сохранён
                        </div>}
                        {(this.props.location.state && this.props.location.state.saved) &&
                            this.closeSucC()
                        }
                        <div className="prof-content pp-profile">
                            <div className="prof-content__header">
                                {/* <Button variant="outline-primary">Редактировать профиль</Button> */}
                                <Link to="/profile-edit" className="btn btn-outline-primary">Редактировать профиль</Link>
                            </div>
                            <div className="prof-content__body">
                                <div className="fields-wrap">
                                    <h5 className="title">Основные данные</h5>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title">Логин</Form.Label>
                                            <div className="input-field">
                                                <p>
                                                    {this.state.userData.info.username && this.state.userData.info.username}
                                                </p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                             <Form.Label className="title">ФИО</Form.Label>
                                            <div className="input-field">
                                                <p>
                                                    {this.state.userData.info.name && this.state.userData.info.name}
                                                    &nbsp;
                                                    {this.state.userData.info.middle_name && this.state.userData.info.middle_name}
                                                    &nbsp;
                                                    {this.state.userData.info.surname && this.state.userData.info.surname}
                                                </p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="002">
                                        <div className="form-field">
                                             <Form.Label className="title">Email</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.email}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {/*<Form.Group controlId="003">*/}
                                    {/*    <div className="form-field">*/}
                                    {/*         <Form.Label className="title">Пароль</Form.Label>*/}
                                    {/*        <div className="input-field">*/}
                                    {/*            <p>*******************</p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group controlId="004">
                                        <div className="form-field">
                                             <Form.Label className="title">Телефон</Form.Label>
                                            <div className="input-field">
                                                <p>
                                                    {this.state.userData.info.phones.length>0 &&
                                                    this.state.userData.info.phones.join(', ')
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="005">
                                        <div className="form-field">
                                             <Form.Label className="title">Адрес</Form.Label>
                                            <div className="input-field">
                                                <p>
                                                    {this.state.userData.info.city && this.state.userData.info.city}
                                                    {this.state.userData.info.street && ', ' + this.state.userData.info.street}
                                                    {this.state.userData.info.home && ', д. ' + this.state.userData.info.home}
                                                    {this.state.userData.info.flat && ', кв. ' + this.state.userData.info.flat}
                                                </p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="fields-wrap">
                                    <h5 className="title">Дополнительная информация</h5>
                                    <Form.Group controlId="006">
                                        <div className="form-field">
                                             <Form.Label className="title">Пол</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.gender==='m' ? 'Мужской' : (this.state.userData.info.gender==='f' ? 'Женский' : '')}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="007">
                                        <div className="form-field">
                                             <Form.Label className="title">Дата рождения</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.date_birthday && this.getDate(this.state.userData.info.date_birthday)}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="008">
                                        <div className="form-field">
                                             <Form.Label className="title">Мои питомцы</Form.Label>
                                            <div className="input-field">
                                                <p>{this.getPets()}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="009">
                                        <div className="form-field">
                                             <Form.Label className="title">Мои интересы</Form.Label>
                                            <div className="input-field">
                                                <p>{this.getHobbies()}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="010">
                                        <div className="form-field">
                                             <Form.Label className="title">Дети</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.child === null ? 'Нет' : 'Да'}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="011">
                                        <div className="form-field">
                                            {this.state.userData.info.vin_auto === 'VIN' && 
                                                <>
                                                    <Form.Label className="title">Автомобиль</Form.Label>
                                                    <div className="input-field">
                                                        <p>Да</p>
                                                    </div>
                                                </>
                                            }
                                            {this.state.userData.info.vin_auto !== 'VIN' && 
                                                <>
                                                    <Form.Label className="title">
                                                    {this.state.userData.info.vin_auto ? 'VIN автомобиля' : 'Автомобиль'}
                                                        </Form.Label>
                                                    <div className="input-field">
                                                        <p>
                                                            {this.state.userData.info.vin_auto ? this.state.userData.info.vin_auto : 'Нет'}
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="fields-wrap">
                                    <h5 className="title">Юридическое лицо</h5>
                                    <Form.Group controlId="012">
                                        <div className="form-field">
                                             <Form.Label className="title">Полное название</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.company_name && this.state.userData.info.company_name}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="013">
                                        <div className="form-field">
                                             <Form.Label className="title">Код ЕГРПОУ</Form.Label>
                                            <div className="input-field">
                                                <p>{this.state.userData.info.company_inn && this.state.userData.info.company_inn}</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="prof-content__footer">
                                {/* <Button variant="outline-primary">Редактировать профиль</Button> */}
                                <Link to="/profile-edit" className="btn btn-outline-primary">Редактировать профиль</Link>
                            </div>
                        </div>
                    </section>
                </Container>
                }
            </main >
        )
    }
}

export default PagePMain;
