
// React 
import React, { Component } from 'react';
import {Button, Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Pdf from "react-to-pdf";
import CartCtx from '../CartCtx';
import logo from "../../img/logo-f.svg";

const ref = React.createRef();
class CheckoutCompleteModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCart: true
        }
        this.handleClose = this.handleClose.bind(this)
        this.onPdfComplete = this.onPdfComplete.bind(this)
    }
    handleClose() {
        this.setState({
            showCart: false
        })
        this.context.clearCart()
    }
    onPdfComplete() {
        //this.context.clearCart()
    }
    static contextType = CartCtx;
    render() {
        
        return (
            <>
                <Modal
                    show={this.state.showCart}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-checkout__complete"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Ваш заказ принят!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text">
                            <h5>Благодарим!</h5>
                            <h5>Ваш заказ отправлен в обработку.</h5>
                            <p>Номер заказа: <b>{this.props.num}</b></p>
                            <span>В ближайшее время с Вами свяжется ваш менеджер</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Pdf targetRef={ref} filename={`order-${this.props.num}.pdf`} onComplete={this.onPdfComplete} options={{
                            format: [600.00, 300 + 50*this.context.cart.length < 900 ? 900 : 300 + 50*this.context.cart.length]
                        }}>
                            {({ toPdf }) => <Button variant="light" onClick={toPdf}>Сохранить заказ в PDF <i className="i-pdf" /></Button>}
                        </Pdf>
                        {/* <Button variant="light" onClick={this.handleClose}>Скачать детали в PDF <i className="i-pdf" /></Button> */}
                        <Link to="/" className="btn btn-primary" onClick={this.handleClose}>Вернуться на главную</Link>
                    </Modal.Footer>
                </Modal>
                <div ref={ref} className="modal-cart__wrap" style={{
                    position: 'fixed',
                    top: 0,
                    left: -750,
                    zIndex: -1,
                    /* left: 0,
                    zIndex: 1111111111111, */
                    width: '750px',
                    minHeight: '2000px',
                    background: '#ffffff'
                }}>
                    <div className="pdf-view">
                        <header>
                            <img src={logo} alt="Логотип" />
                            <p>Детали заказа</p>
                        </header>
                        <section className="customer-info">
                            <p>ЗкС-№: <b>{this.props.num}</b></p>
                            <p>Имя покупателя: <b>{this.props.cName}</b></p>
                            <p>Тел. покупателя: <b>{this.props.cPhone}</b></p>
                            <p>Email покупателя: <b>{this.props.cEmail}</b></p>
                            {this.props.companyName &&
                            <p>Название компании: <b>{this.props.companyName}</b></p>
                            }
                            {this.props.companyInn &&
                            <p>Код ЕГРПОУ: <b>{this.props.companyInn}</b></p>
                            }
                            <p>Способ доставки:
                                    {this.props.del === "1" &&
                                    <b> Самовывоз из ТЦ 'Галактика' №1 (г. Макеевка, просп. 250-летия Донбасса, 74)</b>
                                }
                                {this.props.del === '2' &&
                                    <b> Самовывоз из ТЦ 'Галактика' №2 (г. Донецк, ул. Шутова, 37)</b>
                                }
                                {this.props.del === '3' &&
                                    <b> Самовывоз из ТЦ 'Галактика' №3 (г. Горловка, ул. Маршала Жукова, 7)</b>
                                }
                                {this.props.del === '4' &&
                                    <b> Самовывоз из ТЦ 'Галактика' №4 (г. Донецк ЖД, ул. Соколиная, 38)</b>
                                }
                                {this.props.del === '5' &&
                                    <b> Доставка ТЦ 'Галактики'. <br /> Адрес: {this.props.cAddress}</b>
                                }
                            </p>
                            <p>Способ оплаты:
                                    {this.props.paym === '1' &&
                                    <b> Наличный расчёт</b>
                                }
                                {this.props.paym === '2' &&
                                    <b> Безналичный расчёт</b>
                                }
                            </p>
                            {this.props.comment &&
                                <p>Комментарий к заказу: <b>{this.props.comment}</b></p>
                            }
                        </section>
                        <hr />
                        <h4>Состав заказа</h4>
                        <section className="customer-items">
                            <div className="cart-items">
                                {/* <div className="cart-table">
                                    <div className="cart-table__header">
                                        <div className="hb col-num">№</div>
                                        <div className="hb col-articule">Артикул</div>
                                        <div className="hb col-name">Название</div>
                                        <div className="hb col-price">Цена</div>
                                        <div className="hb col-qty">Кол-во</div>
                                        <div className="hb col-total">Сумма</div>
                                    </div>
                                    <div className="cart-table__body">
                                        <div className="col-num"></div>
                                        <div className="col-articule">Артикул</div>
                                        <div className="col-name">Название</div>
                                        <div className="col-price">Цена</div>
                                        <div className="col-qty">Кол-во</div>
                                        <div className="col-total">Сумма</div>
                                    </div>
                                </div> */}
                                <table className="cart-table">
                                    <thead className="cart-table__header">
                                        <tr>
                                            <th className="col-num">№</th>
                                            <th>Артикул</th>
                                            <th>Название</th>
                                            <th>Цена</th>
                                            <th>Кол-во</th>
                                            <th>Сумма</th>
                                        </tr>
                                    </thead>
                                    <tbody className="cart-table__body">
                                        {this.context.cart.map((product, index) => (
                                            <tr key={index}>
                                                <td className="col-num"></td>
                                                <td className="col-articule">{product.code}</td>
                                                <td className="col-name">{product.name}</td>
                                                <td className="col-price i-rub">{product.price / product.count}</td>
                                                <td className="col-qty">{product.count}</td>
                                                <td className="col-total i-rub">{product.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="cart-footer">
                                <div className="cart-footer__left">
                                </div>
                                <div className="cart-footer__right">
                                    <div className="cart-total">
                                        <p>Всего:</p>
                                        <span className="i-rub">{(+this.context.getTotalSumm()).toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </>
        )
    }

}

export default CheckoutCompleteModal;
