
// React 
import React, { Component } from 'react';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import ModalRegEmail from './ModalRegEmail';
import ModalRegComplete from './ModalRegComplete';

//SCSS
import '../../scss/components/main/footer.scss';
import SocialStuff from "./SocialStuff";

class ProfileHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            authorized: false,
            goto_profile: false,
            showCart: false,
            tabContent: 1,
            showPass: false,
            showTip: false,
            restoreEmail: '',
            reg_name: '',
            reg_surname: '',
            reg_email: '',
            reg_password: '',
            auth_email: '',
            auth_password: '',
            auth_user: '',
            avatar: '',
            error: false,
            error2: false,
            wrong: false,
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.tabs = this.tabs.bind(this);
        this.showPassToggle = this.showPassToggle.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.changeInputReg = this.changeInputReg.bind(this);
        this.changeInputAuth = this.changeInputAuth.bind(this);
        this.register = this.register.bind(this);
        this.restore = this.restore.bind(this);
        this.login = this.login.bind(this);
    }
    changeInputReg(e) {
        this.setState({
            ['reg_' + e.target.name]: e.target.value
        })
    }
    changeInputAuth(e) {
        this.setState({
            ['auth_' + e.target.name]: e.target.value
        })
    }
    changeInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    register(e) {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/register`, {
            name: this.state.reg_name,
            surname: this.state.reg_surname,
            email: this.state.reg_email,
            password: this.state.reg_password
        }).then((response) => {
            localStorage.setItem('galaxy-token',response.data.token.token)
            this.setState({
                goto_profile: true
            })
            this.setState({
                error2: false
            })
            this.handleClose()
        }).catch(err => {
            console.error(err.message)
            this.setState({
                error2: true
            })
        })
    }
    login(e) {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/login`, {
            email: this.state.auth_email,
            password: this.state.auth_password
        }).then((response) => {
            localStorage.setItem('galaxy-token',response.data.token.token)
            this.handleClose()
            this.setState({
                error: false
            })
            window.location.href = '/profile'
        }).catch(err => {
            this.setState({
                error: true
            })
            console.error(err)
        })
    }
    restore(e) {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/forgot-password`, {
            email: this.state.restoreEmail
        }).then((response) => {
            // console.log(response)
            this.tabs(4)
            this.setState({
                wrongMail: false,
                showTip: true
            })
        }).catch(err => {
            this.setState({
                wrongMail: true
            })
            console.error(err)
        })
    }
    showPassToggle() {
        this.setState({
            showPass: !this.state.showPass
        })
    }
    tabs(tabNum) {
        this.setState({
            tabContent: tabNum,
            error: false
        })
        if(tabNum===2) {
            this.setState({
                showTip: false
            })
        }
    }
    handleClose() {
        this.setState({
            showCart: false,
            error: false
        })
    }
    handleShow() {
        if(localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then((response) => {
                // this.setState({
                //     goto_profile: true
                // })
                this.handleClose()
            }).catch(err => {
                console.error(err)
                localStorage.removeItem('galaxy-token')
                this.setState({
                    showCart: true
                })
            })
        } else {
            this.setState({
                showCart: true
            })
        }
    }
    componentDidMount() {
        if(localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then((response) => {
                this.setState({
                    auth_user: response.data.data.info.name + ' ' + response.data.data.info.surname,
                    avatar: response.data.data.info.icon_url
                })
            }).catch(err => {
                localStorage.removeItem('galaxy-token')
                console.error(err)
            })
        }
    }

    render() {
        return (
            <>
                {this.state.goto_profile === true && <Redirect to={'/profile'} />
                }
                <div className="h-profile">
                    {!localStorage.getItem('galaxy-token') &&
                    <Button className="btn-profile" onClick={this.handleShow}>
                        <i className="i-profile"></i>
                    </Button>
                    }
                    {localStorage.getItem('galaxy-token') &&
                    <div className="avatar">
                        <Link to='/profile' title={this.state.auth_user}>
                            {
                                this.state.avatar &&
                                <img id='userpic'
                                src={process.env.REACT_APP_BACKEND + 'users_avatar/' + this.state.avatar + '?rng=' + new Date().getTime()} 
                                alt=""
                                style={{
                                    border: '2px solid #999'
                                }}
                                 />
                            }
                            {
                                !this.state.avatar &&
                                <img id='userpic' src="https://sellik.com/images/default.png" alt="" />
                            }
                        </Link>
                    </div>
                    }
                    <ModalRegComplete />
                    <ModalRegEmail />
                    <Modal
                        show={this.state.showCart}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="modal-login"
                    >
                        <Modal.Header closeButton>
                            <div className="tabs">
                                <div className={`tab ${this.state.tabContent !== 2 ? 'active' : ''}`} onClick={() => {this.tabs(1)}} style={{cursor: 'pointer'}}>
                                    <span>Вход</span>
                                </div>
                                <div className={`tab ${this.state.tabContent === 2 ? 'active' : ''}`} onClick={() => {this.tabs(2)}} style={{cursor: 'pointer'}}>
                                    <span>Регистрация</span>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            {/* Класс "dn" присваивает display: none */}

                            <div className="block-login">

                                {/* Вход */}
                                <Form className={`loginForm ${this.state.tabContent === 1 ? '' : 'dn'}`} onSubmit={this.login}>
                                    <h5>Авторизация по E-mail:</h5>
                                    <Form.Group controlId="logMail">
                                        <div className="form-field">
                                            <Form.Label>E-mail</Form.Label>
                                            <Form.Control type="text" name="email" value={this.state.auth_email} onChange={this.changeInputAuth} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="logPass">
                                        {this.state.showTip &&
                                        <span className="tip">На указанный адрес эл. почты выслан временный пароль, введите его</span>
                                        }
                                        <div className="form-field">
                                            <Form.Label>Пароль</Form.Label>
                                            <Form.Control type="password" name="password" value={this.state.auth_password} onChange={this.changeInputAuth} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <div className="form-field">
                                            <div className="checkbox-filter__item check-remember">
                                                {/*<input className="custom-checkbox filter-check brand-check" type="checkbox" id="logRemember" />*/}
                                                {/*<label htmlFor="logRemember">Запомнить меня</label>*/}
                                            </div>
                                            <div className="forgetPass">
                                                <Button variant="link" onClick={() => {this.tabs(3)}}>Забыли пароль?</Button>
                                            </div>
                                        </div>
                                        {this.state.error && <div className="alert alert-danger">Email и/или пароль введены не верно</div>}
                                    </Form.Group>
                                    <Button type={'submit'} className="btn btn-primary">Войти</Button>
                                    <div className="ordel"><span>(в разработке &darr;)</span></div>
                                    <h5>Авторизация через соц.сети:</h5>
                                    <SocialStuff/>
                                </Form>

                                {/* Восстановить пароль */}
                                <Form className={`loginForm ${this.state.tabContent === 3 ? '' : 'dn'}`} onSubmit={this.restore}>
                                    <h5>Восстановления пароля:</h5>
                                    <Form.Group controlId="logMail">
                                        {this.state.wrongMail &&
                                        <span className="tip">Данный E-Mail отсутствует в базе данных</span>
                                        }
                                        <div className="form-field">
                                            <Form.Label>E-mail</Form.Label>
                                            <Form.Control type="email" required name="restoreEmail" value={this.state.restoreEmail} onChange={this.changeInput} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="logPass">
                                        {/*<div className="form-field">*/}
                                        {/*    <Form.Label>&nbsp;</Form.Label>*/}
                                        {/*    <Form.Control type="text" name="1" />*/}
                                        {/*</div>*/}
                                        <Button variant="primary" type='submit'>Получить временный пароль</Button>
                                    </Form.Group>
                                    <Form.Group>
                                        <div className="form-field">
                                            <div className="checkbox-filter__item check-remember">
                                            </div>
                                            <div className="forgetPass">
                                                <Button type='button'
                                                        variant="link"
                                                        onClick={() => {
                                                            this.tabs(1);
                                                            this.setState({
                                                                showTip: false
                                                            })
                                                        }}>
                                                    Я вспомнил свой пароль
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <div className="ordel"><span>(в разработке &darr;)</span></div>
                                    <h5>Авторизация через соц.сети:</h5>
                                    <SocialStuff/>
                                </Form>

                                {/* Ввод временного пароля */}
                                <Form className={`loginForm ${this.state.tabContent === 4 ? '' : 'dn'}`}>
                                    <h5>Перейдите по ссылке, отправленной вам на указанный почтовый адрес</h5>
                                    {/*<Form.Group controlId="logMail">*/}
                                    {/*    <div className="form-field">*/}
                                    {/*        <Form.Label>E-mail</Form.Label>*/}
                                    {/*        <Form.Control type="text" name="firstName" value={this.state.firstName} onChange={this.changeInput} />*/}
                                    {/*    </div>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group controlId="logPass">
                                        <span className="tip">

                                        </span>
                                        {/*<div className="form-field">*/}
                                        {/*    <Form.Label>Пароль</Form.Label>*/}
                                        {/*    <Form.Control type="password" name="firstName" value={this.state.firstName} onChange={this.changeInput} />*/}
                                        {/*</div>*/}
                                    </Form.Group>
                                    <Form.Group>
                                        <div className="form-field">
                                            <div className="checkbox-filter__item check-remember">
                                                {/*<input className="custom-checkbox filter-check brand-check" type="checkbox" id="logRemember" />*/}
                                                {/*<label htmlFor="logRemember">Запомнить меня</label>*/}
                                            </div>
                                            <div className="forgetPass">
                                                {/*<Button variant="link">Я вспомнил свой пароль</Button>*/}
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {/*<Link to="/ProfileMain" className="btn btn-primary">Войти</Link>*/}
                                    <div className="ordel"><span>(в разработке &darr;)</span></div>
                                    <h5>Авторизация через соц.сети:</h5>
                                    <SocialStuff/>
                                </Form>

                            </div>

                            <div className={`block-registration ${this.state.tabContent === 2 ? '' : 'dn'}`}>
                                {/* Регистрация */}
                                <Form className={`loginForm ${this.state.tabContent === 2 ? '' : 'dn'}`} onSubmit={this.register}>
                                    <h5>Регистрация по E-mail:</h5>
                                    <Form.Group controlId="logName">
                                        <div className="form-field">
                                            <Form.Label>Имя</Form.Label>
                                            <Form.Control type="text" name="name" value={this.state.reg_name} required onChange={this.changeInputReg} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="logName">
                                        <div className="form-field">
                                            <Form.Label>Фамилия</Form.Label>
                                            <Form.Control type="text" name="surname" value={this.state.reg_surname} required onChange={this.changeInputReg} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="logMail">
                                        <div className="form-field">
                                            <Form.Label>E-mail</Form.Label>
                                            <Form.Control type="email" name="email" required value={this.state.reg_email} onChange={this.changeInputReg} />
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="logPass">
                                        <div className="form-field">
                                            <Form.Label>Пароль</Form.Label>
                                            <Form.Control type={this.state.showPass ? 'text' : 'password'}
                                                          name="password"
                                                          className="inputregpass"
                                                          value={this.state.reg_password}
                                                          onChange={this.changeInputReg} />
                                            <div className="btn-toolbar">
                                                {/*<Button variant="link">*/}
                                                {/*    <i className="icon">*/}
                                                {/*        <svg><use xlinkHref="#i_wizard" /></svg>*/}
                                                {/*    </i>*/}
                                                {/*</Button>*/}
                                                <Button variant="link">
                                                    <i className="icon">
                                                        {!this.state.showPass &&
                                                        <svg onClick={this.showPassToggle}><use xlinkHref="#i_eye_hide" /></svg>}
                                                        {this.state.showPass &&
                                                        <svg onClick={this.showPassToggle}><use xlinkHref="#i_eye" /></svg>}
                                                    </i>
                                                </Button>
                                            </div>
                                        </div>
                                        {this.state.error2 && <div className="alert alert-danger">Пользователь с таким E-Mail уже зарегистрирован</div>}
                                    </Form.Group>
                                    {/*<div className="privacy">*/}
                                    {/*    <p>Регистрируясь, Вы соглашаетесь с*/}
                                    {/*        <Button variant="link">договором пользователя</Button>*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                    {/*<Link to="/profile" onClick={this.handleClose} className="btn btn-primary">Зарегистрироваться</Link>*/}
                                    <Button type={"submit"} className="btn btn-primary">Зарегистрироваться</Button>
                                    <div className="ordel"><span>(в разработке &darr;)</span></div>
                                    <h5>Регистрация через соц.сети:</h5>
                                    <SocialStuff/>
                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

export default ProfileHeader;
