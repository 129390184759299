import React, { Component } from 'react';
import {Button} from "react-bootstrap";
import VkLogin from 'react-vkontakte-login';
// import SocialLogin from 'react-social-login'

export default class SocialStuff extends Component {
    constructor(props) {
        super(props);
        this.componentClicked = this.componentClicked.bind(this)
    }
    responseVk(response) {
        console.log(response);
    }
    componentClicked(e) {
        console.log(e);
    }
    render() {
        return (
            <div className="login-soc">
                <div className="login-soc__wrap">
                    <div className="login-soc__item">
                        <VkLogin
                            apiId="7540675"
                            callback={this.responseVk}
                            render={renderProps => (
                                <Button variant="simple" onClick={renderProps.onClick}>
                                    <svg><use xlinkHref="#i_vk" /></svg>
                                </Button>
                            )}
                        />

                    </div>
                    <div className="login-soc__item">
                        <Button variant="simple">
                            <svg><use xlinkHref="#i_google" /></svg>
                        </Button>
                    </div>
                    <div className="login-soc__item">
                        <Button variant="simple">
                            <svg><use xlinkHref="#i_fb" /></svg>
                        </Button>
                    </div>
                    <div className="login-soc__item">
                        <Button variant="simple">
                            <svg><use xlinkHref="#i_ok" /></svg>
                        </Button>
                    </div>
                    <div className="login-soc__item">
                        <Button variant="simple">
                            <svg><use xlinkHref="#i_insta" /></svg>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
