
// React 
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
class BlackListModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCart: true
        }
        this.handleClose = this.handleClose.bind(this)
    }
    handleClose() {
        this.setState({
            showCart: false
        })
    }
    render() {
        
        return (
            <>
                <Modal
                    show={this.state.showCart}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-checkout__complete"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text">                            
                            <h5>Номер телефона в Черном списке!</h5>
                            <p>К сожалению Вы не можете оформить заказ по данному номеру телефона, т.к. он находится в черном списке нашей сети.</p>
                            <span className="tip">За разъяснением ситуации Вы можете обратиться к администратору сайта
email: <a href="mailto:admin@galaktika.me">admin@galaktika.me</a>.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="simple" variant="link" onClick={this.handleClose}>Назад к заказу</Button>
                        <Link to="/" className="btn btn-primary" onClick={this.handleClose}>Вернуться на главную</Link>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

export default BlackListModal;