
// React 
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CartMain from '../cart/cartMain';



function CheckoutCartModal() {

    const [showCart, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="link" onClick={handleShow}>Редактировать заказ</Button>
            <Modal
                show={showCart}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-cart"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Корзина</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CartMain />
                    <Button variant="link" onClick={handleClose} className="cart-close">Назад</Button>
                    <Link to="/checkout" className="btn btn-primary to-checkout" onClick={handleClose}>Оформить заказ</Link>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CheckoutCartModal;