import React, { Component } from 'react';

class Badges extends Component {
    
    render(){
        return(
            <>
                <div className="products-labels">
                    {this.props.items.bages.map( (badge, index) => {
                        if(badge.bage.badge_type === 1) {
                            return (
                                <div className="label" key={index}>
                                    <img src={`${process.env.REACT_APP_BACKEND}/uploads/badges/${badge.bage.background_url}`} alt=""/>
                                </div>
                            )
                        }
                        if(badge.bage.badge_type === 2) {
                            return (
                                <div className="label" key={index}>
                                    <span style={{
                                        backgroundColor: `${badge.bage.background_RGBA}`,
                                        color: `${badge.bage.text_color}`
                                    }}>
                                        {badge.bage.text}
                                    </span>
                                </div>
                            )
                        }
                        if(badge.bage.badge_type === 3) {
                            return (
                                <div className="label" key={index}>
                                    <span style={{
                                        backgroundColor: `${badge.bage.background_RGBA || '#b35b9d'}`,
                                        color: `${badge.bage.text_color || '#FFF'}`
                                    }}>
                                        {`${badge.bage.text ? badge.bage.text : 'Скидка'}`}{`${this.props.items.price.discount ? this.props.items.price.discount+'%' : ''}`}
                                    </span>
                                </div>
                            )
                        }
                        if(badge.bage.badge_type === 4 && this.props.items.price.discountPrice) {
                            return (
                                <div className="label" key={index}>
                                    <span style={{
                                        backgroundColor: `${badge.bage.background_RGBA || '#f85b41'}`,
                                        color: `${badge.bage.text_color || '#FFF'}`
                                    }}>
                                        {`${badge.bage.text ? badge.bage.text : 'Скидка'}`} 
                                        &nbsp;
                                        {
                                        (this.props.items.price.discountPrice*1 - this.props.items.price.price*1)*10%10 !== 0 ?
                                        (this.props.items.price.discountPrice*1 - this.props.items.price.price).toFixed(1) :
                                        (this.props.items.price.discountPrice*1 - this.props.items.price.price*1).toLocaleString('ru')
                                        } руб.
                                    </span>
                                </div>
                            )
                        }
                        return false
                    })}
                </div>
            </>
        )
    }

}

export default Badges;