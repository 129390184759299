// React
import React, { Component } from 'react';
import { Link } from "react-router-dom";

//SCSS
import '../../../scss/components/main/mainCatalog.scss';
import '../../../scss/components/pages/promo/index.scss';
import '../../img/call-byttech.svg';

// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png"
// import axios from "axios";
import { Button, Form } from 'react-bootstrap';

class MenuCatalog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show_search: 0,
      category_main: '',
      items: [],
      filter: '',
    }
    this.changeFilter = this.changeFilter.bind(this)
    this.search = this.search.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this);

  }

  changeFilter = (e) => {
    this.setState({ filter: e.target.value });
  };

  componentDidMount() {
    this.setState({
      items: this.props.sidebar.categories,
      show_search: this.props.sidebar.show_search,
      category_main: this.props.sidebar.category_main,
      stock: this.props.stock,
      stock_title: this.props.stock_title,
    }, () => {
      // console.log(this.state.items.length)
    })
  }

  search(e) {
    if (e) e.preventDefault()
    let str = this.state.filter
    str = str.replace(/[^a-zA-Zа-яА-ЯёЁйЙьЬъЪ0-9_\- ]/g, "")
    str = str.replace(/\s+/g, '%20')
    window.location.href = `/stock-search/1?q=${str}&s=${this.state.stock}`
  }
  toggleFilter(e) {
    let parent;
    if (e.target.type === 'button') parent = e.target.parentNode
    else parent = e.target.parentNode
    parent.classList.toggle('open')
    const text = e.target.innerText === 'Показать больше' ? 'Скрыть' : 'Показать больше'
    e.target.innerText = text

  }

  render() {
    // console.log(this.props.items)
    // const regexp = new RegExp(this.state.filter, "ig")
    return (
      <>

        {this.state.show_search && (<Form className='search' onSubmit={this.search}>
          <Form.Control type="text" className="search_categories" id="search_categories" autoComplete="off"
            placeholder="Поиск акционных товаров" onChange={this.changeFilter} value={this.state.filter} />
        </Form>)}
        <ul className="menu-categories menu-promo ml-0">
          {this.state.items.length > 0 && this.state.items.map((cat, index) => (
            <li key={index}
              className={`menu-categories__item`}
            >
              {/*<Link*/}
              {/*  to={`/promo/${(cat.subCategories && cat.subCategories.length > 0) ? 'category' : 'catalog'}/${cat.name_slug}`}*/}
              {/*  className={`${(this.state.filter && !regexp.test(cat.name) ? 'd-none' : '')}`}*/}
              {/*>*/}
              <Link
                to={`/promo/${this.state.stock}/${cat.name_slug}`}
                className={`d-flex align-items-center`}
              >
                <img
                  height="24"
                  src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.icon_url}`}
                  alt='' />
                <span className="menu-categories__name" style={{ fontSize: '1.1rem', fontWeight: '500' }}>{cat.name} {cat.count && (<>({cat.count})</>)}</span>
              </Link>
              {cat.subCategories && (
                <>
                  <ul className={`list menu-promo__sub pl-4`}>
                    {cat.subCategories.map((subcat, index) => (
                      <React.Fragment key={index}>
                        <li key={index}>
                          <span>
                            {/*<Link*/}
                            {/*  className={`menu-promo__sub-link ${(this.state.filter && !regexp.test(subcat.name) ? 'd-none' : '')}`}*/}
                            {/*  to={`/promo/${subcat.type === 'have_cat' ? 'category' : 'catalog'}/${subcat.name_slug}`}>*/}
                            <Link
                              className={`menu-promo__sub-link`}
                              to={`/promo/${subcat.type === 'have_cat' ? this.state.stock : 'catalog/' + this.state.stock}/${subcat.name_slug}`}>
                              {subcat.name} {subcat.count && (<>({subcat.count})</>)}
                            </Link>
                          </span>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                  {(cat.subCategories.length > 4) ? (
                    <Button variant='link' className="btn_more mb-2" onClick={this.toggleFilter}>Показать больше</Button>
                  ) : (
                    <div className='flex mb-2'></div>
                  )}
                </>
              )}

            </li>
          ))}
        </ul>
      </>
    )
  }
}

export default MenuCatalog;
