// React 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import logo from "../../img/logo.svg";
import logoM from "../../img/logo-sm.svg";

class HeaderLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHome: false
        }
        this.logoNevroz = this.logoNevroz.bind(this)
    }
    logoNevroz() {
        if(window.location.pathname === '/' || window.location.pathname === '') {
            // document.getElementById('header-logo-link').style.display = 'none'
            // document.getElementById('header-logo-span').style.display = 'inline'
            this.setState({
                isHome: true
            })
        } else {
            // document.getElementById('header-logo-link').style.display = 'inline'
            // document.getElementById('header-logo-span').style.display = 'none'
            this.setState({
                isHome: false
            })
        }
    }
    componentDidMount() {
        setInterval(this.logoNevroz, 200)
    }

    render() {
        return (
            <Col className="header-logo" style={{minWidth:'auto'}}>
                {!this.state.isHome &&
                    <Link to={'/'} id='header-logo-link'>
                        <img src={logo} alt="Логотип" />
                        <img src={logoM} className="logo-m" alt="Логотип" />
                    </Link>
                }
                {this.state.isHome &&
                <span id='header-logo-span'>
                    <img src={logo} alt="Логотип" />
                    <img src={logoM} className="logo-m" alt="Логотип" />
                </span>
                }
            </Col>
        )
    }
}

export default HeaderLogo;
