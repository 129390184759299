// React 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SVGsprite from './svgSprite';

//SCSS
import '../../scss/components/main/footer.scss';

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            subscribeStatus: '',
            subscribed: localStorage.getItem('galaxy-subsribed')
        }
        this.changeForm = this.changeForm.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.emailField = React.createRef();
    }

    changeForm(e) {
        this.setState({
            email: this.emailField.current.value
        })        
    }

    submitForm(e) {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE}/addsubscriber`,{
            'email': this.state.email 
        })
        .then( response => {
            if(response.status === 200) {
                localStorage.setItem('galaxy-subsribed', true)
                this.setState({
                    subscribeStatus: 'Вы успешно подписались!',
                    subscribed: localStorage.getItem('galaxy-subsribed')
                })
            } else {
                this.setState({
                    subscribeStatus: 'Ошибка! Попробуйте ещё раз'
                })
            }
        })
        .catch( err => {
            this.setState({
                subscribeStatus: 'Ошибка! Попробуйте ещё раз'
            })
        })
    }

    render() {
        return (
            <>
                <footer className='footer-desktop mt-5' >
                    <div className="footer-desktop__top">
                        <Container>
                            <div className="flex">
                                <div className="subscribe">
                                    <b>Узнавайте о скидках и новых акциях</b>
                                    <b>{this.state.subscribeStatus}</b>
                                    <Form className="subscribe__form" onSubmit={this.submitForm}>
                                        <Form.Control type="email" className="form__input" required placeholder="Ваш E-mail" onChange={this.changeForm} value={this.state.value} ref={this.emailField} />
                                        <Button variant="primary" type="submit" disabled={this.state.subscribed}>
                                            {this.state.subscribed ? 'Вы подписаны' : 'Подписаться'}
                                        </Button>
                                    </Form>
                                    <div className="social">
                                        <p>Мы в социальных сетях:</p>
                                        <div className="social-list">
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.instagram.com/galaktika_official_/" target="_blank">
                                                    <img src="/img/i-f-insta.svg" alt="Instagram" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.youtube.com/channel/UCYe5DCU2M-joE39Zg_QzKog" target="_blank">
                                                    <img src="/img/i-f-youtube.svg" alt="YouTube" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://vk.com/galaktika_official" target="_blank">
                                                    <img src="/img/i-f-vk.svg" alt="VKontakte" />
                                                </Nav.Link>
                                            </div>
                                            <div className="social-list__item">
                                                <Nav.Link href="https://www.facebook.com/galaktika.official/" target="_blank">
                                                    <img src="/img/i-f-fb.svg" alt="fb" />
                                                </Nav.Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Container>
                    </div>
                    <div className="footer-desktop__bottom">

                        <Container className="flex">
                            <div className="footer-main">
                                <Link to="/">
                                    <img src="/img/logo-f.svg" alt="Галактика" />
                                </Link>
                                <p className="copyright">© Сеть торговых центров «Галактика»  2015–{(new Date().getFullYear())}. Все права защищены.</p>
                            </div>
                            {/* <div className="footer-menu">
                                <div className="footer-menu__col">
                                    <ul className="footer-menu__list">
                                        <li><Nav.Link href="">О компании</Nav.Link></li>
                                        <li><Nav.Link href="">Услуги</Nav.Link></li>
                                        <li><Nav.Link href="">Блог</Nav.Link></li>
                                        <li><Nav.Link href="">Подарочные карточки</Nav.Link></li>
                                        <li><Nav.Link href="">Вакансии</Nav.Link></li>
                                    </ul>
                                </div>
                                <div className="footer-menu__col">
                                    <ul className="footer-menu__list">
                                        <li><Nav.Link href="">Оплата</Nav.Link></li>
                                        <li><Nav.Link href="">Доставка</Nav.Link></li>
                                        <li><Nav.Link href="">Доставка и подъем</Nav.Link></li>
                                        <li><Nav.Link href="">Гарантия и возврат</Nav.Link></li>
                                        <li><Nav.Link href="">Возврат товара</Nav.Link></li>
                                    </ul>
                                </div>
                                <div className="footer-menu__col">
                                    <ul className="footer-menu__list">
                                        <li><Nav.Link href="">Оптовым покупателям</Nav.Link></li>
                                        <li><Nav.Link href="">Поставщикам</Nav.Link></li>
                                        <li><Nav.Link href="">Жалобы и предложения</Nav.Link></li>
                                        <li><Nav.Link href="">График работы</Nav.Link></li>
                                        <li><Link to="/contact">Контакты и адреса</Link></li>
                                    </ul>
                                </div>
                            </div> */}
                        </Container>

                    </div>
                </footer>
                <div id='overlay_main'>{this.props.handleClick}</div>
                <SVGsprite />
            </>
        )
    }
}

export default Footer;
