
// React
import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";



class CheckoutCartModal extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            showM: false,
            showN: false,
            showHuy: false,
            newWL: '',
            lists: [],
            fuckingUselessPieceOfShit: [],
            selectedList: '',
            inAnyList: false,
            inLists: []
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleNewShow = this.handleNewShow.bind(this)
        this.handleNewClose = this.handleNewClose.bind(this)
        this.newWishList = this.newWishList.bind(this)
        this.addWishList = this.addWishList.bind(this)
        this.addProductWishList = this.addProductWishList.bind(this)
        this.changeList = this.changeList.bind(this)
        this.addToWaitList = this.addToWaitList.bind(this)
    }
    addToWaitList(id) {
        this.setState({showHuy:true})
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list/add-product-to-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            productId: id
        })
            .then(res => {
                axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list`, {
                    jwt: localStorage.getItem('galaxy-token')
                }).then(response => {
                    if(response.data.data[0])
                        this.setState({
                            fuckingUselessPieceOfShit: response.data.data[0].products
                        })
                }).catch(err => {
                    console.error(err)
                })
            })
            .catch(err => {
                // console.error(err)
            })
    }
    changeList(e) {
        this.setState({
            selectedList: e.target.value
        })
    }
    handleClose() {
        this.setState({showM:false})
        this.setState({showHuy:false})
    }
    handleShow() {
        this.setState({showM:true})
        this.fetchWL()
    }
    handleNewClose(){
        this.setState({showN:false})
    }
    handleNewShow(){
        this.setState({showN:true})
    }
    newWishList(e) {
        this.setState({
            newWL: e.target.value
        })
    }
    addWishList() {
        this.setState({showN:false})
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/add-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            name: this.state.newWL || 'Список ' + (this.state.lists.length+1),
            default: this.state.lists.length===0 ? 1 : 0
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data,
                    newWL: ''
                })
            })
            // window.location.reload()
        })
    }
    addProductWishList() {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/add-product-to-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            listId: this.state.selectedList,
            productId: this.props.productId
        }).then(response => {
            this.setState({
                showM: false,
                inAnyList: true
            })
            if(this.state.inLists.indexOf(this.state.selectedList) === -1) {
                let tmp = []
                tmp.push(this.state.selectedList)
                // console.log(tmp);
                this.setState({
                    inLists: tmp
                })
            }
        }).catch(err=>{
            console.error(err)
        })
    }
    componentDidMount() {
        if(localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data
                })
            })
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                if(response.data.data[0])
                    this.setState({
                        fuckingUselessPieceOfShit: response.data.data[0].products
                    }, function () {
                        // console.log(this.state.products)
                    })
            }).catch(err => {
                console.error(err)
            })
        }
    }

    fetchWL() {
        if(localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data
                })
            })
        }
    }

    productIsInThisParticularList(listId, productId) {
        let k = 0
        this.state.lists.map((list,index) => {
            let arr = []
            if(list.products && list.products.length>0) {
                arr = list.products.filter(product => {
                    return list.id === listId && product.id === productId
                })
            }
            if (arr.length>0) return k++
            else return null
        })
        return k>0
    }
    productIsInAnyList(productId) {
        let k = 0
        this.state.lists.map((list,index) => {
            let arr = []
            if(list.products && list.products.length>0) {
                arr = list.products.filter(product => {
                    return product.id === productId
                })
            }
            if (arr.length>0) return k++
            else return null
        })
        return k>0
    }

    render() {

        return process.env.REACT_APP_TEST === `1` ? (
            <>
                {!this.props.noWL &&
                <Button variant="simple"
                className={`btn-wishlist ${this.state.inAnyList ? 'active' : ''} ${this.productIsInAnyList(this.props.productId) ? 'active' : ''}`}
                onClick={this.handleShow} title="Добавить в список желаемого">
                    {(!this.state.inAnyList && !this.productIsInAnyList(this.props.productId)) &&
                        <svg className="w20 i-wishlist"><use xlinkHref="#i_like" /></svg>
                    }
                    {(this.state.inAnyList || this.productIsInAnyList(this.props.productId)) &&
                        <svg className="w20 i-wishlist"><use xlinkHref="#i_like2" /></svg>
                    }
                    <span>В избранное</span>
                </Button>

                }
                {this.props.productCount===0 &&
                <Button variant="simple"
                className="waiting"
                onClick={()=>{this.addToWaitList(this.props.productId)}}
                title={this.state.fuckingUselessPieceOfShit.filter((f,i)=>{
                    return f.id === this.props.productId
                }).length > 0 ? "Уже в списке ожидания" : "Добавить в список ожидания"}
                disabled={this.state.fuckingUselessPieceOfShit.filter((f,i)=>{
                    return f.id === this.props.productId
                }).length > 0}
                >
                    <svg className="w20"><use xlinkHref="#i_time" /></svg>
                </Button>
                }
                {!localStorage.getItem('galaxy-token') &&
                <Modal
                    show={this.state.showM}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавить в список</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="center-block">
                            <p>Чтобы сохранить товар, Вам необходимо  <a href='!' onClick={(e) => {
                                e.preventDefault()
                                this.handleClose()
                                document.querySelector('.h-profile>button').click()
                            }}>авторизироваться</a>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={this.handleClose} className="btn-secondary">Закрыть</Button>
                        {/*<Link to="/checkout" className="btn btn-primary" onClick={handleClose}>Авторизироваться</Link>*/}
                    </Modal.Footer>
                </Modal>
                }

                {!localStorage.getItem('galaxy-token') &&
                <Modal
                    show={this.state.showHuy}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавить в список ожидания</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="center-block">
                            <p>Чтобы сохранить товар, Вам необходимо  <a href='!' onClick={(e) => {
                                e.preventDefault()
                                this.handleClose()
                                document.querySelector('.h-profile>button').click()
                            }}>авторизироваться</a>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={this.handleClose} className="btn-secondary">Закрыть</Button>
                        {/*<Link to="/checkout" className="btn btn-primary" onClick={handleClose}>Авторизироваться</Link>*/}
                    </Modal.Footer>
                </Modal>
                }
                {localStorage.getItem('galaxy-token') &&
                <Modal
                    show={this.state.showM}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавить в список</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mwlist">
                            <div className="mwlist__toolbar">
                                <Button variant="link" className="wlnew__btn" onClick={this.handleNewShow}>
                                    <span>Создать список</span>
                                </Button>
                                {/* Клик по кнопке добавляет нижнему блоку класс show для отображения */}
                                <div className={`wlnew ${this.state.showN ? 'show' : ''}`}>
                                    <Form.Group>
                                        <div className="form-field">
                                            <div className="input-field">
                                                <Form.Control type="text" placeholder="Введите название" onChange={this.newWishList}/>
                                            </div>
                                            <Button variant="simple" onClick={this.addWishList}>
                                                OK
                                            </Button>
                                            <Button variant="simple" className="btn_cancel" onClick={this.handleNewClose}>
                                                <svg className="w20">
                                                    <use xlinkHref="#i_plus"/>
                                                </svg>
                                            </Button>
                                        </div>
                                    </Form.Group>
                                </div>
                                <Link to="/profile/wishlist" className="">Мои списки</Link>
                            </div>
                            <div className="mwlist__inner">
                                <div className="checkbox-wrap d-flex flex-column">
                                    {
                                        this.state.lists.length > 0 &&
                                            this.state.lists.map((list, i) => {
                                                return(
                                                    <div className="checkbox-wrap__item" key={i}>
                                                        <input className="custom-checkbox"
                                                               type="radio"
                                                               name="wl"
                                                               id={`wl-${i}`}
                                                               value={list.id}
                                                               onChange={this.changeList}
                                                               disabled={this.productIsInThisParticularList(list.id, this.props.productId) || (this.state.inLists.length>0 && this.state.inLists.filter(il => il===list.id).length>0)}
                                                        />
                                                        <label htmlFor={`wl-${i}`}>
                                                            {list.name}
                                                            &nbsp;
                                                            {
                                                            (this.productIsInThisParticularList(list.id, this.props.productId) || (this.state.inLists.length>0 && this.state.inLists.filter(il => il===list.id).length>0))
                                                                 &&
                                                                <em>(Товар уже в этом списке)</em>
                                                            }
                                                        </label>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={this.handleClose} className="btn-secondary">Назад</Button>
                        <Button className="btn btn-primary" onClick={this.addProductWishList} disabled={this.state.selectedList === ''}>Добавить</Button>
                    </Modal.Footer>
                </Modal>
                }

            </>
        ) : (
            <></>
        )
    }
}

export default CheckoutCartModal;
