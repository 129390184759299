import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  FullscreenControl,
  GeolocationControl,
  RouteButton,
  TrafficControl,
  TypeSelector,
} from "react-yandex-maps";

// function ContactGallery() {
//     const [currentImage, setCurrentImage] = useState(0);
//     const [viewerIsOpen, setViewerIsOpen] = useState(false);

//     const openLightbox = useCallback((event, { photo, index }) => {
//         setCurrentImage(index);
//         setViewerIsOpen(true);
//     }, []);

//     const closeLightbox = () => {
//         setCurrentImage(0);
//         setViewerIsOpen(false);
//     };
//     const photos = [
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/01.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/02.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/03.jpg",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/04.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/05.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         },
//         {
//             src: "img/temp/galaktika/06.jfif",
//             width: 1,
//             height: 1,
//             title: "Картинка 1"
//         }
//     ];
//     return (
//         <div>
//             <Gallery photos={photos} onClick={openLightbox} />
//             <ModalGateway>
//                 {viewerIsOpen ? (
//                     <Modal onClose={closeLightbox}>
//                         <Carousel
//                             currentIndex={currentImage}
//                             views={photos.map(x => ({
//                                 ...x,
//                                 srcset: x.srcSet,
//                                 caption: x.title
//                             }))}
//                         />
//                     </Modal>
//                 ) : null}
//             </ModalGateway>
//         </div>
//     );
// }

class ContactMariupol extends Component {
  render() {
    const mapData = {
      center: [47.118004, 37.503351],
      zoom: 15,
      controls: [],
    };

    const coordinates = [[47.118004, 37.503351]];
    return (
      <div className="contact__wrap">
        <div className="contact__title">
          <h1>Торговый центр Мариуполь</h1>
          <p>Запорожское шоссе, 4</p>
        </div>
        <div className="contact__main">
          <Row>
            <Col sm={12} md={5} xl={4}>
              <div className="contact__worktime">
                <div className="worktime-header">
                  <h3>
                    <i className="i-calendar"></i>График работы:
                  </h3>
                </div>
                <div className="worktime-content">
                  <div className="weekdays flex">
                    <div className="left-side">
                      <p>ПН-ЧТ</p>
                    </div>
                    <div className="right-side">
                      <p>08:00 - 21:00</p>
                    </div>
                  </div>
                  <div className="weekends flex">
                    <div className="left-side">
                      <p>ПТ-ВС</p>
                    </div>
                    <div className="right-side">
                      <p>08:00 - 21:00</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact-place">
                <div className="contact-place__item">
                  <i className="i-map orange"></i>
                  <div className="place-info">
                    <p>Адрес ТЦ</p>
                    <span>Запорожское шоссе, 4, Мариуполь</span>
                  </div>
                </div>
                <div className="contact-place__item">
                  <i className="i-square orange"></i>
                  <div className="place-info">
                    <p>Площадь ТЦ</p>
                    <span>6000 кв.м.</span>
                  </div>
                </div>
                <div className="contact-place__item">
                  <i className="i-opening orange"></i>
                  <div className="place-info">
                    <p>Дата открытия ТЦ</p>
                    <span>29.07.2023</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={7} xl={8}>
              <div className="contact__map">
                <YMaps>
                  <Map defaultState={mapData} width="100%" height="390px">
                    {coordinates.map((coordinate) => (
                      <Placemark geometry={coordinate} />
                    ))}
                    <ZoomControl options={{ float: "right" }} />
                    <FullscreenControl options={{ float: "right" }} />
                    <GeolocationControl options={{ float: "left" }} />
                    <RouteButton options={{ float: "left" }} />
                    <TrafficControl options={{ float: "right" }} />
                    <TypeSelector options={{ float: "right" }} />
                  </Map>
                </YMaps>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contact__numbers">
          <Row>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Центральное инфо</h5>
                <ul className="numbers__wrap">
					<li className="numbers__item">
						<a
						  className="numbers__link i-phone"
						  href="tel:+78563349850"
						>
						  <span className="label">Городской:</span>
						  <span>+7 (856) 334-98-50</span>
						</a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79497075676"
                    >
                      <span className="label viber">Мобильный:</span>
                      <span> +7 (949) 707-56-76</span>
                    </a>
                  </li>
                  
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mail"
                      href="mailto:mr1.info@galaktika.me"
                    >
                      <span className="label">E-mail:</span>
                      <span>mr1.info@galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Отдел безналичного расчета</h5>
                <ul className="numbers__wrap">
					<li className="numbers__item">
						<a
						  className="numbers__link i-phone"
						  href="tel:+78563349853"
						>
						  <span className="label">Городской:</span>
						  <span>+7 (856) 334-98-53</span>
						</a>
                  </li>
				  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79496481431"
                    >
                      <span className="label viber">Мобильный:</span>
                      <span> +7 (949) 648-14-31</span>
                    </a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="mailto:mr1.client@galaktika.me"
                    >
                      <span className="label">E-Mail:</span>
                      <span>mr1.client@galaktika.me</span>
                    </a>
                  </li>
					
                </ul>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="numbers">
                <h5>Доставка</h5>
                <ul className="numbers__wrap">
                  <li className="numbers__item">
						<a
						  className="numbers__link i-phone"
						  href="tel:+78563349852"
						>
						  <span className="label">Городской:</span>
						  <span>+7 (856) 334-98-52</span>
						</a>
                  </li>
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79496218328"
                    >
                      <span className="label">Мобильный:</span>
                      <span> +7 (949) 621-83-28</span>
                    </a>
                  </li>
			            <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="mailto:mr1.dst@galaktika.me"
                    >
                      <span className="label">E-Mail:</span>
                      <span>mr1.dst@galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
			<Col xs={12} md={4}>
              <div className="numbers">
                <h5>Отдел кадров</h5>
                <ul className="numbers__wrap">
                  
                  <li className="numbers__item">
                    <a
                      className="numbers__link i-mobile"
                      href="tel:+79497075674"
                    >
                      <span className="label">Мобильный:</span>
                      <span> +7 (949) 707-56-74</span>
                    </a>
                  </li>
			            <li className="numbers__item">
                    <a
                      className="numbers__link i-phone"
                      href="mailto:mr1.hr@galaktika.me"
                    >
                      <span className="label">E-Mail:</span>
                      <span>mr1.hr@galaktika.me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="contact__gallery">
                    <h4>Фотогалерея</h4>
                    <ContactGallery />
                </div> */}
      </div>
    );
  }
}
export default ContactMariupol;
