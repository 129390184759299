// React
import React, { Component } from 'react';
import { Link } from "react-router-dom";

//SCSS
import '../../../scss/components/main/mainCatalog.scss';
import '../../../scss/components/pages/promo/index.scss';
import '../../img/call-byttech.svg';

// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png"
// import axios from "axios";
import { Form } from 'react-bootstrap';


class MenuCatalog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show_search: 0,
      category_main: '',
      category_up: [],
      category_down: [],
      category: null,
      filter: '',
      stock_title: this.props.stock_title,
    }
    this.changeFilter = this.changeFilter.bind(this)
    this.search = this.search.bind(this)

  }

  changeFilter = (e) => {
    this.setState({ filter: e.target.value });
  };

  componentDidMount() {
    this.setState({
      show_search: this.props.sidebar.show_search,
      category_main: this.props.sidebar.category_main,
      category_up: this.props.sidebar.category_up,
      category_down: this.props.sidebar.category_down,
      stock: this.props.stock,
      stock_title: this.props.stock_title,
    }, () => {
      // console.log(this.state.category_down)
    })
  }

  search(e) {
    if (e) e.preventDefault()
    let str = this.state.filter
    str = str.replace(/[^a-zA-Zа-яА-ЯёЁйЙьЬъЪ0-9_\- ]/g, "")
    str = str.replace(/\s+/g, '%20')
    window.location.href = `/stock-search/1?q=${str}&s=${this.state.stock}`
  }

  render() {
    // console.log(this.props.items)
    // const regexp = new RegExp(this.state.filter, "ig")
    return (
      <>
        {this.state.show_search && (<Form className='search' onSubmit={this.search}>
          <Form.Control type="text" className="search_categories" id="search_categories" autoComplete="off"
            placeholder="Поиск акционных товаров" onChange={this.changeFilter} value={this.state.filter} />
        </Form>)}
        {this.props.stock !== 'all-stocks' && (<Link
          className={`promo-crumbs_link f my-3`}
          to={`/promo/${this.state.stock}`}
        >
          <i className="i-arrow rot_r" />
          <span className="menu-categories__name" style={{ fontSize: '1.1rem', fontWeight: '500' }}>
            {this.state.stock_title}
          </span>
        </Link>)}
        <div className="promo-crumbs">
          {this.props.sidebar.category_up && this.props.sidebar.category_up.length > 0 &&
            this.props.sidebar.category_up.map((c, i) => (
              <Link
                className={`promo-crumbs_link f`}
                key={i}
                to={`/promo/${this.props.stock}/${c.name_slug}`}
              >
                <i className="i-arrow rot_r" />
                <span className="menu-categories__name">
                  {c.name} {c.count && (<span>({c.count})</span>)}
                </span>
              </Link>
            ))
          }

          <p className="promo-crumbs_last">
            {this.props.sidebar.category.name}
          </p>

          {this.props.sidebar.category_down && this.props.sidebar.category_down.length > 0 &&
            this.props.sidebar.category_down.map((c, i) => (
              <Link
                className={`promo-crumbs_link d-block`}
                key={i}
                to={`/promo/${c.type === 'have_cat' ? this.state.stock : 'catalog/' + this.state.stock}/${c.name_slug}`}
              >
                <i className="i-arrow rot_r" />
                <span className="menu-categories__name">
                  {c.name} {c.count && (<span>({c.count})</span>)}
                </span>
              </Link>
            ))
          }
        </div>
      </>
    )
  }
}

export default MenuCatalog;
