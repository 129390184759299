
// React 
import React, { Component } from 'react';
import CartHeader from '../cart/cartHeader';
import ProfileHeader from '../profile/ProfileHeader';

class HeaderProfileMenu extends Component {
    render() {
        return (
            <div className="profile-menu">
                <div className="profile-menu__wrap">
                    <div className="profile-menu__item">
                        <CartHeader />
                    </div>
                    <div className="profile-menu__item">
                        <ProfileHeader />
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderProfileMenu;