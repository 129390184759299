// React 
import React, { Component } from 'react';
import ProfileNav from '../SideNav';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WishList from "../../../components/main/ModalWishlist";
//SCSS
import "../../../scss/components/pages/profile/profile.scss"
import axios from "axios";
import Badges from "../../main/Badges";
import CartCtx from '../../CartCtx'

class PagePWaitingList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: false
        }
    }
    componentDidMount() {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/viewed-list`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {

            this.setState({
                products: response.data.data[0].products.slice(0,40)
            }, function () {
                // console.log(this.state.products)
            })
        }).catch(err => {
            console.error(err)
        })
    }
    deleteFromList(pid, i) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/viewed-list`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {

            this.setState({
                products: response.data.data[0].products.slice(0,20)
            }, function () {
                // console.log(this.state.products)
            })
        }).catch(err => {
            console.error(err)
        })
    }
    static contextType = CartCtx;
    render() {
        let Products = []
        if(this.state.products.length > 0)
            Products = this.state.products
        return (
            <main className='page-profile'>
                <Container>
                    <ProfileNav />
                    <section className="prof-main">
                        <div className="breadcrumbs">
                            <div className="breadcrumbs__item">
                                <h1>Личный кабинет</h1>
                            </div>
                            <div className="breadcrumbs__item">
                                <h2>Просмотренные товары</h2>
                            </div>
                        </div>
                        <div className="prof-content pp-waitinglist">
                            <div className="prof-content__body">
                                <div className="catalog-list">
                                    <div className="catalog-list__wrap">
                                        {Products.map((product, index) => (
                                            <div className="catalog-list__item" key={index}>
                                                <div className="products__wrap">

                                                    <div className="btn-toolbar">
                                                        {/*<Button variant="simple" className="btn-delete" onClick={() => {this.deleteFromList(product.id, index)}}>*/}
                                                        {/*    <svg className="w20"><use xlinkHref="#i_plus" /></svg>*/}
                                                        {/*</Button>*/}
                                                        <WishList productId={product.id} />
                                                    </div>
                                                    <div className="products-image">
                                                        <Link className="products-image__link"
                                                              to={`/product/${product.name_slug}`}>
                                                            <img
                                                                src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                                                                alt={("Изображение товара " + product.name)}/>
                                                        </Link>
                                                    </div>

                                                    <Link className="products-title"
                                                          to={`/product/${product.name_slug}`} title={product.name}>
                                                        {product.name}
                                                    </Link>

                                                    <div
                                                        className={("products-price " + product.sale + " " + product.stockStatus)}>
                                                        <div className="producst-price__wrap">
                                                            <div className="products-price__old">
                                                                {product.priceBadgeStock.price.discountPrice &&
                                                                <span
                                                                    className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                                                                }
                                                            </div>
                                                            <div className="products-price__main">
                                                                <span className="price i-rub">
                                                                    {product.priceBadgeStock.price.discountPrice &&
                                                                    product.priceBadgeStock.price.discountPrice.toLocaleString('ru')
                                                                    }
                                                                    {!product.priceBadgeStock.price.discountPrice &&
                                                                    product.price.toLocaleString('ru')
                                                                    }

                                                                </span>
                                                            </div>
                                                        </div>
                                                            <div className="products-cart-sm">
                                                                {product.count > 0 &&
                                                                    <>
                                                                        {!this.context.isInCart(product.id) &&
                                                                            <CartCtx.Consumer>
                                                                                {({ cart, addProduct }) => (
                                                                                    <Button variant="simple" className="add-to-cart" onClick={() => addProduct(product.id)}><i className="i-cart"></i></Button>
                                                                                )}
                                                                            </CartCtx.Consumer>
                                                                        }
                                                                        {this.context.isInCart(product.id) &&
                                                                            <CartCtx.Consumer>
                                                                                {({ cart, addProduct }) => (
                                                                                    <Button variant="simple" className="add-to-cart added" disabled><i className="i-cart"></i></Button>
                                                                                )}
                                                                            </CartCtx.Consumer>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                    </div>

                                                    <div className="products-stock">
                                                        {product.count > 0 &&
                                                        <p className='stock-in'>
                                                            В наличии
                                                        </p>
                                                        }
                                                        {product.count < 1 &&
                                                        <p className='stock-out'>
                                                            Нет в наличии
                                                        </p>
                                                        }
                                                    </div>
                                                    {
                                                        product.priceBadgeStock.bages.length > 0 &&
                                                        <Badges items={product.priceBadgeStock}/>
                                                    }


                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </main >
        )
    }
}

export default PagePWaitingList;
