// React 
import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";


class HeaderSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            str: '',
            disableSubmit: true,
            redirect: null,
        }
        this.changeForm = this.changeForm.bind(this)
        this.submitForm = this.submitForm.bind(this)
        this.searchField = React.createRef();
    }
    changeForm(e) {
        this.setState({
            search: this.searchField.current.value,
            disableSubmit: this.searchField.current.value.length < 3
        })
    }
    submitForm(e) {
        if(e) e.preventDefault()
        let str = this.state.search
        str = str.replace(/[^a-zA-Zа-яА-ЯёЁйЙьЬъЪ0-9_\- ]/g, "")
        str = str.replace(/\s+/g, '%20')
        window.location.href='/search/1?q=' + str
        /*this.setState({
            redirect: '/search/1?q=' + str,
            str: str
        })
        setTimeout(() => {
            this.setState({
                redirect: null
            })
        },100)*/
    }
    render() {
        return (
            <div className="header-search">
                <Form className='search' onSubmit={this.submitForm}>
                    <Form.Label htmlFor="search_input"></Form.Label>
                    <Form.Control onChange={this.changeForm} ref={this.searchField} type="text" className="search__input" id="search_input" placeholder="Поиск товаров..." />
                    <Button variant="primary" type="submit" disabled={this.state.disableSubmit}>Найти</Button>
                </Form>
                {this.state.redirect &&
                <Redirect to={{
                    pathname: this.state.redirect,
                    state: {str: this.state.str}
                }} />
                }
            </div>
        )
    }
}

export default HeaderSearch;
