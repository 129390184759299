// React
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

//SCSS
import '../../scss/components/main/mainCatalog.scss';
import '../../img/call-byttech.svg';

// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png";

class HeaderCatalog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            active: false,
            selectedItemIndex: 0,
            isToggleOnHover: true,
            items: [],
            stock: []
        }
        this.toggleClass = this.toggleClass.bind(this)
    }

    toggleClass = (id) => {
        this.setState({ selectedItemIndex: id });
    };

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
          .then(response => {
              const data = response.data.data;
              this.setState({
                  stock: data
              }, () => {
                  // console.log(this.state);
              })
          })
          .catch(error => {
              console.error(error);
          })
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/categories-parent`)
            .then(response => {
                this.setState({
                    items: response.data.data.categoryData
                })
            })
            .catch(error => {
                console.error(error);
            })
    }

    render() {
        return (
            <>
                {this.state.items.length > 0 &&
                    <ul className="menu-categories">

                        {this.state.stock.length > 0 &&
                        <li className={`menu-categories__item`}>
                            <Link className="menu-categories__link" to={`/promo`}>
                                <span className="menu-categories__icon">
                                    <img src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/cat-discount.svg`} alt='' />
                                </span>
                                <span className="menu-categories__name">Акции</span>
                            </Link>
                        </li>
                        }
                        {this.state.items.map((cat, index) => (
                            <li key={index} className={`menu-categories__item`}>
                                <Link className={`menu-categories__link 
                                ${cat.id === 29271 ? 'deko' : ''} 
                                ${cat.id === 29272 ? 'gc' : ''} 
                                ${cat.id === 29337 ? 'deko' : ''} 
                                ${cat.id === 29338 ? 'gc' : ''}
                                ${cat.id === 29517 ? 'gc' : ''}
                                `} 
                                to={`/${(cat.id === 29272) ? 'catalog' : 'category'}/${cat.name_slug}`}
                            >
                                    <span className="menu-categories__icon">
                                        <img
                                            src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.icon_url}`}
                                            alt='' />

                                    </span>
                                    <span className="menu-categories__name">{cat.name}</span>
                                </Link>
                            </li>
                        ))}
                        {/*<li className="menu-categories__item" style={{display: "none"}}>*/}
                        {/*    <a className="menu-categories__link deko" href="/">*/}
                        {/*        <span className="menu-categories__icon">*/}
                        {/*            <img src={dekoL} alt="" />*/}
                        {/*        </span>*/}
                        {/*        <span className="menu-categories__name">Посуда Текстиль Подарки</span>*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        {/*<li className="menu-categories__item" style={{display: "none"}}>*/}
                        {/*    <a className="menu-categories__link gc" href="/">*/}
                        {/*        <span className="menu-categories__icon">*/}
                        {/*            <img src={gcL} alt="" />*/}
                        {/*        </span>*/}
                        {/*        <span className="menu-categories__name">Подарочные карты</span>*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                    </ul>
                }
            </>
        )
    }
}

export default HeaderCatalog;
