// React
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'

//SCSS
import '../../scss/components/pages/home2/sliderLine1.scss';

class SliderCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: 4000,
            nextIcon: <span className="arrow-icon next"></span>,
            prevIcon: <span className="arrow-icon prev"></span>
        }
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }
    render() {
        const { nextIcon, prevIcon, interval } = this.state;

        return (

            <Carousel id='sliderCategory' nextIcon={nextIcon} prevIcon={prevIcon} interval={interval} >
                {this.props.items.map( (banner, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <img src={process.env.REACT_APP_BACKEND + 'uploads/categories_banners/' + banner.baner_url} alt={banner.name} />
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        )
    }
}

export default SliderCategory;
