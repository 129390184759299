// React 
import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
// import InputMask from "react-input-mask";

//My components
// import ModalCallbackAnswer from "./ModalCallbackAnswer";
// import ModalCallback from "./ModalCallback";

//SCSS
import '../../scss/components/main/header.scss';

class HeaderContact extends Component {
    render() {
        return (
            <div className="header-menu__contact flex">
                <Nav.Link href="tel:0713266588">(071) 326 65 88</Nav.Link>
                <div className="contact-popup">
                    <div className="contact-popup__btn">
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1334 -5.24537e-08L6.33337 4.8L1.53337 -4.72083e-07L0.333373 1.2L6.33337 7.2L12.3334 1.2L11.1334 -5.24537e-08Z" fill="white" />
                        </svg>
                    </div>
                    <div className="contact-popup__wrap">
                        <div className="contact-popup__inner">
                            <ul className="contact-list">
                                <li className="item flex">
                                    <p className="pr-1">Донецк</p>
                                    <a href="tel:0713142666">(071) 314 26 66</a>
                                </li>
                                <li className="item flex">
                                    <p className="pr-1">Донецк (ЖД)</p>
                                    <a href="tel:0714392214">(071) 439 22 14</a>
                                </li>
								<li className="item flex">
                                    <p className="pr-1">Донецк (Петровский)</p>
                                    <a href="tel:0714392214">(071) 514 75 72</a>
                                </li>
                                <li className="item flex">
                                    <p className="pr-1">Макеевка</p>
                                    <a href="tel:0713266588">(071) 326 65 88</a>
                                </li>
                                <li className="item flex">
                                    <p className="pr-1">Горловка</p>
                                    <a href="tel:0713543104">(071) 354 31 04</a>
                                </li>
								<li className="item flex">
                                    <p className="pr-1">Енакиево</p>
                                    <a href="tel:0713543104">(071) 514 73 00</a>
                                </li>
                            </ul>
                            {/* <div className="callback">
                                <form className="callback__form">
                                    <InputMask mask="(071)-999-9999" placeholder="(071)-___-____"></InputMask>
                                    <ModalCallbackAnswer />
                                </form>
                            </div> */}
                            {/* <Button className="btn-clear call-chat"><i></i>Написать в чат</Button> */}
                            <div className="worktime">
                                <div className="worktime__wrap">
                                    Пн-Вс: <span>8:00-21:00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ModalCallback /> */}
            </div>
        )
    }
}

export default HeaderContact;