
// React 
import React, { Component } from 'react';
import ProfileNav from '../SideNav';
import { Button, Container, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WishList from "../../../components/main/ModalWishlist";
import axios from "axios";
//SCSS
import "../../../scss/components/pages/profile/profile.scss"

import CartCtx from '../../CartCtx'
import Badges from "../../main/Badges";

class PagePWaitingList extends Component {
    static contextType = CartCtx;
    constructor(props) {
        super(props);
        this.state = {
            new_list: '',
            lists: [],
            fuckingUselessPieceOfShit: [],
            showCunt: 0,
            renameCunt: ''
        }
        this.changeInput = this.changeInput.bind(this)
        this.changeRename = this.changeRename.bind(this)
        this.addList = this.addList.bind(this)
        this.newList = this.newList.bind(this)
        this.showRename = this.showRename.bind(this)
        this.renameList = this.renameList.bind(this)
        this.removeList = this.removeList.bind(this)
        this.removeFromList = this.removeFromList.bind(this)
        this.addToWaitList = this.addToWaitList.bind(this)
        this.makeListDefault = this.makeListDefault.bind(this)
    }
    showRename(id, txt) {
        this.setState({
            showCunt: id,
            renameCunt: txt
        }, () => {
            // console.log(this.state.showCunt)
        })
    }
    newList(e) {
        this.setState({
            new_list: e.target.value
        })
    }
    changeRename(e) {
        this.setState({
            renameCunt: e.target.value
        })
    }
    changeInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    addList() {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/add-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            name: this.state.new_list,
            default: this.state.lists.length===0 ? 1 : 0
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data,
                    new_list: ''
                })
            })
        })
    }
    renameList(id) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/rename-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            listId: id,
            name: this.state.renameCunt
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data,
                    showCunt: 0
                })
            }).catch(err => console.error(err))
        }).catch(err => console.error(err))
    }
    removeList(id) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/delete-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            listId: id
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data
                })
            })
        })

    }
    removeFromList(id, prodID) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/delete-product-from-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            listId: id,
            productId: prodID
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data
                })
            }).catch(err => console.error(err))
        }).catch(err => console.error(err))
    }
    makeListDefault(id) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list/default-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            listId: id
        }).then(response => {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
                jwt: localStorage.getItem('galaxy-token')
            }).then(response => {
                this.setState({
                    lists: response.data.data
                })
            }).catch(err => console.error(err))
        }).catch(err => console.error(err))
    }
    addToWaitList(id) {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list/add-product-to-list`, {
            jwt: localStorage.getItem('galaxy-token'),
            productId: id
        })
            .then(res => {
                axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list`, {
                    jwt: localStorage.getItem('galaxy-token')
                }).then(response => {
                    if(response.data.data[0])
                        this.setState({
                            fuckingUselessPieceOfShit: response.data.data[0].products
                        }, function () {
                            // console.log(this.state.products)
                        })
                }).catch(err => {
                    console.error(err)
                })
            })
            .catch(err => {
                // console.error(err)
            })
    }
    componentDidMount() {
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wish-list`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {
            this.setState({
                lists: response.data.data
            })
        }).catch(err => console.error(err))
        // axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/wait-list`, {
        //     jwt: localStorage.getItem('galaxy-token')
        // }).then(response => {
        //     if(response.data.data[0])
        //         this.setState({
        //             fuckingUselessPieceOfShit: response.data.data[0].products
        //         }, function () {
        //             // console.log(this.state.products)
        //         })
        // }).catch(err => {
        //     console.error(err)
        // })
    }

    render() {
        return (
            <main className='page-profile'>
                <Container>
                    <ProfileNav />
                    <section className="prof-main">
                        <div className="breadcrumbs">
                            <div className="breadcrumbs__item">
                                <h1>Личный кабинет</h1>
                            </div>
                            <div className="breadcrumbs__item">
                                <h2>Список желаний</h2>
                            </div>
                        </div>
                        <div className="prof-content pp-profile">
                            <div className="prof-content__header">

                                <div className="input-field d-flex align-items-center">
                                    <Form.Control type="text"
                                                  name="newList"
                                                  className="w-75 mr-2"
                                                  placeholder='Название нового списка'
                                                  value={this.state.new_list}
                                                  onChange={this.newList} />
                                    <Button variant="outline-primary" onClick={this.addList}><span>+</span> Добавить список</Button>
                                </div>
                            </div>
                            <div className="prof-content__body">
                                <div className="wishlist">
                                    {this.state.lists.map((list, i) => (
                                    <div className="wishlist__item" key={i}>
                                        <div className="wishlist-header">
                                            <div className="wishlist-header__top">
                                                <div className={`rename ${list.id===this.state.showCunt ? 'active' : ''}`}>
                                                    <Form.Group controlId="001">
                                                        <div className="input-field">
                                                            <Form.Control type="text" name="name" value={this.state.renameCunt} onChange={this.changeRename} />
                                                        </div>
                                                        <Button variant="primary" className="btn-ok" onClick={()=>{
                                                            this.renameList(list.id)
                                                        }}>
                                                            <span>Ок</span>
                                                        </Button>
                                                        <Button variant="secondary" className="btn-cancel" onClick={() => {
                                                            this.setState({showCunt: 0})
                                                        }}>
                                                            <span>Отмена</span>
                                                        </Button>
                                                    </Form.Group>
                                                </div>
                                                <div className="title">{list.name}</div>
                                                <div className="btn-toolbar">
                                                    <Button variant="simple" onClick={() => {this.showRename(list.id, list.name)}}>
                                                        <svg className="w16"><use xlinkHref="#i_edit" /></svg>
                                                        <span>Переименовать</span>
                                                    </Button>
                                                    <Button variant="simple" onClick={() => {this.removeList(list.id)}}>
                                                        <svg className="w16"><use xlinkHref="#i_delete" /></svg>
                                                        <span>Удалить</span>
                                                    </Button>
                                                    {list.default===1 &&
                                                    <Button variant="simple" className="check">
                                                        <svg className="w16"><use xlinkHref="#i_check" /></svg>
                                                        <span>Список по-умолчанию</span>
                                                    </Button>
                                                    }
                                                    {list.default!==1 &&
                                                    <Button variant="simple" className="check" onClick={()=>{this.makeListDefault(list.id)}}>
                                                        <span>Сделать списком по умолчанию </span>
                                                    </Button>
                                                    }
                                                    {/*<Button variant="simple" className="btn-copy">*/}
                                                    {/*    <span>Копировать ссылку</span>*/}
                                                    {/*    <svg className="w16"><use xlinkHref="#i_copy" /></svg>*/}
                                                    {/*</Button>*/}
                                                </div>
                                            </div>
                                            <div className="wishlist-header__bottom">
                                                <div className="left-side">
                                                    {/*<p>Товаров 2 на сумму<span className="i-rub">13 778</span></p>*/}
                                                    {/*<Button variant="primary">*/}
                                                    {/*    <svg className="w20"><use xlinkHref="#i_cart" /></svg>*/}
                                                    {/*    <span>В корзину</span>*/}
                                                    {/*</Button>*/}
                                                </div>

                                                {/*<div className="right-side">*/}
                                                {/*    <span>Сортировка</span>*/}
                                                {/*    <select id='order' className="select-custom">*/}
                                                {/*        <option value='1'> По умолчанию </option>*/}
                                                {/*        <option value='2'> От дешевых к дорогим </option>*/}
                                                {/*        <option value='3'> От дорогих к дешевым </option>*/}
                                                {/*    </select>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div className="wishlist-body">
                                            <div className="products-dropdown">
                                                <div className="products-dropdown__wrap">
                                                    {list.products && list.products.map((product, index) => (
                                                        <div className="products-dropdown__item" key={index}>
                                                            <div className="products__wrap" key={index}>
                                                                {/*<div className="checkbox-wrap">*/}
                                                                {/*    <div className="checkbox-wrap__item">*/}
                                                                {/*        <input className="custom-checkbox" type="checkbox" id={product.id} />*/}
                                                                {/*        <label htmlFor={product.id}></label>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                                <div className="btn-toolbar">
                                                                    <Button variant="simple"
                                                                            className="btn-delete"
                                                                            onClick={()=>{this.removeFromList(list.id, product.id)}}>
                                                                        <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                                    </Button>
                                                                    {/* {
                                                                        this.state.fuckingUselessPieceOfShit.filter((f,i)=>{
                                                                            return f.id === product.id
                                                                        }).length === 0 &&
                                                                        <Button variant="simple" onClick={()=>{this.addToWaitList(product.id)}}>
                                                                            <svg className="w20"><use xlinkHref="#i_time" /></svg>
                                                                        </Button>
                                                                    }
                                                                    {
                                                                        this.state.fuckingUselessPieceOfShit.filter((f,i)=>{
                                                                            return f.id === product.id
                                                                        }).length > 0 &&
                                                                        <Button variant="simple" style={{opacity: .2, cursor: 'default'}}>
                                                                            <svg className="w20"><use xlinkHref="#i_time" /></svg>
                                                                        </Button>
                                                                    } */}

                                                                    <WishList productId={product.id} productCount={product.count} noWL={true}/>
                                                                </div>
                                                                <div className="products-image">
                                                                    <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                                                                        <img
                                                                            src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url}
                                                                            alt={("Изображение товара " + product.name)}/>
                                                                    </Link>
                                                                </div>
                                                                <div className="products-colors"><div className="products-colors__wrap undefined"></div></div>
                                                                <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                                                                    {product.name}
                                                                </Link>

                                                                <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                                                                    <div className="producst-price__wrap">
                                                                        <div className="products-price__old">
                                                                            <span className="price i-rub">{product.oldprice}</span>
                                                                        </div>
                                                                        <div className="products-price__main">
                                                                            <span className="price i-rub">
                                                                                {product.price.toLocaleString('ru')}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="products-cart-sm">
                                                                        {product.count > 0 &&
                                                                        <>
                                                                            {!this.context.isInCart(product.id) &&
                                                                            <CartCtx.Consumer>
                                                                                {({ cart, addProduct }) => (
                                                                                    <Button variant="simple" className="add-to-cart" onClick={() => addProduct(product.id)}><i className="i-cart"></i></Button>
                                                                                )}
                                                                            </CartCtx.Consumer>
                                                                            }
                                                                            {this.context.isInCart(product.id) &&
                                                                            <CartCtx.Consumer>
                                                                                {({ cart, addProduct }) => (
                                                                                    <Button variant="simple" className="add-to-cart added" disabled><i className="i-cart"></i></Button>
                                                                                )}
                                                                            </CartCtx.Consumer>
                                                                            }
                                                                        </>
                                                                        }
                                                                        {/*<Link to="/SingleProduct"><span className="i-cart"></span></Link>*/}
                                                                    </div>
                                                                </div>

                                                                <div className="products-stock">
                                                                    <p className={product.stockStatus}>
                                                                        {product.stockLabel}
                                                                    </p>
                                                                </div>

                                                                {
                                                                    product.priceBadgeStock.bages.length > 0 &&
                                                                    <Badges items={product.priceBadgeStock} />
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </main >
        )
    }
}

export default PagePWaitingList;
