
// React 
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputNumber from 'rc-input-number';

import CartCtx from '../CartCtx'
//SCSS
import "../../scss/components/pages/cart/cart.scss"

class CartMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: {},
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
    }
    onChange = (e, id, count, articule) => {
        // console.log(e, id, count, articule)
        if (e < count) this.context.decreaseProduct(id)
        if (e > count) this.context.increaseProduct(id)
    }
    static contextType = CartCtx;
    render() {
        return (
            <div className="modal-cart__wrap">
                <div className="cart-items">
                    <div className="cart-items__wrap">{this.state.product.id}
                        {this.context.cart.map((product, index) => (
                            <div className="cart-item" key={index}>
                                <div className="products-image">
                                    <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                                        <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.img} alt={("Изображение товара" + product.name)} />
                                    </Link>
                                </div>
                                <div className="products-info">
                                    <div className="products-info__top">
                                        <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                                            {product.name}
                                        </Link>
                                        <div className="products-remove">
                                            <CartCtx.Consumer>
                                                {({ cart, removeProduct }) => (
                                                    <Button variant="link" className="products-remove__btn" onClick={() => removeProduct(product.product_id)}>
                                                        <i className="i-remove" />
                                                    </Button>
                                                )}
                                            </CartCtx.Consumer>
                                        </div>
                                    </div>

                                    <div className="products-info__bottom">
                                        <div className="products-price in-stock">
                                            <div className="products-price__wrap">

                                                <p className="product-header__code">Код: {product.code}<span></span></p>
                                                <div className="products-price__main">
                                                    <span className="price i-rub">
                                                        {product.price / product.count}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="products-sum">
                                            <div className="products-quantity">
                                                <InputNumber
                                                    key={index}
                                                    min={0}
                                                    max={10000}
                                                    style={{ width: 100 }}
                                                    value={this.context.cart[index].count}
                                                    onChange={(e) => this.onChange(e, this.context.cart[index].product_id, this.context.cart[index].count)}
                                                    readOnly={false}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div className="products-sum__total">
                                                <span className="price i-rub">
                                                    {parseFloat((product.price * 1).toFixed(2)).toLocaleString('ru')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="cart-footer">
                    <div className="cart-footer__left">
                    </div>
                    <div className="cart-footer__right">
                        <div className="cart-total">
                            <p>Всего:</p>
                            <span className="i-rub">{parseFloat((this.context.getTotalSumm() * 1).toFixed(2)).toLocaleString('ru')}</span>
                        </div>
                        <div className="cart-checkout">
                            {this.context.getTotalCount() > 0 &&
                            <Link to="/PageCheckout" className="btn btn-primary d-none">Оформить заказ</Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CartMain;
