// React
import React, { Component } from 'react';
import Countdown, { zeroPad } from 'react-countdown-now';
// import TinySlider from 'tiny-slider-react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import Badges from "../main/Badges";
import WishList from "../main/ModalWishlist";
import { Button } from 'react-bootstrap';
// import StarRatings from 'react-star-ratings';

//SCSS
import '../../scss/components/pages/home2/sliderLine1.scss';
import '../../scss/components/pages/home2/countdown.scss';
import '../../scss/components/main/productCard.scss';
import { Col, Row } from 'react-bootstrap';
import CartCtx from '../CartCtx'

const Completionist = () => <p className="countdown__end">Акция завершена.</p>;
const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a complete state
        return <Completionist />;
    } else {
        // Render a countdown
        return (
            <div className="countdown__wrap">
                <p className="countdown__title">До конца акции осталось:</p>
                <span className="countdown__timer"><b>{days}</b>:<b>{zeroPad(hours)}</b>:<b>{zeroPad(minutes)}</b>:<b>{zeroPad(seconds)}</b></span>
                <div className="countdown__days">
                    <p>Дней</p>
                    <p>Часов</p>
                    <p>Минут</p>
                    <p>Секунд</p>
                </div>
            </div>
        );
    }
};

class PromoLine3 extends Component {
    static contextType = CartCtx;
    render() {
        // const ProductsNavi = (slide) => { };

        // const settings = {
        //     lazyload: true,
        //     autoplay: false,
        //     nav: true,
        //     controls: true,
        //     autoplayButtonOutput: false,
        //     mouseDrag: true,
        //     loop: false,
        //     responsive: {
        //         1200: {
        //             items: 4,
        //         },
        //         768: {
        //             items: 4,
        //         },
        //         0: {
        //             items: 2,
        //         }
        //     }

        // };

        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 6,
            },
            desktop: {
                breakpoint: { max: 3000, min: 1200 },
                items: 4,
            },
            tablet: {
                breakpoint: { max: 1200, min: 768 },
                items: 3,
            },
            mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 2,
            },
        };

        let start = new Date(this.props.startDate.replace(' ', 'T') + '.000Z')
        let end = new Date(this.props.endDate.replace(' ', 'T') + '.000Z')
        let startString = start.getDate() + '.' + (start.getMonth() + 1 < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1) + '.' + start.getFullYear();
        let endString = end.getDate() + '.' + (end.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1) + '.' + end.getFullYear();
        // console.log(startString, endString);
        return (
            <Row>
                <Col xs={12} xl={8} className="products promo-product">
                    <h3 className="page-subtitle">Акционные товары <span>{this.props.name}</span></h3>
                    <Carousel responsive={responsive}
                        autoPlay
                        autoPlaySpeed={3000}>
                        {this.props.products.map((product, index) => (
                            <div className="products__wrap" key={index}>
                                <div className="btn-toolbar">
                                                                <WishList />
                                </div>
                                <div className="products-image">
                                    <Link className="products-image__link" to={`/product/${product.name_slug}`}>
                                        <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + product.photo_url} alt={("Изображение товара " + product.name)} />
                                    </Link>
                                </div>

                                <div className="products-colors">
                                    <div className={("products-colors__wrap " + product.colors)}>
                                        {/*        <Link to="#" className="products-colors__item active">*/}
                                        {/*            <span style={{ background: '#3A50AE' }}></span>*/}
                                        {/*        </Link>*/}
                                        {/*        <Link to="#" className="products-colors__item">*/}
                                        {/*            <span style={{ background: '#000' }}></span>*/}
                                        {/*        </Link>*/}
                                        {/*        <Link to="#" className="products-colors__item">*/}
                                        {/*            <span style={{ background: '#ddd' }}></span>*/}
                                        {/*        </Link>*/}
                                        {/*        <Link to="#" className="products-colors__item">*/}
                                        {/*            <span style={{ background: '#E51414' }}></span>*/}
                                        {/*        </Link>*/}
                                    </div>
                                </div>

                                <Link className="products-title" to={`/product/${product.name_slug}`} title={product.name}>
                                    {product.name}
                                </Link>

                                {/*<Link to="/SingleProduct" className="products-reviews">*/}
                                {/*    /!* Классы no-rating / no-reviews для products-reviews, для добавления иконки, когда нет звездочек *!/*/}
                                {/*    <StarRatings*/}
                                {/*        rating={product.rating}*/}
                                {/*        starDimension="13px"*/}
                                {/*        starRatedColor="#FFB800"*/}
                                {/*        starSpacing="1px"*/}
                                {/*    />*/}
                                {/*    <i className="i-reviews"></i>*/}
                                {/*    <p className="products-reviews__link">{product.reviews}</p>*/}
                                {/*</Link>*/}

                                <div className={("products-price " + product.sale + " " + product.stockStatus)}>
                                    <div className="producst-price__wrap">
                                        <div className="products-price__old">
                                            {product.priceBadgeStock.price.discountPrice &&
                                                <span className="price i-rub d-inline-block">{product.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                                            }
                                        </div>
                                        <div className="products-price__main">
                                            <span className="price i-rub">
                                                {product.priceBadgeStock.price.discountPrice &&
                                                    product.priceBadgeStock.price.discountPrice.toLocaleString('ru')
                                                }
                                                {!product.priceBadgeStock.price.discountPrice &&
                                                    product.price.toLocaleString('ru')
                                                }

                                            </span>
                                        </div>
                                    </div>
                                    {process.env.REACT_APP_TEST === 1 &&
                                    <div className="products-cart-sm">
                                        {product.count > 0 &&
                                            <>
                                                {!this.context.isInCart(product.id) &&
                                                    <CartCtx.Consumer>
                                                        {({ cart, addProduct }) => (
                                                            <Button variant="simple" className="add-to-cart" onClick={() => addProduct(product.id)}><i className="i-cart"></i></Button>
                                                        )}
                                                    </CartCtx.Consumer>
                                                }
                                                {this.context.isInCart(product.id) &&
                                                    <CartCtx.Consumer>
                                                        {({ cart, addProduct }) => (
                                                            <Button variant="simple" className="add-to-cart added" disabled><i className="i-cart"></i></Button>
                                                        )}
                                                    </CartCtx.Consumer>
                                                }
                                            </>
                                        }
                                    </div>
                                    }
                                </div>

                                <div className="products-stock">
                                    {product.count > 0 &&
                                        <p className='stock-in'>
                                            В наличии
                                    </p>
                                    }
                                    {product.count < 1 &&
                                        <p className='stock-out'>
                                            Нет в наличии
                                    </p>
                                    }
                                </div>
                                {
                                    product.priceBadgeStock.bages.length > 0 &&
                                    <Badges items={product.priceBadgeStock} />
                                }


                            </div>
                        ))}
                    </Carousel>

                </Col>

                <Col xs={12} xl={4}>
                    <div className="countdown">
                        <h1 className="countdown__header">Акция "{this.props.name}"</h1>
                        <Countdown
                            date={this.props.endDate.replace(' ', 'T') + '.000Z'}
                            renderer={renderer}
                        />
                        <p className="countdown__footer">Период проведения акции: <span>{startString} - {endString}</span></p>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default PromoLine3;
