
// React 
import React, { Component } from 'react';
import { Button, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CartMain from './cartMain';

import CartCtx from '../CartCtx'

class CartHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showCart: false,
            orderCount: 0,
            orderSumm: 0,
            orderDetails: ""
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.localStorageUpdated = this.localStorageUpdated.bind(this)
    }

    static contextType = CartCtx;

    handleClose() {
        this.setState({
            showCart: false
        })
    }
    handleShow() {
        this.setState({
            showCart: true
        })
    }
    localStorageUpdated() {
    }

    static contextType = CartCtx;
    render() {
        return (
            <>
                <div className="h-cart">
                    <Button className="btn-cart" onClick={this.handleShow}>
                        <i className="i-cart"></i>
                        <span className="cart-count">
                            {this.context.getTotalCount()}
                        </span>
                    </Button>
                    <div className="popup-cart">
                        <div className="popup-cart__wrap">
                            <div className="popup-cart__header">
                                <p>Товаров в корзине:
                                <span>
                                        {this.context.getTotalCount()}
                                    </span>
                                </p>
                                <p>Общая сумма: <span className="i-rub">{(this.context.getTotalSumm() * 1).toLocaleString('ru')}</span></p>
                            </div>
                            <div className="popup-cart__footer">
                                <Nav.Link href="/checkout" className="btn btn-primary">Оформить заказ</Nav.Link>
                                <Button variant="link" className="open-cart" onClick={this.handleShow}>Перейти в корзину</Button>
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={this.state.showCart}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="modal-cart"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Корзина</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CartMain />
                            <Button variant="link" onClick={this.handleClose} className="cart-close">Продолжить покупки</Button>
                            {this.context.getTotalCount() > 0 &&
                            <Link to="/checkout" className="btn btn-primary to-checkout" onClick={this.handleClose}>Оформить
                                заказ</Link>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
                {this.context.getTotalCount() === 0 &&
                    <div className="cart_blocked" />
                }
            </>
        )
    }
}

export default CartHeader;
