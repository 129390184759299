//React
import React, { Component } from 'react';

class SKeditorContent extends Component {
  // eslint-disable-next-line no-useless-constructor
    constructor(props) {
      super(props);
    }
    render() {
        return (
            <>
                <div className="promo-sk border ptb30 mtb20 px-3 f fc">
                  <div dangerouslySetInnerHTML={{__html: this.props.text}} />
                </div>
            </>
        );
    }
}

export default SKeditorContent;
