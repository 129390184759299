// React
import React, {Component} from 'react';
import {Link} from "react-router-dom";

//SCSS
import '../../../scss/components/main/mainCatalog.scss';
import '../../../scss/components/pages/promo/index.scss';
import '../../img/call-byttech.svg';

// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png"
import axios from "axios";
import {Form} from "react-bootstrap";


class MenuCatalog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      show_search: 0,
      category_main: '',
      items: [],
      filter: ''
    }
    this.changeFilter = this.changeFilter.bind(this)
    this.search = this.search.bind(this)

  }

  changeFilter = (e) => {
    this.setState({filter: e.target.value});
  };

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stock-categories-sidebar/first/all-stocks`)
      .then(response => {
        this.setState({
          items: response.data.data.categories,
          show_search: response.data.data.show_search,
          category_main: response.data.data.category_main,
        }, () => {
          // console.log(this.state.items.length)
          if (this.state.category_main !== 'all') {
            let found = this.state.items.filter((item, i) => item.name_slug === this.state.category_main)
            this.setState({
              items: found
            })
          }
        })
      })
      .catch(error => {
        console.error(error);
      })
  }

  search(e) {
    if(e) e.preventDefault()
    let str = this.state.filter
    str = str.replace(/[^a-zA-Zа-яА-ЯёЁйЙьЬъЪ0-9_\- ]/g, "")
    str = str.replace(/\s+/g, '%20')
    window.location.href=`/stock-search/1?q=${str}&s=all-stocks`
  }

  render() {
    // console.log(this.props.items)
    // const regexp = new RegExp(this.state.filter, "ig")
    return (
      <>
        <h6>Категории акционных товаров</h6>
        <Form className='search' onSubmit={this.search}>
          <Form.Control type="text" className="search_categories" id="search_categories" autoComplete="off"
                        placeholder="Поиск акционных товаров" onChange={this.changeFilter} value={this.state.filter}/>
        </Form>
        <ul className="menu-categories menu-promo ml-0">
          {this.state.items.length > 0 && this.state.items.map((cat, index) => (
            <li key={index}
                className={`menu-categories__item`}
            >
              <Link
                to={`/promo/${cat.type === 'have_cat' ? 'all-stocks' : 'catalog'}/${cat.name_slug}`}
                className={`d-flex align-items-center`}
              >
                <img
                  height="24"
                  src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.icon_url}`}
                  alt=''/>
                <span className="menu-categories__name"  style={{fontSize: '1.1rem'}}>
                  {cat.name} {cat.count && (<span>({cat.count})</span>)}
                </span>
              </Link>
              {cat.subCategories && (
                <ul className="menu-promo__sub pl-4 mb-3">
                  {cat.subCategories.map((subcat, index) => (
                    <React.Fragment key={index}>
                      <li key={index}>
                          <span>
                              <Link
                                className={`menu-promo__sub-link`}
                                to={`/promo/${subcat.type === 'have_cat' ? 'all-stocks' : 'catalog/all-stocks'}/${subcat.name_slug}`}>
                                  {subcat.name} {subcat.count && (<span>({subcat.count})</span>)}
                              </Link>
                          </span>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </>
    )
  }
}

export default MenuCatalog;
