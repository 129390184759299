import React from 'react';
const CartCtx = React.createContext({
    cart:[],
    addProduct: (id) => {},
    removeProduct: (id) => {},
    increaseProduct: (id) => {},
    decreaseProduct: (id) => {},
    isInCart: (id) => {},
    clearCart: () => {},
    getTotalCount: () => {},
    getTotalSumm: () => {}
});
export default CartCtx