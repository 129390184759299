import React, { Component } from "react";
import { Container, Breadcrumb, Tabs, Tab } from "react-bootstrap";
import ContactMakeevka from "./ContactMakeevka"
import ContactDonetsk from "./ContactDonetsk"
import ContactDonetsk2 from "./ContactDonetsk2"
import ContactDonetsk3 from "./ContactDonetsk3"
import ContactGorlovka from "./ContactGorlovka"
import ContactEnakievo from "./ContactEnakievo"
import ContactMariupol from "./ContactMariupol"

//SCSS
import "../../scss/components/pages/contacts/pageContacts.scss"

class PageContact extends Component {
    render() {
        
        return (
            <>
                <main className='page-contact'>
                    <Container>
                        <Breadcrumb>
                            <Breadcrumb.Item href="#"><i className="i-home"></i></Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="page-title">Контакты</h1>
                        <div className="contact">
                            <Tabs defaultActiveKey="city01" transition={false} id="contactTabs">
                                <Tab eventKey="city01" title="Макеевка">
                                    <ContactMakeevka />
                                </Tab>
                                <Tab eventKey="city02" title="Донецк">
                                    <ContactDonetsk />
                                </Tab>
                                <Tab eventKey="city03" title="Донецк ЖД">
                                    <ContactDonetsk2 />
                                </Tab>
                                <Tab eventKey="city06" title="Донецк (р-н Петровский)">
                                    <ContactDonetsk3 />
                                </Tab>
                                <Tab eventKey="city04" title="Горловка">
                                    <ContactGorlovka />
                                </Tab>
                                <Tab eventKey="city05" title="Енакиево">
                                    <ContactEnakievo />
                                </Tab>
								<Tab eventKey="city07" title="Мариуполь">
                                    <ContactMariupol />
                                </Tab>
                            </Tabs>
                        </div>
                    </Container>
                </main>
            </>
        )
    }
}
export default PageContact