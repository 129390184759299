// React
import React, {Component} from 'react';
import {Link} from "react-router-dom";

//SCSS
import '../../scss/components/main/mainCatalog.scss';
import '../../img/call-byttech.svg';
import axios from "axios";

// import dekoL from "../../img/temp/deko.png";
// import gcL from "../../img/temp/gc.png";

class HeaderCatalog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: false,
      selectedItemIndex: 0,
      items: [],
      stock: []
    }
    this.toggleClass = this.toggleClass.bind(this)
    this.closeMenu = this.closeMenu.bind(this)

  }

  toggleClass = (id) => {
    this.setState({selectedItemIndex: id});
  };

  closeMenu() {
    document.getElementById('menu-underlay').click()
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_BASE}/new-stocks/stocks-in-sidebar`)
      .then(response => {
        const data = response.data.data;
        this.setState({
          stock: data
        }, () => {
          // console.log(this.state);
        })
      })
      .catch(error => {
        console.error(error);
      })
  }

  render() {
    // console.log(this.props.items)
    return (
      <>
        <ul className="menu-categories">
          {this.state.stock.length > 0 &&
          <li
            className={`menu-categories__item ${this.state.selectedItemIndex === 'stock' ? 'menu-categories__item_state_hovered' : ""}`}
            onMouseEnter={() => this.toggleClass('stock')}>
            <Link className="menu-categories__link" to={`/promo`}
                  onClick={this.closeMenu}>
              <span className="menu-categories__icon">
                  <img
                    src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/cat-discount.svg`}
                    alt=''/>
              </span>
              <span className="menu-categories__name">Акции</span>
            </Link>
            <div className="menu__hidden-content">
              <div className="menu__main-cats">
                <div className="menu__main-cats-inner">
                  <div className="menu__hidden-list flex-column">
                    <div>
                      <Link className="menu__hidden-title font-weight-bold mb-2"
                            to={`/promo/`}
                            onClick={this.closeMenu}>
                        Все акции
                      </Link>
                    </div>
                    {this.state.stock.map((subcat, index) => (
                      <div key={index}>
                        <Link className="menu__hidden-title"
                              to={`/promo/${subcat.name_slug}`}
                              onClick={this.closeMenu}>
                          {subcat.name}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </li>
          }
          {this.props.items.map((cat, index) => (
            <React.Fragment key={index}>
              {cat.categoryData.redirect === null &&
              <li key={index} onMouseEnter={() => this.toggleClass(cat.categoryData.id)}
                  className={`menu-categories__item ${this.state.selectedItemIndex === cat.categoryData.id ? 'menu-categories__item_state_hovered' : ""}`}
                  onClick={this.closeMenu}>
                <Link
                  className={`menu-categories__link 
                                ${cat.categoryData.id === 29271 ? 'deko' : ''} 
                                ${cat.categoryData.id === 29272 ? 'gc' : ''} 
                                ${cat.categoryData.id === 29337 ? 'deko' : ''} 
                                ${cat.categoryData.id === 29338 ? 'gc' : ''}
                                ${cat.categoryData.id === 29517 ? 'deko' : ''}
                                `}
                  to={`/${cat.subCategories.length > 0 ? 'category' : 'catalog'}/${cat.categoryData.name_slug}`}>
                                <span className="menu-categories__icon">
                                    <img
                                      src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/${cat.categoryData.icon_url}`}
                                      alt=''/>

                                </span>
                  <span className="menu-categories__name">{cat.categoryData.name}</span>
                </Link>
                {cat.subCategories && (
                  <div className="menu__hidden-content">
                    <div className="menu__main-cats">
                      <div className="menu__main-cats-inner">
                        {/*<div className="menu__hidden-column">*/}
                        <ul className="menu__hidden-list">
                          {cat.subCategories.map((subcat, index) => (
                            <React.Fragment key={index}>
                              {subcat.subCategories.redirect === null &&
                              <li key={index}>
                                                        <span onClick={this.closeMenu}>
                                                            <Link className="menu__hidden-title"
                                                                  to={`/${subcat.subSubCategories.length === 0 ? 'catalog' : 'category'}/${subcat.subCategories.name_slug}`}>{subcat.subCategories.name}</Link>
                                                        </span>
                                {subcat.subSubCategories.length > 0 && (
                                  <ul>
                                    {subcat.subSubCategories.map((subChild, index) => (
                                      <React.Fragment key={index}>
                                        {subChild.redirect === null &&
                                        <li key={index} onClick={this.closeMenu}><Link
                                          title={subChild.name} className="menu__link"
                                          to={`/${subChild.type === 'no_have_cat' ? 'catalog' : 'category'}/${subChild.name_slug}`}> {subChild.name} </Link>
                                        </li>
                                        }
                                      </React.Fragment>
                                    ))}
                                  </ul>
                                )}
                              </li>
                              }
                            </React.Fragment>
                          ))}
                        </ul>
                        {/*</div>*/}

                        <div
                          className="menu__hidden-column menu__hidden-column_no_padding banner-col"
                          onClick={this.closeMenu}>
                          <Link to={`/category/${cat.categoryData.name_slug}`}>
                            {
                              cat.baner.length &&
                              <img alt=""
                                   src={`${process.env.REACT_APP_BACKEND}uploads/categories_banners/${cat.baner[0].baner_url}`}/>
                            }

                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
              }
            </React.Fragment>
          ))}
        </ul>
      </>
    )
  }
}

export default HeaderCatalog;
