// React
import React, {Component} from 'react';
import Countdown, {zeroPad} from 'react-countdown-now';
import {Button, Modal} from 'react-bootstrap';
//SCSS
import '../../../scss/components/pages/home2/countdown.scss';
import CartCtx from '../../CartCtx'
import {Link} from "react-router-dom";

const Completionist = () => <p className="countdown__end">Акция завершена.</p>;
const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a complete state
        return <Completionist/>;
    } else {
        // Render a countdown
        return (
            <div className="countdown__wrap">
                <p className="countdown__title">До конца акции осталось:</p>
                <span
                    className="countdown__timer"><b>{days}</b>:<b>{zeroPad(hours)}</b>:<b>{zeroPad(minutes)}</b>:<b>{zeroPad(seconds)}</b></span>
                <div className="countdown__days">
                    <p>Дней</p>
                    <p>Часов</p>
                    <p>Минут</p>
                    <p>Секунд</p>
                </div>
            </div>
        );
    }
};

class PromoLine3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }

    showModal() {
        this.setState({
            modal: true
        })
    }

    hideModal() {
        this.setState({
            modal: false
        })
    }

    componentDidMount() {
        // console.log(this.props.button)
    }

    static contextType = CartCtx;

    getLink(linkObj) {

        if (typeof linkObj === 'string') {
            if(linkObj.indexOf("https://galaktika.me") !== -1)
                return linkObj.replace("https://galaktika.me/", '/')
            if(linkObj.indexOf("https://avoidrandomusers99.galaktika.me/") !== -1)
                return linkObj.replace("https://avoidrandomusers99.galaktika.me/", '/')
            return '/' + linkObj
        }

        if (!linkObj || (linkObj.type !== 'prod' && linkObj.type !== 'cat')) return '/'

        if (linkObj.type === 'prod') return '/product/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'have_cat') return '/category/' + linkObj.name_slug
        if (linkObj.type === 'cat' && linkObj.type_cat === 'no_have_cat') return '/catalog/' + linkObj.name_slug
    }

    render() {

        // let start = new Date(this.props.startDate)
        // let end = new Date(this.props.endDate)
        //let startString = start.getDate() + '.' + (start.getMonth() + 1 < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1) + '.' + start.getFullYear();
        //let endString = end.getDate() + '.' + (end.getMonth() + 1 < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1) + '.' + end.getFullYear();
        // console.log(startString, endString);
        return (
            <div className="countdown">
                <h1 className="countdown__header text-center d-block"
                    dangerouslySetInnerHTML={{__html: this.props.name}}/>
                {this.props.status === 'open' &&
                (
                    <>
                        <p dangerouslySetInnerHTML={{__html: this.props.time_stock}}/>
                        <Countdown
                            date={this.props.endDate}
                            renderer={renderer}
                        />
                        {/*<p className="countdown__footer">Период проведения акции: <span>{startString} - {endString}</span></p>*/}
                        <div className="flex  mt-3 gap">
                            {this.props.button &&
                            (
                                <>
                                    <Button variant="primary" className="fg" onClick={this.showModal}
                                            dangerouslySetInnerHTML={{__html: this.props.button}}>
                                        {/*  сказать, что бэк отдающий HTML для текста кнопки - это пиздец  */}
                                    </Button>
                                    {this.props.link &&
                                    (
                                          <Link to={this.getLink(this.props.link)}>
                                              Перейти в Акцию
                                          </Link>
                                    )
                                    }

                                </>
                            )
                            }

                            {!this.props.button && this.props.link &&
                                (
                                <div className="text-center w-100">
                                    <Link to={this.getLink(this.props.link)}>
                                        Перейти в Акцию
                                    </Link>
                                </div>
                                )
                            }
                        </div>
                        {this.props.button &&
                        (
                            <Modal
                                show={this.state.modal}
                                onHide={this.hideModal}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Условия акции</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div dangerouslySetInnerHTML={{__html: this.props.text_for_modal}}/>
                                </Modal.Body>
                            </Modal>
                        )}
                    </>
                )
                }
                {this.props.status !== 'open' &&
                (
                    <div dangerouslySetInnerHTML={{__html: this.props.text_end}}/>
                )
                }

            </div>
        )
    }
}

export default PromoLine3;
