
// React 
import React, { Component } from 'react';
import axios from 'axios';
import { Link, NavLink, Redirect } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import ProfileCartModal from './ProfileCartModal';
//SCSS
import "../../scss/components/pages/profile/profile.scss"

class ProfileNav extends Component {

    constructor(props) {
        super(props)
        this.state = {
            goback: false,
            userData: {},
            selectedFile: null,
            valid: false,
            loaded: 0,
            btnText: 'Выбрать файл',
            avatar: false,
            errorMsg: ''
        }
        this.logout = this.logout.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this)
        this.onClickHandler = this.onClickHandler.bind(this)
    }
    onChangeHandler=event=>{
        let err = ''
        if(event.target.files[0].size > 3*1024*1024) {
            err += 'Размер файла не должен превышать 3МБ'
        }
        if(event.target.files[0].type.indexOf('image') === -1) {
            err += '<br/>Неверный формат файла'
        }
        this.setState({
            selectedFile: event.target.files[0],
            btnText: err==='' ? event.target.files[0].name : 'Выбрать файл',
            errorMsg: err,
            loaded: 0,
            valid: err===''
        }, ()=>{

        })
    }
    onClickHandler = () => {
        const data = new FormData()
        data.append('image', this.state.selectedFile)
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile/update-avatar?jwt=${localStorage.getItem('galaxy-token')}`, data, {
            headers: { Authorization: "Bearer " + localStorage.getItem('galaxy-token') }
        }).then(response => {
            axios.get(`${process.env.REACT_APP_API_BASE}/cabinet/profile/avatar?jwt=${localStorage.getItem('galaxy-token')}`)
                .then(res=>{
                    console.log(res)
                    this.setState({
                        btnText: 'Выбрать файл',
                        selectedFile: null,
                        avatar: res.data.url
                    }, ()=>{
                        document.getElementById('userpic').src = process.env.REACT_APP_BACKEND + 'users_avatar/' + this.state.avatar + '?rng=' + new Date().getTime()
                    })
                })
                .catch(err=>{

                })

        }).catch(err => {
            console.error(err)
        })
        // console.log(data.get('file'))
    }
    logout(e) {
        e.preventDefault()
        localStorage.removeItem('galaxy-token')
        window.location.href = '/'
        // this.setState({
        //     goback: true
        // })
        // axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/logout`, {
        //     jwt: localStorage.getItem('galaxy-token')
        // }).then(response => {
        //     localStorage.removeItem('galaxy-token')
        //     this.setState({
        //         goback: true
        //     })
        // }).catch(err => {
        //     console.error(err)
        // })
    }
    componentDidMount() {
        document.title = "Личный кабинет | Галактика"
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {
            this.setState({
                userData: response.data.data,
                avatar: response.data.data.info.icon_url,
            })
        }).catch(err => {
            console.error(err)
        })
    }
    render() {
        return (
            <aside className="prof-sidebar">
                {this.state.goback && <Redirect to='/' />}
                <div className="prof-sidebar__wrap">
                    <div className="prof-sidebar__header">
                        <div className="avatar">
                            {
                                this.state.avatar &&
                                <img id='userpic' src={process.env.REACT_APP_BACKEND + 'users_avatar/' + this.state.avatar + '?rng=' + new Date().getTime()} alt="" />
                            }
                            {
                                !this.state.avatar &&
                                <img id='userpic' src="https://sellik.com/images/default.png" alt="" />
                            }
                        </div>
                        <h5>
                            {this.state.userData.info &&
                                this.state.userData.info.name + ' ' + this.state.userData.info.surname
                            }
                        </h5>
                        {this.props.isEdit &&
                            <div>
                                <div className='d-flex justify-content-around mb-1 pr-3'>
                                    <label className='upload-avatar'>
                                        <span className="btn btn-secondary">
                                            {this.state.btnText}
                                        </span>
                                        <input type="file" name="file" onChange={this.onChangeHandler} />
                                    </label>
                                    <button type="button" className="btn btn-success upload-avatar-btn"
                                            onClick={this.onClickHandler}
                                            disabled={!this.state.valid}
                                    >
                                        Загрузить
                                    </button>
                                </div>
                                <div className="text-danger p-2 mb-1" dangerouslySetInnerHTML={{__html: this.state.errorMsg}}></div>
                            </div>
                        }
                    </div>
                    <nav className="prof-sidebar__nav">
                        <ul className="nav-list">
                            <li className="nav-list__item">
                                <NavLink to="/profile" className="nav-list__link" exact activeClassName='active'>
                                    <svg><use xlinkHref="#i_profile" /></svg>
                                    <p>Профиль</p>
                                </NavLink>
                            </li>
                            <li className="nav-list__item">
                                <NavLink to="/profile/orders" className="nav-list__link" activeClassName='active'>
                                    <svg><use xlinkHref="#i_orders" /></svg>
                                    <p>Мои заказы</p>
                                </NavLink>
                            </li>
                            <li className="nav-list__item">
                                <NavLink to="/profile/wishlist" className="nav-list__link" activeClassName='active'>
                                    <svg><use xlinkHref="#i_like" /></svg>
                                    <p>Список желаний</p>
                                </NavLink>
                            </li>
                            <li className="nav-list__item">
                                <ProfileCartModal />
                            </li>
                            <li className="nav-list__item">
                                <NavLink to="/profile/waiting-list" className="nav-list__link" activeClassName='active'>
                                    <svg><use xlinkHref="#i_time" /></svg>
                                    <p>Лист ожидания</p>
                                </NavLink>
                            </li>
                            <li className="nav-list__item">
                                <NavLink to="/profile/viewed-products" className="nav-list__link" activeClassName='active'>
                                    <svg><use xlinkHref="#i_eye2" /></svg>
                                    <p>Просмотренные товары</p>
                                </NavLink>
                            </li>
                            <li className="nav-list__item">
                                <Link to='#' onClick={this.logout} className="nav-list__link">
                                    <svg className="red"><use xlinkHref="#i_exit" /></svg>
                                    <p>Выйти</p>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}

export default ProfileNav;
