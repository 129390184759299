// React 
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

//My components
import HeaderContact from "../main/HeaderContact";

//SCSS
import '../../scss/components/main/header.scss';

class HeaderTopLine extends Component {
    render() {
        return (
            <div className='header-menu__top flex'>
                <Nav className='header-menu__main'>
                  <Link to="/promo" className="nav-link red">Акции</Link>
                  <Link to="/contact" className="nav-link">Контакты</Link>
                </Nav>
                <HeaderContact />
            </div>
        )
    }
}

export default HeaderTopLine;