// React 
import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button} from 'react-bootstrap';

//My components
import HeaderCatalogMobile from "../main/HeaderCatalogMobile";
import logo from "../../img/logo.svg";

//SCSS
import '../../scss/components/main/header.scss';
import axios from "axios";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      menuToggleOn: true,
      isToggleOnMobile: true,
      items: [],
      goback: false,
      userData: {}
    }
    this.navMenuClick = this.navMenuClick.bind(this);
    this.navCatalogMenuClick = this.navCatalogMenuClick.bind(this);
    this.navCatalogMenuClickBack = this.navCatalogMenuClickBack.bind(this);
    this.closeMenu = this.closeMenu.bind(this)
  }

  closeMenu() {
    document.querySelector('.nav-mobile__overlay').click();
  }

  navMenuClick() {
    document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      menuToggleOn: !prevState.menuToggleOn
    }));
  }

  navCatalogMenuClick() {
    document.body.classList.add('bfix');
    this.setState(prevState => ({
      menuToggleOn: !prevState.menuToggleOn,
      isToggleOnMobile: !prevState.isToggleOnMobile
    }));
  }

  navCatalogMenuClickBack() {
    document.body.classList.remove('bfix');
    this.setState(prevState => ({
      isToggleOnMobile: !prevState.isToggleOnMobile
    }));
  }

  logout(e) {
    e.preventDefault()
    localStorage.removeItem('galaxy-token')
    window.location.href = '/'
    // this.setState({
    //     goback: true
    // })
    // axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/logout`, {
    //     jwt: localStorage.getItem('galaxy-token')
    // }).then(response => {
    //     localStorage.removeItem('galaxy-token')
    //     this.setState({
    //         goback: true
    //     })
    // }).catch(err => {
    //     console.error(err)
    // })
  }

  componentDidMount() {
    this.setState({
      items: this.props.items
    })
    if (localStorage.getItem('galaxy-token')) {
      axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
        jwt: localStorage.getItem('galaxy-token')
      }).then(response => {
        this.setState({
          userData: response.data.data.info
        })
      }).catch(err => {
        console.error(err)
      })
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({windowHeight: window.innerHeight})
  }

  render() {
    return (
      <div>
        <button className="navbar-toggler" onClick={this.navMenuClick}>
          <div className="navbar-toggler-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <div className={`nav-mobile ${this.state.menuToggleOn ? '' : 'open'}`}>
          <div className="nav-mobile__overlay" onClick={this.navMenuClick}></div>
          <div className="nav-mobile__wrap">
            <div className="nav-mobile__header flex">
              <div className="header-logo">
                <Link to="/" className="header-logo__link" onClick={this.navMenuClick}>
                  <img src={logo} alt=""/>
                </Link>
              </div>
              <Button variant="link" className="nav-mobile__close" onClick={this.navMenuClick}><i
                className="i-close"></i></Button>
            </div>
            <div className="catalog__btn">
              <Button className='catalog-btn' onClick={this.navCatalogMenuClick}>Каталог товаров</Button>
            </div>
            <div className="nav-mobile__menu">
              {localStorage.getItem('galaxy-token') &&
              <ul className="menu-list">
                <div className="user_info">
                  <div className="avatar">
                    <img src="https://sellik.com/images/default.png" alt=""/>
                  </div>
                  {this.state.userData &&
                  <h5>
                    {this.state.userData.name && this.state.userData.name}
                    &nbsp;
                    {this.state.userData.surname && this.state.userData.surname}
                  </h5>
                  }
                </div>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to="/profile" className="nav-link" activeClassName='active'>Профиль</NavLink>
                </li>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to="/profile/orders" className="nav-link" activeClassName='active'>Мои заказы</NavLink>
                </li>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to="/profile/wishlist" className="nav-link" activeClassName='active'>Список желаний</NavLink>
                </li>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to="/profile/waiting-list" className="nav-link" activeClassName='active'>Лист
                    ожидания</NavLink>
                </li>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to="/profile/viewed-products" className="nav-link" activeClassName='active'>Просмотренные
                    товары</NavLink>
                </li>
                <li className="item" onClick={this.closeMenu}>
                  <NavLink to='#' onClick={this.logout}>Выйти</NavLink>
                </li>
              </ul>
              }
              <ul className="menu-list">
                <li className="item" onClick={this.closeMenu}>
                  <Link className="nav-link" to="/" onClick={this.navMenuClick}>Главная</Link>
                </li>
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">О компании</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Новости</a>*/}
                {/*</li>*/}
                <li className="item" onClick={this.closeMenu}>
                  <Link className="nav-link" to="/contact" onClick={this.navMenuClick}>Контакты и адреса</Link>
                </li>
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Вакансии</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Поставщикам</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Размещение рекламы</a>*/}
                {/*</li>*/}
              </ul>

              {/*<ul className="menu-list">*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Оплата</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Кредит</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Доставка и самовывоз</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Возврат товара</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Гарантия и сервис</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Услуги</a>*/}
              {/*    </li>*/}
              {/*    <li className="item" onClick={this.closeMenu}>*/}
              {/*        <a className="nav-link" href="#!">Подарочные карты</a>*/}
              {/*    </li>*/}
              {/*</ul>*/}

              <ul className="menu-list">
                <li className="item" onClick={this.closeMenu}>
                  <Link to={'/promo'} className="nav-link d-flex align-items-center">
                    <span className="menu-categories__icon">
                        <img
                          src={`${process.env.REACT_APP_BACKEND}/site_image/categories_icons/cat-discount.svg`}
                          alt=''/>
                    </span>
                    Акции
                  </Link>
                </li>
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Статьи и обзоры</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Оставить отзыв</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Вопросы и ответы</a>*/}
                {/*</li>*/}
                {/*<li className="item" onClick={this.closeMenu}>*/}
                {/*    <a className="nav-link" href="#!">Условия пользования</a>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
        <div className="catalog-menu">
          <div className={`header-catalog header-catalog__mobile ${this.state.isToggleOnMobile ? '' : 'open'}`}
               style={{height: this.state.windowHeight}}>

            <div className="menu__hidden-content__header">
              <Button id={'menu-btn'} variant="link" className="hCatalog_m_toggle"
                      onClick={this.navCatalogMenuClickBack}><i className="i-arrow"></i>Каталог товаров</Button>
            </div>
            {this.state.items.length > 0 &&
            <HeaderCatalogMobile items={this.state.items}/>
            }
          </div>
        </div>
        <div className={`overlay_main_m ${this.state.isToggleOnMobile ? '' : 'open'}`}
             onClick={this.navCatalogMenuClickBack}></div>
      </div>
    )
  }
}

export default Header;
