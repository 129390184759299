//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import queryString from 'query-string'
import { Breadcrumb, Container, Carousel } from 'react-bootstrap';
import Gallery from "./Gallery"

import '../../scss/components/pages/info/info.scss';

class PageHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            crumbs: [],
            sliders: [
                {
                    src: "img/temp/galaktika/01.jpg",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                },
                {
                    src: "img/temp/galaktika/02.jpg",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                },
                {
                    src: "img/temp/galaktika/03.jpg",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                },
                {
                    src: "img/temp/galaktika/04.jfif",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                },
                {
                    src: "img/temp/galaktika/05.jfif",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                },
                {
                    src: "img/temp/galaktika/06.jfif",
                    width: 1,
                    height: 1,
                    title: "Картинка 1"
                }
            ]
        }
    }
    render() {
        return (
            <>
                <main className='page-content'>
                    <Container>
                        <Breadcrumb>
                            <Breadcrumb.Item href={process.env.REACT_APP_CLIENT}><i className="i-home"></i></Breadcrumb.Item>

                            <li className='breadcrumb-item'>
                                О компании
                            </li>

                        </Breadcrumb>
                        <div className="page-content__wrap">
                            <aside className="sidenav">
                                <nav className="sidenav__wrap">
                                    <ul className="sidenav__list">
                                        <li className="item">
                                            <Link to="/" className="nav-link active" >О компании</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Новости</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Контакты</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Вакансии</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Поставщикам</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Размещение рекламы</Link>
                                        </li>
                                    </ul>

                                    <ul className="sidenav__list">
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Оплата</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Кредит</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Доставка и самовывоз</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Возврат товара</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Гарантия и сервис</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Услуги</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Подарочные карты</Link>
                                        </li>
                                    </ul>

                                    <ul className="sidenav__list">
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Акции</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Статьи и обзоры</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Оставить отзыв</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Вопросы и ответы</Link>
                                        </li>
                                        <li className="item">
                                            <Link to="/" className="nav-link" >Условия пользования</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </aside>
                            <div className="page-content__inner">

                                {/* {this.state.banners.length > 0 &&
                                    <Slider items={this.state.banners} />
                                } */}

                                <Carousel id='sliderCategory'>
                                    {this.state.sliders.map((slider, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <img src={slider.src} alt={slider.title} />
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel>
                                <h1>О компании</h1>
                                <h3>Наша миссия</h3>
                                <blockquote>Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</blockquote>
                                <hr />
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</p>
                                <p>Tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab! Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</p>
                                <p>Более 1000000 магазинов</p>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab!</p>
                                <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Autem, corporis ab! Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae totam illo et quasi dolorem doloremque tenetur veniam amet, autem deserunt tempore nobis.</p>
                                <h3>Наши преимущества</h3>
                                <div className="features">
                                    <div className="features__item">
                                        <img src="./img/slider-newspaper.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                    <div className="features__item">
                                        <img src="./img/slider-onlywe.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                    <div className="features__item">
                                        <img src="./img/slider-hot.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                    <div className="features__item">
                                        <img src="./img/slider-eye.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                    <div className="features__item">
                                        <img src="./img/slider-bag2.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                    <div className="features__item">
                                        <img src="./img/slider-star.svg" className="thumb" alt="newspaper" />
                                        <span className="title">Доставка по Белоруссии</span>
                                        <span className="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis nihil iste mollitia molestiae</span>
                                    </div>
                                </div>

                                <h3>Наши награды, достижения, сертификаты</h3>
                                <div className="numbers">
                                    <div className="numbers__item">
                                        <h4>3.9 млн</h4>
                                        <span>Доступных к покупке товаров</span>
                                    </div>
                                    <div className="numbers__item">
                                        <h4>81%</h4>
                                        <span>наших покупателей возвращаются</span>
                                    </div>
                                    <div className="numbers__item">
                                        <h4>789 млн</h4>
                                        <span>пользователей посетили галактику в 2020 году</span>
                                    </div>
                                    <div className="numbers__item">
                                        <h4>2.5 млн</h4>
                                        <span>Посетителей в день</span>
                                    </div>
                                </div>

                                <div className="contact__gallery">
                                    <Gallery />
                                </div>
                                <h3>Реквизиты компании</h3>
                                <div className="requiz">
                                    <div className="line">
                                        <p>Владелец магазина</p>
                                        <span>Такой-то</span>
                                    </div>
                                    <div className="line">
                                        <p>Свидетельство о регистрации</p>
                                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit</span>
                                    </div>
                                    <div className="line">
                                        <p>Дата регистрации в Торговом реестре Республики ДНР</p>
                                        <span>20.08.2020</span>
                                    </div>
                                    <div className="line">
                                        <p>Юридический адрес</p>
                                        <span>Такой-то</span>
                                    </div>
                                    <div className="line">
                                        <p>Контактные телефоны</p>
                                        <span>5466548749849684</span>
                                    </div>
                                    <div className="line">
                                        <p>Адрес для почтовых отправлений</p>
                                        <span>Lorem ipsum dolor sit amet consectetur adipisicing elit</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </main>
            </>
        );
    }
}

export default PageHome;
