import React, { Component } from 'react';
// import { Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap';
import axios from 'axios'
class Recovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allGood: false
        }
    }
    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_BASE}/forgotPassword/${this.props.match.params.token}/${this.props.match.params.mail}`)
            .then((res) => {
                console.log(res)
            })
            .catch(err=>{
                // window.location.replace('/404.html')
            })
    }

    render() {
        return (
            <main className='page-error'>
                <Container>
                    <h3 style={{
                        textAlign: "center"
                    }}>
                        Проверьте почту. Мы выслали новый пароль на адрес: {this.props.match.params.mail}
                    </h3>
                </Container>
            </main>
        );
    }
}

export default Recovery;
