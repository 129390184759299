//React
import React from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function ContactGallery() {
    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const openLightbox = React.useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    const photos = [
        {
            src: "img/temp/galaktika/01.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/02.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/03.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/04.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/05.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/01.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/02.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/03.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/04.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/05.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/01.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/02.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/03.jpg",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/04.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/05.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        },
        {
            src: "img/temp/galaktika/06.jfif",
            width: 1,
            height: 1,
            title: "Картинка 1"
        }
    ];
    return (
        <div>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

export default ContactGallery;