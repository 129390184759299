// React
import React, { Component } from 'react';
import { Col, Row, Carousel } from 'react-bootstrap';
import PromoRools from './PromoRools'

//SCSS
import '../../scss/components/pages/home2/sliderLine1.scss';




class PromoLine4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: 10000,
            nextIcon: <span className="arrow-icon next"></span>,
            prevIcon: <span className="arrow-icon prev"></span>
        }
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({
            index: selectedIndex,
            direction: e.direction
        });
    }
    render() {
        const { nextIcon, prevIcon, interval } = this.state;
        return (
            <Row>
                <Col xs={12} xl={this.props.banners.length>0 ? 8 : 12}>
                    <div className="promo-rools">
                        <div className="promo-rools__wrap">
                            <h3 className="page-subtitle">Акция <span>{this.props.info.name}</span></h3>
                            <div dangerouslySetInnerHTML={{__html: this.props.info.description}}></div>
                        </div>
                        <PromoRools condition={this.props.info.condition} />
                    </div>
                </Col>
                
                {this.props.banners.length>0 &&                 
                    <Col xs={12} xl={4}>
                        
                        <Carousel id='sliderLast' nextIcon={nextIcon} prevIcon={prevIcon} interval={interval} >
                            {this.props.banners.map( (banner, index)=>{
                                return(
                                    <Carousel.Item key={index}>
                                        <img src={process.env.REACT_APP_BACKEND + 'uploads/stock_banners/' + banner.baner_url} alt={banner.baner_name} />
                                    </Carousel.Item>
                                )
                            } )}
                        </Carousel>
                    </Col>
                }
            </Row>
        )
    }
}

export default PromoLine4;
